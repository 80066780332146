import {baseApi} from "./apiService";
import {RegisterPayload} from "../types";

//2 - Company view (not shown if personal use) Create company with dummy data from username and mark it as personal
//3 - Show suitable Subscriptions If personal offer Personal. Login user, redirect with subscription maker on login check.

export const registerApi = baseApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        register: builder.mutation<void, RegisterPayload>({
            query: (body) => ({
                url: `register`,
                method: 'POST',
                body,
            }),
            // invalidatesTags: ['Tasks', 'Files'],
        }),
    }),
})

export const {
    useRegisterMutation,
} = registerApi
