import {
    Box,
    Button,
    HStack,
    Spacer,
    AccordionPanel,
    AccordionItem,
    Accordion,
} from "@chakra-ui/react";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import FileAccordionButton from "./FileAccordionButton";
import {UploadHistory} from "../../types";
import FileLinkedFiles from "./FileLinkedFiles";
import {isNoAccessFile} from "../../utils/generalHelper";
import NoAccessFileAccordionButton from "./NoAccessFileAccordionButton";

export default function FileLinkedFilesStep({setStep, uploadHistory}: {
    setStep: (step: number) => void,
    uploadHistory: UploadHistory,
}) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [submittedDetails, setSubmittedDetails] = useState<string[]>([]);
    const [index, setIndex] = useState<number | number[]>(0)

    // if (isLoading || isLoadingLinkedFiles) {
    //     return (
    //         <Box>
    //         </Box>
    //     )
    // }

    return (
        <Box>
            {/* Index is hack to open first */}
            <Accordion allowMultiple allowToggle index={index} onChange={setIndex}>
                {uploadHistory.files.map((file) =>
                    <AccordionItem bg={"#fff"} isDisabled={isNoAccessFile(file)} borderRadius={"lg"} mt={8} key={file.id}>
                        {isNoAccessFile(file)
                            ? <NoAccessFileAccordionButton file={file}/>
                            : <>
                                <FileAccordionButton file={file} submittedDetails={submittedDetails}/>
                                <AccordionPanel pb={4}>
                                    <FileLinkedFiles file={file} submittedDetails={submittedDetails}
                                                     setSubmittedDetails={setSubmittedDetails}/>
                                </AccordionPanel>
                            </>
                        }
                    </AccordionItem>
                )}
            </Accordion>
            <Box p={8} bg={"#fff"} mt={8} borderRadius={"lg"}>
                <HStack>
                    <Spacer/>
                    <Button
                        // isLoading={isLoadingLinkedFiles}
                        //     loadingText={t('upload.actions.uploading')}
                        onClick={() => navigate('/files')}
                        variant={"primary"}>{t('common.actions.finish')}</Button>
                </HStack>
            </Box>
        </Box>);
}
