import * as React from 'react'
import {FormikProps} from 'formik'
import {isEqual, debounce} from 'lodash'
import {SearchFormValues} from "../types";

export const AutoSubmit = ({formik}: { formik: FormikProps<SearchFormValues> }) => {
    const [lastValues, updateState] = React.useState(formik.values)

    const submitForm = React.useCallback(
        debounce(
            (): void => {
                formik.submitForm()
            },
            500,
            {maxWait: 1500}
        ),
        []
    )

    React.useEffect(() => {
        const valuesEqualLastValues = isEqual(lastValues, formik.values)
        const valuesEqualInitialValues = isEqual(formik.values, formik.initialValues)

        if (!valuesEqualLastValues) {
            updateState(formik.values)
        }

        if (!valuesEqualLastValues && formik.isValid) {
        // if (!valuesEqualLastValues && !valuesEqualInitialValues && formik.isValid) {
            submitForm()
        }

        return () => {
            submitForm.cancel();
        };
    }, [formik.values, formik.isValid])

    return null
}
