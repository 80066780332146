import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from "react-i18next";
import translation_en from "./translations/en/translation.json";
import translation_sk from "./translations/sk/translation.json";

const fallbackLng = 'en';
const availableLanguages = ['en', 'sk'];

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        returnNull: false,
        fallbackLng: fallbackLng,
        debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
        resources: {
            en: {
                translation: translation_en
            },
            sk: {
                translation: translation_sk
            },
        },
        // lng: "en", //Do not use because of detector
        supportedLngs: availableLanguages,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
