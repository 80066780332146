import { defineStyleConfig, extendTheme, defineStyle } from "@chakra-ui/react";

const Button = defineStyle({
  baseStyle: {
    minW: "110px",
    flexShrink: 0,
  },
  variants: {
    primary: {
      bgColor: "teal.400",
      minW: "110px",
      color: "white",
      _hover: {
        bg: "teal.500",
      },
    },
    outline: {
      borderColor: "teal.400",
      color: "teal.400",
      minWidth: "110px",
    },
    gray: {
      background: "gray.200",
      color: "black",
      minW: "110px",
      _hover: {
        bg: "gray.300",
      },
    },
  },
});

const Checkbox = defineStyleConfig({
  defaultProps: {
    colorScheme: "teal",
  },
});

const Select = defineStyleConfig({
  defaultProps: {
    colorScheme: "teal",
  },
});
const Input = defineStyleConfig({
  defaultProps: {
    _placeholder: { color: "gray.400" },
    _focusVisible: {
      borderColor: "teal.400",
      boxShadow: "0 0 0 1px var(--chakra-colors-teal-400)",
    },
  },
});

export const theme = extendTheme({
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  components: {
    Button,
    Checkbox,
    Select,
    Input,
  },
});
