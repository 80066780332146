import {baseApi} from "./apiService";

export const subscriptionApi = baseApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        subscribeEmail: builder.mutation({
            query: (body) => ({
                url: `subscribe`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Subscribe'],
        }),
        getEmailSubscribers: builder.query({
            query: () => ({
                url: `subscribe`,
                method: 'GET',
            }),
        }),
    })
});

export const {useSubscribeEmailMutation, useGetEmailSubscribersQuery} = subscriptionApi
