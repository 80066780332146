import React from "react";
import {Box, Container, Heading, HStack, ListItem, Text, UnorderedList, VStack} from "@chakra-ui/react";
import Seo from "../../components/Seo";
import {Link} from "react-router-dom";

const DataProcessingAgreement = () => {

    return (
        <Container maxW={"container.xl"} pt={"10"} px={3}>
            <Seo
                title={`Filetica - Data processing agreement`}
                description={'Filetica Data processing agreement'}
                // ogType={'article'}
            />
            <HStack mb={2}>
                <Text color={"gray.600"}>Filetica / </Text>
                <Heading size={"md"} color={"teal.400"}>
                    Data processing agreement
                </Heading>
            </HStack>
            <Box bg={"#fff"} p={8} borderRadius={10}>
                <VStack paddingTop="40px" spacing="4" alignItems="flex-start">
                    <Text>
                        Filetica (“we”, “us” or “our”) is committed to ensuring the security and protection of
                        the
                        personal information that we process, and to provide a compliant and consistent
                        approach to data
                        protection.
                    </Text>
                    <Heading size={'md'}>Definitions:</Heading>
                    <Text>
                        Key terms used throughout the agreement include:
                    </Text>
                    <VStack spacing="4" alignItems="flex-start">
                        <UnorderedList>
                            <ListItem>Data Controller: The entity that determines the purposes and means of processing
                                personal data.</ListItem>
                            <ListItem>Data Processor: The entity that processes data on behalf of the Data
                                Controller.</ListItem>
                            <ListItem>Data Subject: The identified or identifiable person to whom personal data
                                relates.</ListItem>
                            <ListItem>Personal Data: Any information relating to a Data Subject.</ListItem>
                            <ListItem>Processing: Any operation performed on personal data, whether or not by automated
                                means.</ListItem>
                        </UnorderedList>
                    </VStack>
                    <Heading size={'md'}>Purpose and Scope:</Heading>
                    <Text>
                        This DPA sets forth the terms and conditions under which personal data will be processed by
                        Filetica. The nature of processing includes storage, archiving, and management of documents. The
                        types of personal data may include user names, email addresses, and document contents. The
                        categories of data subjects are Filetica users, including businesses and individual account
                        holders.
                        We have created this Data processing agreement to explain our approach to
                        implementing our GDPR
                        compliance program. It describes the implementation of our data protection roles,
                        policies,
                        procedures, controls and measures to ensure ongoing compliance with GDPR.
                    </Text>

                    <Heading size={'md'}>Obligations of the Data Controller and Data Processor:</Heading>
                    <Text>
                        Both parties commit to ensuring that processing is lawful, transparent, and undertaken with
                        appropriate security measures. The Data Controller will provide necessary data for processing,
                        while the Data Processor commits to processing only as instructed by the Controller.
                    </Text>

                    <Heading size={'md'}>Sub-processors:</Heading>
                    <Text>
                        Filetica uses third-party sub-processors, including Google Analytics and Amazon S3, for specific
                        processing activities. Any engagement with a new sub-processor will be communicated in advance,
                        and approval will be sought.
                    </Text>

                    <Heading size={'md'}>Data Transfers:</Heading>
                    <Text>
                        Personal data may be transferred outside the EEA, especially for storage on Amazon S3. Filetica
                        ensures that such transfers are compliant with GDPR, using mechanisms like Standard Contractual
                        Clauses.
                    </Text>

                    <Heading size={'md'}>Governing Law:</Heading>
                    <Text>
                        This DPA is governed by the laws of the European Union.
                    </Text>

                    <Heading size={'md'}>Our GDPR Principles</Heading>
                    <Text>
                        Filetica takes the privacy and security of individuals and their personal information
                        very
                        seriously.
                        Our principles for processing personal information are:
                    </Text>
                    <VStack spacing="4" alignItems="flex-start">

                        <UnorderedList>
                            <ListItem>We will process all personal information fairly and lawfully</ListItem>
                            <ListItem>We will only process personal information for specified and lawful
                                purposes
                            </ListItem>
                            <ListItem>Where practical, we will keep personal information up to date</ListItem>
                            <ListItem>We will not keep personal information for longer than is necessary</ListItem>
                        </UnorderedList>
                    </VStack>
                    <Heading size={'md'}>Data Subjects Rights under GDPR</Heading>
                    <Text>
                        At Filetica, an individual can request information about:
                    </Text>
                    <VStack spacing="4" alignItems="flex-start">

                        <UnorderedList>
                            <ListItem>What personal information we hold about an individual</ListItem>
                            <ListItem>The categories of personal information we collect from an individual</ListItem>
                            <ListItem>
                                The purposes for collecting and processing personal information from an
                                individual
                            </ListItem>
                            <ListItem>How long we plan to keep the personal information</ListItem>
                            <ListItem>
                                The process to have incomplete or inaccurate personal information corrected
                                or completed
                            </ListItem>
                            <ListItem>
                                Where applicable, the process for requesting erasure of the personal
                                information or for restricting the processing of personal information in
                                accordance with data protection laws, as well as to object to any direct
                                marketing from us
                            </ListItem>
                            <ListItem>About any automated decision-making that we use</ListItem>
                        </UnorderedList>
                    </VStack>
                    <Heading size={'md'}>Our GDPR compliance plan</Heading>
                    <Text>
                        Here&apos;s an overview of our steps that we are taking to ensure compliance with
                        GDPR at
                        Filetica:
                    </Text>
                    <VStack spacing="4" alignItems="flex-start">

                        <UnorderedList>
                            <ListItem>We conducted a data mapping inventory and analysis of collected personal
                                information in our systems and records
                            </ListItem>
                            <ListItem>We have established procedures and policies to restrict processing of
                                personal information
                            </ListItem>
                            <ListItem>We have updated our procedures for data breaches and incident responses</ListItem>
                            <ListItem>We have updated our company’s Data Protection Policy, Data Retention Policy,
                                Information Security Policy, Cookies Policy and Privacy Policy
                            </ListItem>
                            <ListItem>We have reviewed all processing activities to identify the legal basis for
                                processing personal information and to ensure that each basis is appropriate
                                for the activity it relates to
                            </ListItem>
                        </UnorderedList>
                    </VStack>

                    <Heading size={'md'}>Contact us if you have Data processing agreement related questions</Heading>
                    <Text>
                        Please feel free to contact us if you have any questions about Filetica’s
                        Data processing agreement or GDPR.
                        You may contact us as follows:
                    </Text>
                    <VStack spacing="4" alignItems="flex-start">
                        <UnorderedList>
                            <ListItem>Website: <Link to=''>https://filetica.com</Link></ListItem>
                            <ListItem>Email: <a href="mailto:info@filetica.com?Subject=DataProcessingAgreement"
                                                target="_top">info@filetica.com</a></ListItem>
                        </UnorderedList>
                    </VStack>
                    {/*Phone: Phone Number*/}
                </VStack>
            </Box>
        </Container>
    )
}

export default DataProcessingAgreement;

