import {
    Box,
    Container,
    Flex,
    IconButton,
    Button,
    Stack,
    Collapse,
    Link,
    Popover,
    PopoverTrigger,
    useColorModeValue,
    useDisclosure,
    Center,
    Divider,
    Menu,
    MenuButton,
    MenuList,
    MenuGroup,
    MenuItem,
    HStack,
    Heading,
    Avatar,
    AvatarBadge,
    Tag,
} from '@chakra-ui/react';
import LogoRight from "../../assets/FileticaLogoRight.png";
import React from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {IoClose, IoCloudUploadSharp, IoMenu, IoSettingsOutline, IoSwapHorizontal} from "react-icons/io5";
import {useTranslation} from "react-i18next";
import {isLoggedIn, logout} from "../../slices/authSlice";
import {useAuth} from "../../hooks/useAuth";
import {useGetCurrentUserQuery} from "../../services/userService";
import {IoIosLogOut, IoMdSettings} from "react-icons/io";
import {isAdmin, isSuperAdmin} from "../../utils/userRoleHelper";
import {useSwitchCompanyMutation} from "../../services/userCompanyService";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";
import {AccountType, Role} from "../../types";
import {RoleAccess} from "../../utils/RoleAccess";

export default function Navbar() {
    const {t, i18n} = useTranslation()
    const {isOpen, onToggle} = useDisclosure();
    const navigate = useNavigate();
    const auth = useAuth()
    const dispatch = useDispatch()
    const {data} = useGetCurrentUserQuery(undefined, {skip: !isLoggedIn(auth)})
    const [switchCompany, {isLoading}] = useSwitchCompanyMutation()


    return (
        <Box bg={useColorModeValue('white', 'gray.800')} as={"nav"}>
            <Container maxW={"container.xl"} mx="auto">
                <Flex
                    bg={useColorModeValue('white', 'gray.800')}
                    color={useColorModeValue('gray.600', 'white')}
                    minH={'60px'}
                    px={{base: 4}}
                    align={'center'}>
                    {isLoggedIn(auth)
                        ? <Flex
                            flex={{base: 1, md: 'auto'}}
                            ml={{base: -2}}
                            display={{base: 'flex', md: 'none'}}>
                            <IconButton
                                onClick={onToggle}
                                icon={
                                    isOpen ? <IoClose w={3} h={3}/> : <IoMenu w={5} h={5}/>
                                }
                                variant={'ghost'}
                                aria-label={'Toggle Navigation'}
                            />
                        </Flex>
                        : null
                    }
                    <Flex flex={{base: 1}} justify={{base: 'center', md: 'start'}}>
                        <Center>
                            <img alt={'Filetica logo'} width={180} height={21} onClick={() => navigate('')}
                                 src={LogoRight}/>
                        </Center>
                        {isLoggedIn(auth)
                            ? <Flex display={{base: 'none', md: 'flex'}} ml={10}>
                                <DesktopNav/>
                            </Flex>
                            : null
                        }
                    </Flex>

                    {isLoggedIn(auth)
                        ? <Stack
                            // flex={{base: 1, md: 0}}
                            justify={'flex-end'}
                            justifyContent={'center'}
                            // verticalAlign={'center'}
                            direction={'row'}
                            spacing={6}>
                            <DesktopNavItem label={'blog.blog'} to={'/blog'}/>
                            <DesktopNavItem label={'nav.upload'} to={'/upload'} icon={<IoCloudUploadSharp
                                fontSize={20}
                                color="var(--chakra-colors-teal-400)"
                            />}/>
                            <Center>
                                <Divider minH={8} orientation="vertical" borderColor={"#D9D9D9"}/>
                            </Center>
                            <Center>
                                <Menu>
                                    <MenuButton
                                        as={IconButton}
                                        fontSize={20}
                                        color="var(--chakra-colors-teal-400)"
                                        background={'white'}
                                        aria-label='Options'
                                        icon={<IoMdSettings/>}
                                        // variant='outline'
                                    />
                                    <MenuList>
                                        {data?.user_companies.length > 1
                                            ? <>
                                                {data.user_companies
                                                    .filter((company) =>
                                                        company.company.account_type !== AccountType.agencyClient
                                                    ).length > 0
                                                    ? <MenuGroup title={t('agency.client', {count: 2})}>
                                                        {data.user_companies
                                                            .filter((company) =>
                                                                    company.company.account_type === AccountType.agencyClient
                                                                // todo filter by agency!!!
                                                                // && company.company.agency !== null
                                                            )
                                                            .map((company) =>
                                                                <MenuItem
                                                                    isDisabled={company.company.id === data.company.id || isLoading}
                                                                    key={company.id}
                                                                    icon={<IoSwapHorizontal/>}
                                                                    onClick={() => switchCompany(company.id)
                                                                        .unwrap()
                                                                        .then(() => window.location.reload())
                                                                        .catch()}
                                                                >
                                                                    {company.company.name}
                                                                </MenuItem>
                                                            )}
                                                    </MenuGroup>
                                                    : null}
                                                {data.user_companies
                                                    .filter((company) =>
                                                        company.company.account_type !== AccountType.agencyClient
                                                    ).length > 0

                                                    ? <MenuGroup title={t('nav.multiCompany')}>
                                                        {data.user_companies
                                                            .filter((company) =>
                                                                company.company.account_type !== AccountType.agencyClient
                                                            )
                                                            .map((company) =>
                                                                <MenuItem
                                                                    isDisabled={company.company.id === data.company.id || isLoading}
                                                                    key={company.id}
                                                                    icon={<IoSwapHorizontal/>}
                                                                    onClick={() => switchCompany(company.id)
                                                                        .unwrap()
                                                                        .then(() => window.location.reload())
                                                                        .catch()}
                                                                >
                                                                    {company.company.name}
                                                                </MenuItem>
                                                            )}
                                                    </MenuGroup>
                                                    : null}
                                            </>
                                            : null}
                                        {(isSuperAdmin(data) || isAdmin(data)) ?
                                            <MenuGroup title={t('nav.admin')}>
                                                <MenuItem icon={<IoSettingsOutline/>}
                                                          onClick={() => navigate('/admin')}>
                                                    {t('nav.admin')}
                                                </MenuItem>
                                                <MenuItem icon={<IoSettingsOutline/>}
                                                          onClick={() => i18n.changeLanguage("en")}>
                                                    {t('nav.en')}
                                                </MenuItem>
                                                <MenuItem icon={<IoSettingsOutline/>}
                                                          onClick={() => i18n.changeLanguage("sk")}>
                                                    {t('nav.sk')}
                                                </MenuItem>
                                            </MenuGroup> : null
                                        }
                                        <MenuGroup title={t('nav.account')}>
                                            <MenuItem icon={<IoSettingsOutline/>}
                                                      onClick={() => navigate('/settings')}>
                                                {t('nav.setting', {count: 2})}
                                            </MenuItem>
                                            <MenuItem icon={<IoIosLogOut/>} onClick={() => dispatch(logout())}>
                                                {t('nav.logout')}
                                            </MenuItem>
                                        </MenuGroup>
                                        {/*<MenuItem icon={<ExternalLinkIcon />} command='⌘N'>*/}
                                        {/*    New Window*/}
                                        {/*</MenuItem>*/}
                                        {/*<MenuItem icon={<RepeatIcon />} command='⌘⇧N'>*/}
                                        {/*    Open Closed Tab*/}
                                        {/*</MenuItem>*/}
                                        {/*<MenuItem icon={<EditIcon />} command='⌘O'>*/}
                                        {/*    Open File...*/}
                                        {/*</MenuItem>*/}
                                    </MenuList>
                                </Menu>
                            </Center>
                            <HStack>
                                <Heading
                                    size={"sm"}>{t('nav.greetings')}, {data?.first_name} | <small>{data?.company?.name}</small> <Tag size={'sm'} variant='solid' colorScheme='teal'>
                                        {t(`subscriptions.accountTypes.${data?.company?.account_type}`)}
                                    </Tag>
                                </Heading>
                                <Avatar width={"30px"} height={"30px"}>
                                    <AvatarBadge boxSize="0.75em" bg="green.500"/>
                                </Avatar>
                            </HStack>
                        </Stack>
                        : <Stack
                            flex={{base: 1, md: 0}}
                            justify={'flex-end'}
                            justifyContent={'center'}
                            // verticalAlign={'center'}
                            direction={'row'}
                            spacing={6}>
                            <DesktopNavItem label={'blog.blog'} to={'/blog'}/>
                            <Button color={"black"} variant="link" onClick={() => navigate("/login")}>
                                {t('nav.login')}
                            </Button>
                            <Center display={{base: 'none', md: 'inline-flex'}}>
                                <Button
                                    variant="primary"
                                    onClick={() => navigate("/register")}
                                >
                                    {t('nav.register')}
                                </Button>
                            </Center>
                            {/*<ColorModeSwitcher justifySelf="flex-end"/>*/}
                        </Stack>
                    }
                </Flex>
                {isLoggedIn(auth)
                    ? <Collapse in={isOpen} animateOpacity>
                        <MobileNav/>
                    </Collapse>
                    : null
                }
            </Container>
        </Box>
    );
}

const DesktopNav = () => {
    return (
        <Stack direction={'row'} spacing={4}>
            {NAV_ITEMS.map((navItem) => (
                <RoleAccess key={navItem.label} allowedRoles={navItem.roleAccess ? [navItem.roleAccess]: []}>
                    <Box>
                        <Popover trigger={'hover'} placement={'bottom-start'}>
                            <PopoverTrigger>
                                <DesktopNavItem label={navItem.label} to={navItem.to} count={navItem.count}/>
                            </PopoverTrigger>
                        </Popover>
                    </Box>
                </RoleAccess>
            ))}
        </Stack>
    );
};

const DesktopNavItem = ({label, count = 1, to, icon}) => {
    const linkColor = useColorModeValue('gray.500', 'gray.200');
    const linkHoverColor = useColorModeValue('gray.800', 'white');
    const activeColor = useColorModeValue('black', 'gray.200');
    const {t} = useTranslation();

    return (
        <Link
            as={NavLink}
            to={to}
            p={3}
            sx={{
                "&": {
                    p: 3,
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    w: "17",
                    h: "70px",
                    transition: ".2s",
                    position: "relative",
                    textDecoration: 'none',
                    "&.hover": {
                        textDecoration: 'none',
                    },
                    "&.active": {
                        color: activeColor,
                        "&::before": {
                            content: `""`,
                            position: "absolute",
                            height: 1,
                            width: "full",
                            background: "teal.400",
                            bottom: 0,
                        },
                    },
                },
            }}
            height={'100%'}
            display={'flex'}
            fontSize={'md'}
            fontWeight={600}
            color={linkColor}
            _hover={{
                textDecoration: 'none',
                color: linkHoverColor,
            }}
        >
            <Center gap={2}>
                {icon}
                {t(label, {count: count})}
            </Center>
        </Link>
    );
};

DesktopNavItem.propTypes = {
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    children: PropTypes.element,
    icon: PropTypes.element,
    count: PropTypes.number,
};

const MobileNav = () => {
    return (
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            p={4}
            display={{md: 'none'}}>
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    );
};

const MobileNavItem = ({label, to, children, count}) => {
    const {isOpen, onToggle} = useDisclosure();
    const {t} = useTranslation()

    const linkColor = useColorModeValue('gray.500', 'gray.200');
    const activeColor = useColorModeValue('teal.600', 'gray.200');

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={NavLink}
                to={to}
                justify={'space-between'}
                align={'center'}
                fontWeight={600}
                color={linkColor}
                sx={{
                    "&": {
                        "&.active": {
                            color: activeColor,
                            "&::before": {
                                content: `""`,
                                position: "absolute",
                                height: 1,
                                width: "full",
                                background: "teal.400",
                                bottom: 0,
                            },
                        },
                    },
                }}
                _hover={{
                    textDecoration: 'none',
                }}>
                {t(label, {count: count})}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{marginTop: '0!important'}}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}>
                    {children &&
                        children.map((child) => (
                            <Link key={child.label} py={2} href={child.href}>
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};

MobileNavItem.propTypes = {
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    children: PropTypes.element,
    count: PropTypes.number,
};

const NAV_ITEMS = [
    {
        label: 'files.file',
        count: 2,
        to: '/files',
    },
    {
        label: 'tasks.task',
        count: 2,
        to: '/tasks',
        roleAccess: Role.User,
    },
    {
        label: 'uploadHistory.uploadHistory',
        count: 2,
        to: '/uploadHistory',
        roleAccess: Role.AgencyClient,
    },
];
