import {createSlice} from '@reduxjs/toolkit'
import {RootState} from "../store/store";

const taskFilterSliceInitialState = {
    status: 'all',
    assignee: 'all',
}

const taskFilterSlice = createSlice({
    name: 'taskFilter',
    initialState: taskFilterSliceInitialState,
    reducers: {
        setStatusFilter: (state, action) => {
            state.status = action.payload
        },
        setAssigneeFilter: (state, action) => {
            state.assignee = action.payload
        },
    },
    extraReducers: (builder) => {
        // Needed to refresh file to see new task for example in modal
    },
})

export const {setStatusFilter, setAssigneeFilter} = taskFilterSlice.actions

export const isTaskStatusFiltered = (state: RootState) => state.taskFilter.status !== 'all'
export const isTaskAssigneeFiltered = (state: RootState) => state.taskFilter.assignee !== 'all'

export const selectTaskStatusFilter = (state: RootState) => state.taskFilter.status
export const selectTaskAssigneeFilter = (state: RootState) => state.taskFilter.assignee

export default taskFilterSlice.reducer
