import React from "react";
import {
    Box,
    Center,
    Heading, Progress, SimpleGrid,
    Spacer, Stat, StatGroup, StatLabel, StatNumber, Tag, TagLabel, TagLeftIcon,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useGetUsageQuery} from "../../services/usageService";
import {IoCheckmark} from "react-icons/io5";
import {AccountType} from "../../types";
import {AccessLimit} from "../../utils/AccessLimit";

export default function Usage() {
    const {t} = useTranslation()
    const {data, isLoading} = useGetUsageQuery()

    const storageUsedGB = ((data?.storage?.used ?? 0) / (1024 * 1024 * 1024)).toFixed(2);
    const totalStorageGB = ((data?.storage?.available ?? 0) / (1024 * 1024 * 1024)).toFixed(2);
    const extraStorageUsedGB = (storageUsedGB > totalStorageGB ? storageUsedGB - totalStorageGB : 0).toFixed(2);
    const storageUsedPercent = ((data?.storage?.used ?? 0) / (data?.storage?.available ?? 0)) * 100;
    const seatsUsedPercent = ((data?.seat?.used ?? 0) / (data?.seat?.available ?? 0)) * 100;

    const getUsageColor = (usage) => {
        switch (true) {
            case (data?.storage?.extraStorage):
                return 'teal';
            case (usage < 60):
                return 'teal';
            case (usage < 80):
                return 'orange';
            default:
                return 'red';
        }
    }

    return (
        <Box p={8} bg={"#fff"} mt={4} borderRadius={"lg"}>
            <Center mb={6}>
                <Heading size={"sm"}>{t('settings.usage.title')}</Heading>
                <Spacer/>
            </Center>
            <Box mb={10}>
                <Heading size={"xs"} mb={3}>
                    {t('settings.usage.storage')}
                </Heading>
                <StatGroup>
                    <Stat>
                        <StatLabel>{t('settings.usage.used')}</StatLabel>
                        <AccessLimit allowedCompanyTypes={[AccountType.agencyClient]}>
                            <StatNumber>{storageUsedGB} GB</StatNumber>
                        </AccessLimit>
                        <AccessLimit allowedCompanyTypes={
                            [AccountType.agency, AccountType.agencyEnterprise, AccountType.company, AccountType.companyEnterprise]
                        }>
                            <StatNumber>{storageUsedGB} GB / {totalStorageGB} GB</StatNumber>
                        </AccessLimit>
                    </Stat>
                    {data?.storage?.extraStorage
                        ? <Stat>
                            <Stat>
                                <StatLabel>{t('settings.usage.extraStorage')}</StatLabel>
                                <StatNumber>{extraStorageUsedGB} GB</StatNumber>
                            </Stat>
                        </Stat>
                        : null
                    }
                </StatGroup>
                <AccessLimit allowedCompanyTypes={
                    [AccountType.agency, AccountType.agencyEnterprise, AccountType.company, AccountType.companyEnterprise]
                }>
                    <Progress
                        value={storageUsedPercent}
                        size='lg'
                        colorScheme={getUsageColor(storageUsedPercent)}
                        isIndeterminate={isLoading}
                    />
                </AccessLimit>
            </Box>
            <Box mb={10}>
                <Heading size={"xs"} mb={3}>
                    {t('settings.usage.seat')}
                </Heading>
                <StatGroup>
                    <Stat>
                        <StatLabel>{t('settings.usage.used')}</StatLabel>
                        <AccessLimit allowedCompanyTypes={[AccountType.agencyClient]}>
                            <StatNumber>{data?.seat?.used}</StatNumber>
                        </AccessLimit>
                        <AccessLimit allowedCompanyTypes={
                            [AccountType.agency, AccountType.agencyEnterprise, AccountType.company, AccountType.companyEnterprise]
                        }>
                            <StatNumber>{data?.seat?.used} / {data?.seat?.available}</StatNumber>
                        </AccessLimit>
                    </Stat>
                </StatGroup>
                <AccessLimit allowedCompanyTypes={
                    [AccountType.agency, AccountType.agencyEnterprise, AccountType.company, AccountType.companyEnterprise]
                }>
                    <Progress
                        value={seatsUsedPercent}
                        size='lg'
                        colorScheme={getUsageColor(seatsUsedPercent)}
                        isIndeterminate={isLoading}
                    />
                </AccessLimit>
            </Box>

            <AccessLimit allowedCompanyTypes={
                [AccountType.agency, AccountType.agencyEnterprise]
            }>
                <>
                    <Box>
                        <Heading size={"s"} mb={3}>
                            {t('subscriptions.accountTypes.agency')}
                        </Heading>
                    </Box>
                    <SimpleGrid columns={2} spacing={10}>
                        <Box mb={10}>
                            <Heading size={"xs"} mb={3}>
                                {t('settings.usage.companies')}
                            </Heading>
                            {/* todo add check*/}
                            <Tag size={'md'} key={'md'} variant='solid' colorScheme='teal' mb={3}>
                                <TagLeftIcon boxSize='12px' as={IoCheckmark}/>
                                <TagLabel>{t('settings.usage.extra')}</TagLabel>
                            </Tag>
                            <StatGroup>
                                <Stat>
                                    <StatLabel>{t('settings.usage.used')}</StatLabel>
                                    <StatNumber>{data?.agency?.companies?.used} / {data?.agency?.companies?.available}</StatNumber>
                                </Stat>
                            </StatGroup>
                        </Box>
                        <Box mb={10}>
                            <Heading size={"xs"} mb={3}>
                                {t('settings.usage.companyUsers')}
                            </Heading>
                            {/* todo add check*/}
                            <Tag size={'md'} key={'md'} variant='solid' colorScheme='teal' mb={3}>
                                <TagLeftIcon boxSize='12px' as={IoCheckmark}/>
                                <TagLabel>{t('settings.usage.extra')}</TagLabel>
                            </Tag>
                            <StatGroup>
                                <Stat>
                                    <StatLabel>{t('settings.usage.used')}</StatLabel>
                                    <StatNumber>{data?.agency?.companyUsers?.used} / {data?.agency?.companyUsers?.available}</StatNumber>
                                </Stat>
                            </StatGroup>

                        </Box>
                    </SimpleGrid>
                </>
            </AccessLimit>
        </Box>
    );
}

Usage.propTypes = {
    usage: PropTypes.object,
};
