import {baseApi} from "./apiService";

export const usageApi = baseApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getUsage: builder.query({
            query: () => ({
                url: 'usage',
            }),
            providesTags: ['Usage'],
        }),
    })
});

export const {useGetUsageQuery} = usageApi
