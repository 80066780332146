import React, {useState} from "react";
import {
    Button,
    ButtonGroup,
    Center,
    Heading,
    Spacer,
    Box,
    Collapse,
    HStack,
    GridItem,
    Grid,
    FormControl,
    FormErrorMessage,
    FormLabel,
    NumberInput,
    NumberInputField,
    NumberInputStepper, NumberDecrementStepper, NumberIncrementStepper, Stack, Text, InputGroup, Textarea,
} from "@chakra-ui/react";
import {IoMdAddCircleOutline} from "react-icons/io";
import {Field, Form, Formik} from "formik";
import {boolean, number, object, string} from "yup";
import {useTranslation} from "react-i18next";
import {useGetFileTypesListQuery} from "../../services/fileTypeService";
import AppInput from "../AppInput";
import AppSelect from "../AppSelect";
import {useGetUsersListQuery} from "../../services/userService";
import {useCreatePredefinedTaskMutation} from "../../services/predefinedTaskService";

export default function PredefinedTaskCreateSingle() {
    const {t} = useTranslation()
    const [add, {isLoading}] = useCreatePredefinedTaskMutation()
    const [isOpen, setIsOpen] = useState(false);
    const {data: fileTypes} = useGetFileTypesListQuery()
    const {data: users} = useGetUsersListQuery()

    function handleCollapse() {
        setIsOpen((p) => !p);
    }

    const schema = object({
        name: string().min(2).required(),
        //todo use CONST
        visibility: string().oneOf(['global']).required(),
        //todo use CONST
        event: string().oneOf(['global', 'all', 'fileType']),
        //todo use CONST
        event_id: string().when('event', {
            is: 'fileType',
            then: (schema) => schema.uuid().required(),
            otherwise: (schema) => schema.uuid()
        }),
        task_name: string().min(2).required(),
        task_note: string(),
        task_assignee_id: string().uuid(),
        task_days_to_escalate: number().min(0),

        hidden: boolean(),
    });

    return (
        <Box mt={"6"} p={8} bg="white" rounded={"2xl"}>
            <HStack>
                <Heading size={"sm"}>{t('predefinedTasks.create.title')}</Heading>
                <Spacer/>
                <Button
                    variant={"outline"}
                    w={"auto"}
                    onClick={handleCollapse}
                    leftIcon={
                        <IoMdAddCircleOutline color="var(--chakra-colors-teal-400)"/>
                    }
                >
                    {t('predefinedTasks.create.button')}
                </Button>
            </HStack>

            <Collapse in={isOpen} animateOpacity>
                <Formik
                    validationSchema={schema}
                    validateOnMount={true}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        // todo extract!!!!!!
                        add({
                            ...Object.keys(values).reduce(
                                (item, key) => ({
                                    ...item,
                                    [key]: values[key] === '' ? null : values[key],
                                }),
                                {}
                            )
                        })
                        setSubmitting(false);
                        resetForm()
                        handleCollapse()
                    }}
                    enableReinitialize={true}
                    initialValues={{
                        name: '',
                        //todo use CONST
                        visibility: 'global',
                        event: '',
                        event_id: '',
                        task_name: '',
                        task_note: '',
                        task_assignee_id: '',
                        task_days_to_escalate: '',
                    }}>
                    {({
                          handleSubmit,
                          handleChange,
                          handleReset,
                          handleBlur,
                          values,
                          dirty,
                          touched,
                          isValid,
                          isSubmitting,
                          errors,
                          setFieldValue,
                          setFieldTouched,
                      }) => (<Form>
                        <Grid pt={4} templateColumns="repeat(3, 1fr)" columnGap={8} rowGap={6}>
                            <GridItem>
                                <FormControl isInvalid={!!errors.name && touched.name}>
                                    <AppInput name={`name`} handleBlur={handleBlur} label={t('common.name')}
                                              placeholder={t('common.name')}/>
                                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                                </FormControl>
                            </GridItem>
                            {/*todo add visibility*/}
                            <GridItem>
                                <FormControl isInvalid={!!errors.event && touched.event}>
                                    <AppSelect name={`event`}
                                               onChange={handleChange}
                                               handleBlur={handleBlur}
                                               value={values.event}
                                               label={'predefinedTasks.event'}
                                        // placeholder={t('common.select')}
                                    >
                                        <option value=''>{t('predefinedTasks.events.noEvent')}</option>
                                        <option value='all'>{t('predefinedTasks.events.all')}</option>
                                        <option value='fileType'>{t('predefinedTasks.events.fileType')}</option>
                                        {/*{fileTypes?.map((fileType) =>*/}
                                        {/*    <option key={fileType.id} value={fileType.id}>*/}
                                        {/*        {fileType.name}*/}
                                        {/*    </option>)*/}
                                        {/*}*/}
                                    </AppSelect>
                                    <FormErrorMessage>{errors.event}</FormErrorMessage>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                {values.event === 'fileType' &&
                                    <FormControl isInvalid={!!errors.event_id && touched.event_id}>
                                        <AppSelect name={`event_id`}
                                                   onChange={handleChange}
                                                   handleBlur={handleBlur}
                                                   value={values.event_id}
                                            // todo change based on event field later
                                                   label={'predefinedTasks.eventId'}
                                                   placeholder={t('common.select')}
                                        >
                                            {/*<option value=''>{t('predefinedTasks.event_id')}</option>*/}
                                            {fileTypes?.map((fileType) =>
                                                <option key={fileType.id} value={fileType.id}>
                                                    {fileType.name}
                                                </option>)
                                            }
                                        </AppSelect>
                                        <FormErrorMessage>{errors.event_id}</FormErrorMessage>
                                    </FormControl>
                                }
                            </GridItem>
                            <GridItem>
                                <FormControl isInvalid={!!errors.task_name && touched.task_name}>
                                    <AppInput name={`task_name`} handleBlur={handleBlur}
                                              label={t('predefinedTasks.taskName')}
                                              placeholder={t('predefinedTasks.taskName')}/>
                                    <FormErrorMessage>{errors.task_name}</FormErrorMessage>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl isInvalid={!!errors.task_assignee_id && touched.task_assignee_id}>
                                    <AppSelect name={`task_assignee_id`}
                                               onChange={handleChange}
                                               handleBlur={handleBlur}
                                               value={values.task_assignee_id}
                                        // todo change based on event field later
                                               label={'predefinedTasks.taskAssignee'}
                                               placeholder={t('common.select')}
                                    >
                                        {users?.map((user) =>
                                            <option key={user.id} value={user.id}>
                                                {user.first_name} {user.last_name}
                                            </option>)
                                        }
                                    </AppSelect>
                                    <FormErrorMessage>{errors.task_assignee_id}</FormErrorMessage>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl
                                    isInvalid={!!errors.task_days_to_escalate && touched.task_days_to_escalate}>
                                    <FormLabel>{t('predefinedTasks.taskDaysToEscalate')}</FormLabel>
                                    <Field name="task_days_to_escalate">
                                        {({field, form}) => (
                                            <NumberInput value={values.task_days_to_escalate} max={400} min={0}
                                                         onChange={val => form.setFieldValue(field.name, val)}>
                                                <NumberInputField/>
                                                <NumberInputStepper>
                                                    <NumberIncrementStepper/>
                                                    <NumberDecrementStepper/>
                                                </NumberInputStepper>
                                            </NumberInput>
                                        )}</Field>
                                    <FormErrorMessage>{errors.task_days_to_escalate}</FormErrorMessage>
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={3}>
                                <Stack>
                                    <Text fontSize={14}>{t('predefinedTasks.taskNote')}</Text>
                                    <FormControl isInvalid={!!errors.task_note && touched.task_note}>
                                        <InputGroup>
                                            <Textarea
                                                name={`task_note`}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    handleBlur(e)
                                                }}
                                                value={values.task_note}
                                                _focusVisible={{
                                                    borderColor: "teal.400",
                                                    boxShadow: "0 0 0 1px var(--chakra-colors-teal-400)",
                                                }}
                                                minH={150}
                                                placeholder={t('common.note', {count: 1})}
                                                borderColor={"teal.400"}
                                                _placeholder={{color: "gray.400"}}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{errors.task_note}</FormErrorMessage>
                                    </FormControl>
                                </Stack>
                            </GridItem>
                        </Grid>
                        <Center
                            mt={16}
                            sx={{
                                button: {
                                    minW: 160,
                                },
                            }}
                        >
                            <Spacer/>
                            <ButtonGroup gap={3}>
                                <Button
                                    isLoading={isLoading}
                                    loadingText={t('common.actions.saving')} type={'submit'}
                                    variant={"primary"}>{t('common.actions.save')}</Button>
                            </ButtonGroup>
                        </Center>
                    </Form>)
                    }
                </Formik>
            </Collapse>
        </Box>
    )
        ;
}

PredefinedTaskCreateSingle.propTypes = {};
