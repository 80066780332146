import React from 'react';
import {
    Box,
    Text,
    Heading,
    Button,
    createIcon,
    Container,
    Stack,
    Icon,
    Image,
    useColorModeValue,
    FormControl,
    Input,
    FormErrorMessage,
    Flex,
    SimpleGrid, Show,
} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {
    IoCheckmark,
    IoCloudDoneSharp,
    IoGitBranchSharp, IoKeySharp, IoCalendarSharp, IoBusinessSharp
} from "react-icons/io5";
import {useSubscribeEmailMutation} from "../services/subscriptionService";
import {Form, Formik} from "formik";
import {object, string} from "yup";
import ProductsImage from "../assets/productMobile.png";
import PropTypes from "prop-types";
import Pricing from "../components/Home/Pricing";
import {NavHashLink} from "react-router-hash-link";
import {Card} from "../components/Home/Card";
import {Step, StepAvatar, StepContent, StepHeading, StepText} from "../components/Home/Step";
import {Helmet} from "react-helmet";
import {HiOutlineDeviceMobile} from "react-icons/hi";

function Home() {
    const {t} = useTranslation()
    const isDevelopment = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')

    const [subscribe, {isLoading, isUninitialized, isError}] = useSubscribeEmailMutation()

    const Arrow = createIcon({
        displayName: 'Arrow',
        viewBox: '0 0 72 24',
        path: (
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.600904 7.08166C0.764293 6.8879 1.01492 6.79004 1.26654 6.82177C2.83216 7.01918 5.20326 7.24581 7.54543 7.23964C9.92491 7.23338 12.1351 6.98464 13.4704 6.32142C13.84 6.13785 14.2885 6.28805 14.4722 6.65692C14.6559 7.02578 14.5052 7.47362 14.1356 7.6572C12.4625 8.48822 9.94063 8.72541 7.54852 8.7317C5.67514 8.73663 3.79547 8.5985 2.29921 8.44247C2.80955 9.59638 3.50943 10.6396 4.24665 11.7384C4.39435 11.9585 4.54354 12.1809 4.69301 12.4068C5.79543 14.0733 6.88128 15.8995 7.1179 18.2636C7.15893 18.6735 6.85928 19.0393 6.4486 19.0805C6.03792 19.1217 5.67174 18.8227 5.6307 18.4128C5.43271 16.4346 4.52957 14.868 3.4457 13.2296C3.3058 13.0181 3.16221 12.8046 3.01684 12.5885C2.05899 11.1646 1.02372 9.62564 0.457909 7.78069C0.383671 7.53862 0.437515 7.27541 0.600904 7.08166ZM5.52039 10.2248C5.77662 9.90161 6.24663 9.84687 6.57018 10.1025C16.4834 17.9344 29.9158 22.4064 42.0781 21.4773C54.1988 20.5514 65.0339 14.2748 69.9746 0.584299C70.1145 0.196597 70.5427 -0.0046455 70.931 0.134813C71.3193 0.274276 71.5206 0.70162 71.3807 1.08932C66.2105 15.4159 54.8056 22.0014 42.1913 22.965C29.6185 23.9254 15.8207 19.3142 5.64226 11.2727C5.31871 11.0171 5.26415 10.5479 5.52039 10.2248Z"
                fill="currentColor"
            />
        ),
    });

    const schema = object({
        email: string().email('Invalid Email address').required(),
    });

    const color1 = useColorModeValue('gray.300', 'gray.700')
    const color2 = useColorModeValue('gray.800', 'gray.300')


    const Feature = ({text, icon, iconBg}) => {
        return (
            <Stack direction={'row'} align={'center'}>
                <Flex
                    w={8}
                    h={8}
                    align={'center'}
                    justify={'center'}
                    rounded={'full'}
                    bg={iconBg}>
                    {icon}
                </Flex>
                <Text fontWeight={600}>{text}</Text>
            </Stack>
        );
    };

    Feature.propTypes = {
        text: PropTypes.string.isRequired,
        icon: PropTypes.element.isRequired,
        iconBg: PropTypes.string.isRequired,
    };

    return (
        <Container maxW={'container.xl'}>
            {isDevelopment
                ? null
                : <Helmet>
                    <script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></script>
                </Helmet>
            }
            <Stack
                as={Box}
                textAlign={'center'}
                spacing={{base: 8, md: 14}}
                py={{base: 20, md: 36}}>
                <Heading
                    fontWeight={600}
                    fontSize={{base: '2xl', sm: '4xl', md: '6xl'}}
                    lineHeight={'110%'}>
                    {t('landing.hero.title1')}
                    <Text as={'span'} color={'teal.400'}>
                        {t('landing.hero.title2')}
                    </Text>
                    {t('landing.hero.title3')}
                </Heading>
                <Text color={'gray.500'} fontSize={'lg'}>
                    <b>{t('landing.hero.subTitle1')}</b><br/>{t('landing.hero.subTitle2')}
                </Text>
                <Stack
                    direction={'column'}
                    spacing={3}
                    align={'center'}
                    alignSelf={'center'}
                    position={'relative'}>
                    <NavHashLink
                        to="/#pricing"
                    >
                        <Button
                            colorScheme={'teal'}
                            bg={'teal.400'}
                            rounded={'full'}
                            px={6}
                            _hover={{
                                bg: 'teal.500',
                            }}>
                            {t('landing.hero.getStarted')}
                        </Button>
                    </NavHashLink>
                    {/*<Button variant={'link'} colorScheme={'blue'} size={'sm'}>*/}
                    {/*    Learn more*/}
                    {/*</Button>*/}
                    <Box>
                        <Icon
                            as={Arrow}
                            color={useColorModeValue('gray.800', 'gray.300')}
                            w={71}
                            position={'absolute'}
                            right={-71}
                            top={'10px'}
                        />
                        <Text
                            fontSize={'xl'}
                            fontFamily={'Caveat'}
                            position={'absolute'}
                            right={'-125px'}
                            top={'-15px'}
                            transform={'rotate(10deg)'}>
                            {t('landing.hero.startingAt')}
                        </Text>
                    </Box>
                </Stack>
            </Stack>
            <Box p={{base: 20, md: 20}}>
                <SimpleGrid columns={{base: 1, md: 2}} spacing={10}>
                    <Stack spacing={4}>
                        <Heading>{t('landing.leading.title')}</Heading>
                        <Text color={'gray.500'} fontSize={'lg'}>
                            {t('landing.leading.text')}
                        </Text>
                        {/*<Stack*/}
                        {/*    spacing={4}*/}
                        {/*    divider={*/}
                        {/*        <StackDivider*/}
                        {/*            borderColor={useColorModeValue('teal.100', 'teal.700')}*/}
                        {/*        />*/}
                        {/*    }>*/}
                        {/*    <Feature*/}
                        {/*        icon={*/}
                        {/*            <Icon as={IoCloudDoneSharp} color={'yellow.500'} w={5} h={5}/>*/}
                        {/*        }*/}
                        {/*        iconBg={useColorModeValue('yellow.100', 'yellow.900')}*/}
                        {/*        text={t('landing.feature.feature1')}*/}
                        {/*    />*/}
                        {/*    <Feature*/}
                        {/*        icon={<Icon as={IoGitBranchSharp} color={'green.500'} w={5} h={5}/>}*/}
                        {/*        iconBg={useColorModeValue('green.100', 'green.900')}*/}
                        {/*        text={t('landing.feature.feature2')}*/}
                        {/*    />*/}
                        {/*    <Feature*/}
                        {/*        icon={*/}
                        {/*            <Icon as={IoKeySharp} color={'red.500'} w={5} h={5}/>*/}
                        {/*        }*/}
                        {/*        iconBg={useColorModeValue('red.100', 'red.900')}*/}
                        {/*        text={t('landing.feature.feature3')}*/}
                        {/*    />*/}
                        {/*    <Feature*/}
                        {/*        icon={*/}
                        {/*            <Icon as={IoCalendarSharp} color={'purple.500'} w={5} h={5}/>*/}
                        {/*        }*/}
                        {/*        iconBg={useColorModeValue('purple.100', 'purple.900')}*/}
                        {/*        text={t('landing.feature.feature4')}*/}
                        {/*    />*/}
                        {/*</Stack>*/}
                    </Stack>
                    <Flex>
                        <Image
                            // rounded={'md'}
                            alt={'feature image'}
                            src={ProductsImage}
                            objectFit={'contain'}
                        />
                    </Flex>
                </SimpleGrid>
            </Box>
            <Box p={4}>
                <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
                    <Heading fontSize={{base: '2xl', sm: '4xl'}} fontWeight={'bold'}>
                        {t('landing.features.title')}
                    </Heading>
                    {/*<Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }}>*/}
                    {/*    {t('landing.features.text')}*/}

                    {/*</Text>*/}
                </Stack>


                {/*        icon={*/}
                {/*            <Icon as={IoKeySharp} color={'red.500'} w={5} h={5}/>*/}
                {/*        }*/}
                {/*        iconBg={useColorModeValue('red.100', 'red.900')}*/}
                {/*        text={t('landing.feature.feature3')}*/}
                {/*    />*/}
                {/*    <Feature*/}
                {/*        icon={*/}
                {/*            <Icon as={IoCalendarSharp} color={'purple.500'} w={5} h={5}/>*/}
                {/*        }*/}
                {/*        iconBg={useColorModeValue('purple.100', 'purple.900')}*/}
                {/*        text={t('landing.feature.feature4')}*/}
                {/*    />*/}
                <Container maxW={'5xl'} mt={12}>
                    <Flex flexWrap="wrap" gridGap={6} justify="center">
                        <Card
                            heading={t('landing.features.one.title')}
                            icon={<Icon as={IoCloudDoneSharp} color={'yellow.500'} w={10} h={10}/>}
                            iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                            description={t('landing.features.one.text')}
                            // href={'#'}
                        />
                        <Card
                            heading={t('landing.features.two.title')}
                            icon={<Icon as={IoKeySharp} color={'red.500'} w={10} h={10}/>}
                            iconBg={useColorModeValue('red.100', 'red.900')}
                            description={t('landing.features.two.text')}
                            // href={'#'}
                        />
                        <Card
                            heading={t('landing.features.three.title')}
                            icon={<Icon as={IoGitBranchSharp} color={'green.500'} w={10} h={10}/>}
                            iconBg={useColorModeValue('green.100', 'green.900')}
                            description={t('landing.features.three.text')}
                            // href={'#'}
                        />
                        <Card
                            heading={t('landing.features.four.title')}
                            icon={<Icon as={HiOutlineDeviceMobile} color={'pink.500'} w={10} h={10}/>}
                            iconBg={useColorModeValue('pink.100', 'pink.900')}
                            description={t('landing.features.four.text')}
                            href={'#'}
                            isSoon={true}
                        />
                        <Card
                            heading={t('landing.features.five.title')}
                            icon={<Icon as={IoCalendarSharp} color={'purple.500'} w={10} h={10}/>}
                            iconBg={useColorModeValue('purple.100', 'purple.900')}
                            description={t('landing.features.five.text')}
                            href={'#'}
                            isSoon={true}

                        />
                        <Card
                            heading={t('landing.features.six.title')}
                            icon={<Icon as={IoBusinessSharp} color={'blue.500'} w={10} h={10}/>}
                            iconBg={useColorModeValue('blue.100', 'blue.900')}
                            description={t('landing.features.six.text')}
                            href={'#'}
                            isSoon={true}
                        />
                    </Flex>
                </Container>
            </Box>
            <Box mt={16}>
                <Stack mb={12} spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
                    <Heading fontSize={{base: '2xl', sm: '4xl'}} fontWeight={'bold'}>
                        {t('landing.meeting.title')}
                    </Heading>
                </Stack>
            <div className="meetings-iframe-container"
                 data-src="https://meetings-eu1.hubspot.com/martin-janosik?embed=true"></div>
            </Box>
            <Box bg={useColorModeValue('gray.100', 'gray.700')}>
                <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
                    <Stack align={'center'} spacing={4}>
                        <Heading>{t('landing.steps.title')} <Text as={'span'} color={'teal.400'}>
                            {t('landing.steps.title2')}
                        </Text></Heading>
                        <Text color={'gray.500'} fontSize={'lg'}>
                            {t('landing.steps.text')}
                        </Text>
                    </Stack>
                    <Stack
                        direction={{base: 'column', md: 'row'}}
                        spacing={{base: 10, md: 4, lg: 10}}>
                        <Step>
                            <StepContent>
                                <StepHeading>{t('landing.steps.one.title')}</StepHeading>
                                <StepText>{t('landing.steps.one.text')}</StepText>
                            </StepContent>
                            <StepAvatar
                                step={'1 .'}
                            />
                        </Step>
                        <Step>
                            <StepContent>
                                <StepHeading>{t('landing.steps.two.title')}</StepHeading>
                                <StepText>{t('landing.steps.two.text')}</StepText>
                            </StepContent>
                            <StepAvatar
                                step={'2 .'}
                            />
                        </Step>
                        <Step>
                            <StepContent>
                                <StepHeading>{t('landing.steps.three.title')}</StepHeading>
                                <StepText>{t('landing.steps.three.text')}</StepText>
                            </StepContent>
                            <StepAvatar
                                step={'3 .'}
                            />
                        </Step>
                    </Stack>
                </Container>
            </Box>

            <Stack p={{base: 20, md: 20}}>
                <Container
                    maxW={'lg'}
                    bg={useColorModeValue('white', 'whiteAlpha.100')}
                    boxShadow={'xl'}
                    rounded={'lg'}
                    p={6}
                    direction={'column'}>
                    <Heading
                        as={'h2'}
                        fontSize={{base: 'xl', sm: '2xl'}}
                        textAlign={'center'}
                        mb={5}>
                        {t('landing.newsletter.title')}
                    </Heading>
                    <Formik
                        onSubmit={(values, {setSubmitting, resetForm}) => {
                            setSubmitting(true);
                            subscribe(values)
                            setSubmitting(false);
                        }}
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{
                            email: '',
                            source: 'home-newsletter',
                        }}>
                        {({
                              handleSubmit,
                              handleChange,
                              handleReset,
                              handleBlur,
                              values,
                              dirty,
                              touched,
                              isValid,
                              isSubmitting,
                              errors,
                              setFieldValue,
                              setFieldTouched,
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Stack
                                    direction={{base: 'column', md: 'row'}}
                                    spacing={'12px'}
                                >
                                    <FormControl isInvalid={!!errors.email && touched.email}>
                                        <Input
                                            variant={'solid'}
                                            borderWidth={1}
                                            color={'gray.800'}
                                            _placeholder={{
                                                color: 'gray.400',
                                            }}
                                            borderColor={color1}
                                            id={'email'}
                                            name={'email'}
                                            type={'email'}
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={!!errors.email && touched.email}
                                            required
                                            placeholder={t('landing.newsletter.placeholder')}
                                            aria-label={t('landing.newsletter.placeholder')}
                                            disabled={!isUninitialized}
                                        />
                                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl w={{base: '100%', md: '40%'}} position={'relative'}>
                                        <Button
                                            colorScheme={isUninitialized ? 'teal' : 'green'}
                                            isLoading={isLoading}
                                            w="100%"
                                            type={isUninitialized ? 'submit' : 'button'}>
                                            {isUninitialized ? t('common.actions.submit') : <IoCheckmark/>}
                                        </Button>
                                        <Show above='md'>
                                            <Box>
                                                <Icon
                                                    as={Arrow}
                                                    color={color2}
                                                    w={71}
                                                    position={'absolute'}
                                                    right={-71}
                                                    top={'18px'}
                                                />
                                                <Text
                                                    fontSize={'lg'}
                                                    fontFamily={'Caveat'}
                                                    position={'absolute'}
                                                    w={155}
                                                    right={'-145px'}
                                                    top={'-68px'}
                                                    transform={'rotate(10deg)'}>
                                                    {t('landing.newsletter.note')}                                                    </Text>
                                            </Box>
                                        </Show>
                                    </FormControl>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                    <Show below='md'>
                        <Text
                            mt={2}
                            fontSize={'lg'}
                            fontFamily={'Caveat'}
                            textAlign={'center'}>
                            {t('landing.newsletter.note')}
                        </Text>
                    </Show>
                    <Text
                        mt={2}
                        textAlign={'center'}
                        color={isError ? 'red.500' : 'gray.500'}>
                        {isError
                            ? t('landing.newsletter.error')
                            : t('landing.newsletter.spam')
                        }
                    </Text>
                </Container>
            </Stack>
            <Pricing/>
            <Box p={{base: 20, md: 20}}>
            </Box>
        </Container>
    );
}

export default Home;
