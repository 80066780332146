import {Navigate, Outlet, useLocation} from 'react-router-dom'
import {useAuth} from '../hooks/useAuth'
import {isLoggedIn} from "../slices/authSlice";
import {useGetCurrentUserQuery} from "../services/userService";
import {hasAllowedRole, hasSubscription} from "./userRoleHelper";
import AccessDenied from "../pages/AccessDenied";
import {useGetSubscriptionQuery} from "../services/stripeSubscriptionService";
import {Role} from "../types";
import {isAgencyClient} from "./generalHelper";

export function PrivateOutlet(
    {
        allowedRoles = [],
        // allowedCompanyTypes = [],
        isStrictRole = false
    } : {
        allowedRoles?: Role[],
        // allowedCompanyTypes?: AccountType[],
        isStrictRole?: boolean,
        showMessage?: boolean,
    }) {
    const auth = useAuth()
    const location = useLocation()
    const {data, isFetching} = useGetCurrentUserQuery(undefined, {skip: !isLoggedIn(auth)})
    const {
        data: subscription,
        isFetching: isFetchingSubscription
    } = useGetSubscriptionQuery(undefined, {skip: !isLoggedIn(auth)})

    if (isFetching || isFetchingSubscription) {
        //     todo loading
        return null
    }
    if (!isLoggedIn(auth)) {
        return <Navigate to="/login" state={{from: location}}/>
    }

    // if (!hasSubscription(subscription) && !isEnterprise(data.company)) {
    if (!hasSubscription(subscription) && !isAgencyClient(data.company)
        && !(location.pathname === '/subscription'
            || location.pathname.startsWith('/setting')
            || location.pathname.startsWith('/admin'))
    ) {
        return <Navigate to="/subscription" state={{from: location}}/>
    }

    return (hasAllowedRole(data, allowedRoles, isStrictRole)
            ? <Outlet/>
            : <AccessDenied/>
    )
}
