import React from "react";
import {
    Avatar,
    Button,
    ButtonGroup,
    Center,
    Grid,
    GridItem,
    HStack,
    Heading,
    Modal,
    ModalContent,
    ModalOverlay,
    Spacer,
    Stack,
    Text,
} from "@chakra-ui/react";
import Chip from "../Chip";
import {BsFileEarmarkCheckFill} from "react-icons/bs";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {closeTaskViewModal, isTaskViewOpen, selectTaskViewModal} from "../../slices/taskViewModalSlice";
import {getEscalationColour, getTaskColour} from "../../utils/taskHelper";
import Moment from "react-moment";
import AppSelect from "../AppSelect";
import {Form, Formik} from "formik";
import {object, string} from "yup";
import {TaskStatus} from "../../constants/status";
import {useUpdateTaskStatusMutation} from "../../services/taskService";
import {isFilled} from "../../utils/generalHelper";
import {IoHandLeft, IoShareOutline} from "react-icons/io5";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {copyToast} from "../../slices/toastSlice";
import FilesTable from "../Files/FilesTable";

export default function TaskView() {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch()
    const isModalOpen = useSelector(isTaskViewOpen)
    const taskViewModal = useSelector(selectTaskViewModal)
    const task = taskViewModal.task
    const [update, {isLoading}] = useUpdateTaskStatusMutation()

    const fields = [
        {
            title: 'common.createdBy',
            field: 'creator',
            type: 'profile',
        },
        {
            title: 'common.assignee',
            field: 'assignee',
            type: 'profile',
        },
        {
            title: 'common.createdAt',
            field: 'created_at',
            type: 'date',
        },
    ];

    const schema = object({
        status: string().oneOf(Object.values(TaskStatus)).required(),
    });


    return (
        <Modal isOpen={isModalOpen} onClose={() => dispatch(closeTaskViewModal())}>
            <ModalOverlay/>
            <ModalContent p={8} maxW={950} borderRadius={"2xl"}>
                <Formik
                    validationSchema={schema}
                    validateOnMount={true}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        update({id: task.id, ...values})
                        setSubmitting(false);
                        resetForm()
                    }}
                    enableReinitialize={true}
                    initialValues={{
                        status: task?.status,
                    }}>
                    {({
                          handleSubmit,
                          handleChange,
                          handleReset,
                          handleBlur,
                          values,
                          dirty,
                          touched,
                          isValid,
                          isSubmitting,
                          errors,
                          setFieldValue,
                          setFieldTouched,
                      }) => (<Form>
                        <Stack gap={4}>
                            <Center mb={4}>
                                <Stack gap={0}>
                                    <HStack>
                                        <Heading size={"md"}>{t('tasks.task', {count: 1})}</Heading>
                                        <Chip w={70} p={1} fontSize={12} color={getTaskColour(task?.status)}>
                                            {t(`tasks.status.${task?.status}`).toUpperCase()}
                                        </Chip>
                                    </HStack>
                                    <Text mt={"0 !important"} color={"teal.400"}>
                                        {task?.name}
                                    </Text>
                                </Stack>
                                <Spacer/>
                                <ButtonGroup>
                                    <CopyToClipboard text={`${window.location.origin}/tasks/${task?.id}`}
                                                     onCopy={() => dispatch(copyToast())}>
                                        <Button
                                            bg={"transparent"}
                                            color={"teal.400"}
                                            leftIcon={
                                                <IoShareOutline color="var(--chakra-colors-teal-400)"/>
                                            }
                                        >
                                            {t('common.actions.share')}
                                        </Button>
                                    </CopyToClipboard>
                                </ButtonGroup>
                                {/*<Button w={"auto"} bg={"transparent"} color={"teal.400"}>*/}
                                {/*    EDIT*/}
                                {/*</Button>*/}
                            </Center>
                            <Grid templateColumns="repeat(3, 1fr)" gap={4} columnGap={12}>
                                {fields.map(({title, field, type = null}, index) => {
                                    return (
                                        <GridItem key={"c" + index}>
                                            <Stack>
                                                <Heading size={"sm"}>{t(title)}</Heading>
                                                <HStack>
                                                    {type === 'profile' && <Avatar size={"sm"}
                                                                                   name={`${task?.[field]?.first_name} ${task?.[field]?.last_name}`}
                                                                                   src=""/>}
                                                    <Heading
                                                        size={"xs"}
                                                        fontWeight={type === 'profile' ? 600 : 400}
                                                        // color={task?.[field] !== null ? "black" : "gray.600"}
                                                        color={"black"}
                                                    >
                                                        {type === 'profile'
                                                            ? `${task?.[field]?.first_name} ${task?.[field]?.last_name}`
                                                            : type !== 'date'
                                                                ? task?.[field]
                                                                : isFilled(task?.[field]) ?
                                                                    <Moment format={'MMM D, YYYY'}
                                                                            locale={i18n.language}>{task?.[field]}</Moment> : null
                                                        }
                                                    </Heading>
                                                </HStack>
                                            </Stack>
                                        </GridItem>
                                    );
                                })}
                                <GridItem>
                                    <Stack>
                                        <Heading size={"sm"}>{t('common.status')}</Heading>
                                        <AppSelect handleBlur={handleBlur} onChange={handleChange} name={'status'}
                                                   value={values.status}>
                                            {Object.values(TaskStatus)?.map((status) =>
                                                <option key={status}
                                                        value={status}>{t(`tasks.status.${status}`)}</option>
                                            )}
                                        </AppSelect>
                                    </Stack>
                                </GridItem>
                                <GridItem>
                                    <Stack>
                                        <Heading size={"sm"}>{t('tasks.timeLeft')}</Heading>
                                        <HStack gap={1}>
                                            <BsFileEarmarkCheckFill
                                                fontSize={20}
                                                color={`var(--chakra-colors-${getEscalationColour(task?.days_left, task?.status)}-400)`}
                                            />
                                            <Text>{(task?.status !== 'done' && task?.status !== 'closed') && `${task?.days_left} d`}</Text>
                                        </HStack>
                                    </Stack>
                                </GridItem>
                                <GridItem>
                                    <Stack>
                                        <Heading size={"sm"}>{t('tasks.completeBy')}</Heading>
                                        <HStack>
                                            <Heading
                                                size={"xs"}
                                                fontWeight={400}
                                                color={"black"}
                                            >
                                                {isFilled(task?.escalation_date)
                                                    ? <Moment format={'MMM D, YYYY'}
                                                              locale={i18n.language}>{task?.escalation_date}</Moment>
                                                    : null
                                                }
                                            </Heading>
                                        </HStack>
                                    </Stack>
                                </GridItem>
                            </Grid>

                            <Stack>
                                <Heading size={"sm"}>{t('common.note', {count: 1})}</Heading>
                                <Text sx={{mt: "4px !important"}}>{task?.note}</Text>
                            </Stack>

                            <Stack>
                                <Heading size={"sm"}>{t('files.file', {count: 2})}</Heading>
                                {task?.user_has_access_to_file === false
                                    ? <HStack gap={1}>
                                        <IoHandLeft ontSize={20} color={`var(--chakra-colors-red-300)`}/>
                                        <Text>{t('files.noAccess', {count: 2})}</Text>
                                    </HStack>
                                    : <FilesTable files={task?.file ? [task?.file]: []} inModal={true}/>
                                }
                            </Stack>

                            {/*<Stack>*/}
                            {/*  <Heading size={"sm"}>Comments</Heading>*/}
                            {/*  <Stack>*/}
                            {/*    <HStack gap={2}>*/}
                            {/*      <Avatar size={"sm"} name={"a"} src="" />*/}
                            {/*      <Stack>*/}
                            {/*        <HStack>*/}
                            {/*          <Heading size={"sm"}>John smith</Heading>*/}
                            {/*          <Text mt={"0 !important"} fontSize={14} color={"gray.400"}>*/}
                            {/*            23 mins ago*/}
                            {/*          </Text>*/}
                            {/*        </HStack>*/}

                            {/*        <Text mt={"0 !important"} fontSize={14}>*/}
                            {/*          It is a long established fact that a reader will be*/}
                            {/*          distracted by the readable content of a page when looking at*/}
                            {/*          its layout.*/}
                            {/*        </Text>*/}
                            {/*      </Stack>*/}
                            {/*    </HStack>*/}
                            {/*  </Stack>*/}
                            {/*</Stack>*/}

                            {/*<InputGroup>*/}
                            {/*  <Input placeholder="Comments" />*/}
                            {/*  <InputRightAddon*/}
                            {/*    cursor={"pointer"}*/}
                            {/*    // borderRadius={0}*/}
                            {/*    children={<IoMdSend color="var(--chakra-colors-teal-400)" />}*/}
                            {/*  />*/}
                            {/*</InputGroup>*/}

                            <ButtonGroup>
                                <Spacer/>
                                <Button
                                    onClick={() => dispatch(closeTaskViewModal())}>{t('common.actions.cancel')}</Button>
                                <Button isLoading={isLoading}
                                        loadingText={t('common.actions.saving')}
                                        colorScheme="blue" bg={"teal.400"} type={'submit'}>
                                    {t('common.actions.save')}
                                </Button>
                            </ButtonGroup>
                        </Stack>
                    </Form>)}
                </Formik>
            </ModalContent>
        </Modal>
    );
}
