import {baseApi} from "./apiService";

export const fileTypeApi = baseApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getFileTypesList: builder.query({
            query: (page) => ({
                url: 'file_types',
                params: {page: page},
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [...result.map(({id}) => ({type: 'FileTypes', id})), 'FileTypes']
                    : ['FileTypes'],
        }),
        createFileType: builder.mutation({
            query: (body) => ({
                url: `file_types`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['FileTypes'],
        }),
    })
});

export const {useCreateFileTypeMutation, useGetFileTypesListQuery,} = fileTypeApi
