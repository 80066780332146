import React, {ReactElement} from 'react';
import {
    Box,
    useRadio, useColorModeValue
} from '@chakra-ui/react';

const RadioCard = (
    {
        children,
        ...props
    }: {
        children: ReactElement
        isChecked: boolean;
    }) => {

    const {getInputProps, getCheckboxProps} = useRadio(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    const bg = useColorModeValue("teal.400", "teal.600");
    const color = useColorModeValue("white", "gray.800");
    const bgGhost = useColorModeValue("white", "gray.800");
    const colorGhost = useColorModeValue("gray.300", "gray.400");

    return (
        <Box as="label">
            <input {...input} />
            <Box
                {...checkbox}
                cursor="pointer"
                borderRadius="full"
                fontWeight="bold" // This will make the text bold
                _checked={{
                    bg: bg,
                    color: color,
                }}
                _focus={{
                    boxShadow: "outline",
                }}
                _hover={{
                    bg: useColorModeValue("gray.100", "gray.700"),
                }}
                px={4}
                py={2}
                // variant={"ghost"}
                bg={props.isChecked ? bg : bgGhost}
                color={props.isChecked ? color : colorGhost}
                // border={props.isChecked ? "" : borderGhost}
            >
                {children}
            </Box>
        </Box>
    );
};

export default RadioCard;
