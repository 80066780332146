import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store/store";
import {SortingState} from "@tanstack/react-table";
import {SortDirection, SortValues} from "../types";



const taskSortModalSliceInitialState = {
    showModal: false,
    //this one is needed due to tanstack table!
    sorting: [{id: 'daysLeft', desc: true}],
    sortField:  'daysLeft',
    sortDirection:  SortDirection.Desc,
};

type SetModalSortingAction = {
    payload: SortValues
    type: string;
};

const taskSortModalSlice = createSlice({
    name: 'taskSortModal',
    initialState: taskSortModalSliceInitialState,
    reducers: {
        showTaskSortModal: (state) => {
            state.showModal = true
        },
        closeTaskSortModal: (state) => {
            state.showModal = false
        },
        clearTaskSort: (state) => {
            state.sorting = taskSortModalSliceInitialState.sorting
            state.sortField = taskSortModalSliceInitialState.sortField
            state.sortDirection = taskSortModalSliceInitialState.sortDirection
        },
        setSorting: (state, action: PayloadAction<SortingState | ((oldState: SortingState) => SortingState)>) => {
            const payload = action.payload;
            if (typeof payload === 'function') {
                state.sorting = payload(state.sorting);
                state.sortField = payload(state.sorting)[0]?.id
                state.sortDirection = typeof payload(state.sorting)[0]?.desc === "boolean"
                    ? payload(state.sorting)[0].desc
                        ? SortDirection.Desc
                        : SortDirection.Asc
                    : SortDirection.Desc
            } else {
                state.sorting = payload;
                state.sortField = payload[0]?.id
                state.sortDirection = typeof payload[0]?.desc === "boolean"
                    ? payload[0].desc
                        ? SortDirection.Desc
                        : SortDirection.Asc
                    : SortDirection.Desc
            }
        },
        setModalSorting: (state, action: SetModalSortingAction) => {
            state.sorting[0].id = action.payload.sortField;
            state.sorting[0].desc = action.payload.sortDirection === SortDirection.Desc ;
            state.sortField = action.payload.sortField;
            state.sortDirection = action.payload.sortDirection;
        }
    },
    extraReducers: (builder) => {
        // Needed to refresh task to see new task for example in modal
    },
})

export const {showTaskSortModal, closeTaskSortModal, clearTaskSort, setSorting, setModalSorting} = taskSortModalSlice.actions

export const isTaskSortOpen = (state: RootState) => state.taskSortModal.showModal

export const isTaskSorted = (state: RootState) => state.taskSortModal.sorting !== taskSortModalSliceInitialState.sorting

export const selectTaskSortModal = (state: RootState) => state.taskSortModal
export const selectTaskRawSorting = (state: RootState) => state.taskSortModal.sorting

export default taskSortModalSlice.reducer
