import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Alert,
    AlertIcon,
    Badge,
    Box,
    Button,
    Center, createIcon,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Slider,
    SliderFilledTrack,
    SliderMark,
    SliderThumb,
    SliderTrack,
    Spacer,
    Stack,
    Tag,
    Text, useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import React, {useState} from "react";
import {AiFillCheckCircle} from "react-icons/ai";
import {PiUserSwitchFill} from "react-icons/pi";
import {useTranslation} from "react-i18next";
import {AccountType, StripePricingLookup} from "../../types";
import {useNavigate} from "react-router-dom";

const Pricing = (
    {
        accountType = null,
        buttonEvent,
        isPersonalDisabled = false,
        isSubscription = false,
    }: {
        accountType: AccountType | null,
        buttonEvent?: ({productName, seats}: { productName: string, seats: number }) => void
        isPersonalDisabled?: boolean,
        isSubscription?: boolean,
    }) => {

    const [users, setUsers] = useState(1);
    const [isYearly, setIsYearly] = useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const calculateMemory = (users: number): number => {
        if (users <= 2) {
            return 3;
        } else {
            return Math.ceil(1.5 * users);
        }
    };

    const handleClick = (productName: string) => {
        if (buttonEvent) {
            buttonEvent({productName, seats: users});
        } else {
            navigate('/register')
        }
    };

    const cardsData = [
        {
            title: t('pricing.personal.title'),
            text: t('pricing.personal.text'),
            price: `€ ${isYearly ? 50 : 5}`,
            features: [
                {
                    text: t('pricing.personal.features.feature1'),
                },
                {
                    text: t('pricing.personal.features.feature2'),
                },
                {
                    text: t('pricing.personal.features.feature3'),
                },
                {
                    text: t('pricing.personal.additionalFeatures.feature1'),
                },
            ],
            button: {
                action: () => null,
                disabled: true,
                text: isSubscription ? t('pricing.personal.buttonSubscribe') : t('pricing.personal.button'),
            },
        },
        {
            title: t('pricing.professional.title'),
            text: t('pricing.professional.text'),
            price: `€ ${isYearly ? 80 * users : 8 * users}`,
            features: [
                {
                    text: t('pricing.professional.features.feature1', {users: users}),
                },
                {
                    text: t('pricing.professional.features.feature2', {memory: calculateMemory(users)}),
                },
                {
                    text: t('pricing.professional.features.feature3'),
                },
                {
                    text: t('pricing.professional.features.feature4'),
                },
                {
                    text: t('pricing.professional.features.feature5'),
                },
                {
                    text: t('pricing.professional.features.feature6'),
                },
                {
                    text: t('pricing.professional.features.feature7'),
                },
                {
                    text: t('pricing.professional.additionalFeatures.feature1'),
                },
                {
                    text: t('pricing.professional.additionalFeatures.feature2'),
                },
            ],
            agency: [
                {
                    text: t('pricing.professional.agencyFeatures.feature1'),
                },
                {
                    text: t('pricing.professional.agencyFeatures.feature2'),
                },
                {
                    text: t('pricing.professional.agencyAdditionalFeatures.feature1'),
                },
                {
                    text: t('pricing.professional.agencyAdditionalFeatures.feature2'),
                },
            ],
            button: {
                action: () => handleClick(isYearly ? StripePricingLookup.businessYearly : StripePricingLookup.businessMonthly),
                disabled: false,
                text: isSubscription ? t('pricing.professional.buttonSubscribe') : t('pricing.professional.button'),
            },
        },
        {
            title: t('pricing.premium.title'),
            text: t('pricing.premium.text'),
            price: "Custom",
            features: [
                {
                    text: t('pricing.premium.features.feature1', {users: users}),
                },
                {
                    text: t('pricing.premium.features.feature2'),
                },
                {
                    text: t('pricing.premium.features.feature3'),
                },
                {
                    text: t('pricing.premium.features.feature4'),
                },
                {
                    text: t('pricing.premium.features.feature5'),
                },
                {
                    text: t('pricing.premium.features.feature6'),
                },
                {
                    text: t('pricing.premium.features.feature7'),
                },
                {
                    text: t('pricing.premium.features.feature8'),
                },
                {
                    text: t('pricing.premium.features.feature9'),
                },
            ],
            agency: [
                {
                    text: t('pricing.premium.agencyFeatures.feature1'),
                },
                {
                    text: t('pricing.premium.agencyFeatures.feature2'),
                },
            ],
            button: {
                action: () => window.location.href = 'mailto:info@filetica.com',
                // action: isSubscription
                //     ? () => window.location.href = 'mailto:info@filetica.com'
                // : () => handleClick('enterprise'),
                disabled: false,
                text: t('pricing.premium.buttonSubscribe'),
            },
        },
    ];

    const Arrow = createIcon({
        displayName: 'Arrow',
        viewBox: '0 0 72 24',
        path: (
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.600904 7.08166C0.764293 6.8879 1.01492 6.79004 1.26654 6.82177C2.83216 7.01918 5.20326 7.24581 7.54543 7.23964C9.92491 7.23338 12.1351 6.98464 13.4704 6.32142C13.84 6.13785 14.2885 6.28805 14.4722 6.65692C14.6559 7.02578 14.5052 7.47362 14.1356 7.6572C12.4625 8.48822 9.94063 8.72541 7.54852 8.7317C5.67514 8.73663 3.79547 8.5985 2.29921 8.44247C2.80955 9.59638 3.50943 10.6396 4.24665 11.7384C4.39435 11.9585 4.54354 12.1809 4.69301 12.4068C5.79543 14.0733 6.88128 15.8995 7.1179 18.2636C7.15893 18.6735 6.85928 19.0393 6.4486 19.0805C6.03792 19.1217 5.67174 18.8227 5.6307 18.4128C5.43271 16.4346 4.52957 14.868 3.4457 13.2296C3.3058 13.0181 3.16221 12.8046 3.01684 12.5885C2.05899 11.1646 1.02372 9.62564 0.457909 7.78069C0.383671 7.53862 0.437515 7.27541 0.600904 7.08166ZM5.52039 10.2248C5.77662 9.90161 6.24663 9.84687 6.57018 10.1025C16.4834 17.9344 29.9158 22.4064 42.0781 21.4773C54.1988 20.5514 65.0339 14.2748 69.9746 0.584299C70.1145 0.196597 70.5427 -0.0046455 70.931 0.134813C71.3193 0.274276 71.5206 0.70162 71.3807 1.08932C66.2105 15.4159 54.8056 22.0014 42.1913 22.965C29.6185 23.9254 15.8207 19.3142 5.64226 11.2727C5.31871 11.0171 5.26415 10.5479 5.52039 10.2248Z"
                fill="currentColor"
            />
        ),
    });

    const color = useColorModeValue('gray.800', 'gray.300')


    return (
        <>
            <Box px="16" mb="16" id={'pricing'}>
                <Heading textAlign="center" fontSize="6xl" mb="4">
                    {t('pricing.heading')}
                </Heading>
                <Text textAlign="center" mb={4} fontSize="lg" color={'gray.500'}>
                    {t('pricing.text')}
                </Text>
                <Center mb="16">
                    <HStack
                        justify="center"
                        position={'relative'}
                        borderRadius="full"
                        w="260px"
                        h="52px"
                        bg="white"
                        px="2"
                    >
                        <Button
                            flex="1"
                            borderRadius="full"
                            onClick={() => setIsYearly(false)}
                            isActive={!isYearly}
                            bg="transparent"
                            _active={{
                                bg: "teal.500",
                                color: "white",
                            }}
                            color="teal.500"
                        >
                            {t('pricing.monthly')}
                        </Button>
                        <Button
                            flex="1"
                            borderRadius="full"
                            onClick={() => setIsYearly(true)}
                            isActive={isYearly}
                            _active={{
                                bg: "teal.500",
                                color: "white",
                            }}
                            bg="transparent"
                            color="teal.500"
                        >
                            {t('pricing.yearly')}
                        </Button>
                        <Box>
                            <Icon
                                as={Arrow}
                                color={color}
                                w={71}
                                position={'absolute'}
                                right={-66}
                                top={'21px'}
                            />
                            <Text
                                fontSize={'xl'}
                                fontFamily={'Caveat'}
                                position={'absolute'}
                                w={155}
                                right={'-160px'}
                                top={'-4px'}
                                transform={'rotate(10deg)'}
                            >
                                {t('pricing.twoMonthsFree')}
                            </Text>
                        </Box>
                    </HStack>
                </Center>

                <Slider
                    onChange={(value) => setUsers(value)}
                    aria-label="slider-ex-4"
                    defaultValue={users}
                    //todo disable if usertype personal
                    // isDisabled={AccountType.personal}
                    mb="8"
                    value={users}
                    min={1}
                    max={50}
                >
                    {[1, 5, 10, 20, 30, 50].map((value) => (
                        <SliderMark
                            key={value}
                            value={value}
                            ml="-2"
                            pos="relative"
                            zIndex="2"
                        >
                            <VStack>
                                <Box
                                    placeItems="center"
                                    h="8px"
                                    bg={value <= users ? "transparent" : "teal.700"}
                                    w="8px"
                                    borderRadius="4px"
                                    mt="-1"
                                />

                                <Text
                                    size="xs"
                                    maxW={{base: "59.4", md: "75.2px"}}
                                    textAlign="center"
                                >
                                    {value}
                                </Text>
                            </VStack>
                        </SliderMark>
                    ))}

                    <SliderTrack bg="blue.100">
                        <SliderFilledTrack bg="teal"/>
                    </SliderTrack>
                    <SliderThumb boxSize={6} pos="relative">
                        <Icon color="teal" as={PiUserSwitchFill}/>
                        <Box fontSize="xl" textAlign="center" pos="absolute" top="-7">
                            {users}
                        </Box>
                        <Box textAlign="center" pos="absolute" top="-14">
                            <Badge colorScheme="teal">{t('common.user', {count: users})}</Badge>
                        </Box>
                    </SliderThumb>
                </Slider>
                {isSubscription
                    ? <Alert status='info' mt={6} alignItems='center'
                             justifyContent='center'
                             textAlign='center'>
                        <AlertIcon/>
                        {t(accountType === AccountType.personal ? 'pricing.personalNote' : 'pricing.companyAgencyNote')}
                    </Alert>
                    : undefined
                }
            </Box>
            <Box>
                <Grid
                    gridGap={{
                        base: "12",
                        lg: "4",
                    }}
                    maxW="75rem"
                    gridTemplateColumns={{
                        md: "repeat(2, 1fr)",
                        lg: "repeat(3, 1fr)",
                    }}
                >
                    {cardsData.map((card, index) => {
                        const secondCard = index === 1;
                        const disableFirstCard = (users > 1 || isPersonalDisabled) && index === 0;

                        const featureAboveFour = card.features.length > 4;

                        return (
                            <GridItem
                                display="flex"
                                flexDir="column"
                                justifyContent="space-between"
                                gap="6"
                                borderRadius="md"
                                p="8"
                                cursor={disableFirstCard ? "not-allowed" : "pointer"}
                                // h={featureAboveFour ? "auto" : "448px"}
                                key={index}
                                bg={index === 1 ? "teal.600" : "white"}
                                color={
                                    index === 1
                                        ? "white"
                                        : disableFirstCard
                                            ? "gray.400"
                                            : "gray.700"
                                }
                                colSpan={{
                                    md: index === 2 ? 2 : 1,
                                    lg: 1,
                                }}
                                pos="relative"
                            >
                                {index > 0 && (
                                    <Tag
                                        size="xl"
                                        px="22px"
                                        borderRadius="full"
                                        py="14px"
                                        pos="absolute"
                                        top="-6"
                                        whiteSpace="nowrap"
                                        left="50%"
                                        bg="linear-gradient(200deg,#a7fdfc,hsla(0,0%,100%,.90))"
                                        boxShadow="0 8px 30px hsla(0,0%,100%,.1)"
                                        transform="translateX(-50%)"
                                    >
                                        {t('pricing.independentCompany')} / {t('pricing.agency')}
                                    </Tag>
                                )}

                                <Stack spacing="2">
                                    <Text fontSize="lg">{card.title}</Text>
                                    <Heading fontSize="3xl">
                                        {card.price}{" "}
                                        {index < 2 && (
                                            <>
                                                {/*<Box as="span" fontSize="2xl" px="2">*/}
                                                {/*  user*/}
                                                {/*</Box>*/}
                                                <Box as="span">/</Box>

                                                <Box as="span" fontSize="2xl" pl="2">
                                                    {isYearly ? t('pricing.year') : t('pricing.month')}
                                                </Box>
                                            </>
                                        )}
                                    </Heading>
                                    <Text fontSize="sm">{card.text}</Text>
                                </Stack>

                                <Stack spacing={4}>
                                    {card.features.slice(0, 4).map((feature, index) => {
                                        return (
                                            <HStack spacing={2} key={index}>
                                                <Icon as={AiFillCheckCircle}/>
                                                <Text fontSize="sm">{feature.text}</Text>
                                            </HStack>
                                        );
                                    })}
                                </Stack>
                                {featureAboveFour && (
                                    <Accordion allowMultiple>
                                        <AccordionItem border="none">
                                            <AccordionButton>
                                                <Box
                                                    fontWeight="bold"
                                                    as="span"
                                                    flex="1"
                                                    textAlign="left"
                                                >
                                                    {t('pricing.additionalFeatures')}
                                                </Box>
                                                <AccordionIcon/>
                                            </AccordionButton>
                                            <AccordionPanel pb={4}>
                                                <Stack spacing="4">
                                                    {card.features.slice(4).map((feature, index) => {
                                                        return (
                                                            <>{index === 5
                                                                ? <Text fontWeight={'bold'} fontSize="md">Agency</Text>

                                                                : null}
                                                                <HStack spacing={2} key={index}>
                                                                    <Icon as={AiFillCheckCircle}/>
                                                                    <Text fontSize="sm">{feature.text}</Text>
                                                                </HStack>
                                                            </>
                                                        );
                                                    })}
                                                    {card.agency !== undefined
                                                        ? <>
                                                            <Text fontWeight={'bold'}
                                                                  fontSize="md">{t('pricing.agency')}</Text>
                                                            {card.agency.map((feature, index) => {
                                                                return (
                                                                    <HStack spacing={2} key={index}>
                                                                        <Icon as={AiFillCheckCircle}/>
                                                                        <Text fontSize="sm">{feature.text}</Text>
                                                                    </HStack>
                                                                );
                                                            })}
                                                        </>
                                                        : null
                                                    }
                                                </Stack>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                )}
                                <Spacer/>
                                <Button
                                    _hover={{
                                        color: secondCard ? "black" : "white",
                                        bg: secondCard ? "white" : "black",
                                    }}
                                    bg={secondCard ? "transparent" : "teal"}
                                    onClick={card.button.action}
                                    color={secondCard ? "white" : "white"}
                                    border="1px solid"
                                    borderColor="white"
                                    isDisabled={disableFirstCard || card.button.action === null}
                                >
                                    {card.button.text}
                                </Button>
                            </GridItem>
                        );
                    })}
                </Grid>
            </Box>
        </>
    );
};

export default Pricing;
