import {baseApi} from "./apiService";
import {PredefinedTask} from "../types";

export const predefinedTaskApi = baseApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getPredefinedTasksList: builder.query<PredefinedTask[],number|void>({
            query: (page) => ({
                url: 'predefined_tasks',
                params: {page: page},
            }),
            providesTags: (result: PredefinedTask[]|undefined) =>
                result
                    ? [...result.map(({id}) => ({type: 'PredefinedTasks' as const, id})), 'PredefinedTasks']
                    : ['PredefinedTasks'],
        }),
        createPredefinedTask: builder.mutation({
            query: (body) => ({
                url: `predefined_tasks`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['PredefinedTasks'],
        }),
        deletePredefinedTask: builder.mutation({
            query: (id) => ({
                url: `predefined_tasks/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['PredefinedTasks'],
        }),
    })
});

export const {useCreatePredefinedTaskMutation, useGetPredefinedTasksListQuery, useDeletePredefinedTaskMutation} = predefinedTaskApi
