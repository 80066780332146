import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React from "react";
import {useLazyGetDownloadFileQuery} from "../../services/fileService";
import {isFilled} from "../../utils/generalHelper";
import {Button, Flex, Td,} from "@chakra-ui/react";
import {
    createColumnHelper,
    getCoreRowModel,
    OnChangeFn,
    SortingState,
    useReactTable,
    VisibilityState
} from "@tanstack/react-table";
import {BsFillCloudDownloadFill} from "react-icons/bs";
import {CellTypes, LinkedFileUndirected} from "../../types";
import {closeFileViewModal, showFileViewModal} from "../../slices/fileViewModalSlice";
import {AiFillEye} from "react-icons/ai";
import TableWrapper from "../../pages/Table/TableWrapper";
import TableCell from "../../pages/Table/TableCell";
import {setSorting} from "../../slices/fileSortModalSlice";
import {selectLinkedFileRawSorting} from "../../slices/linkedFileSortModalSlice";
import {IoRemoveCircleOutline} from "react-icons/io5";
import {useDeleteLinkedFilesMutation} from "../../services/linkedFilesService";

export default function LinkedFilesTable(
    {
        linkedFiles = [],
        hiddenFields = [],
        inModal = false,
        enableUnlink = false,
        customAction = undefined,
        customActionArgs = []
    }: {
        linkedFiles: LinkedFileUndirected[],
        hiddenFields?: string[],
        inModal?: boolean,
        enableUnlink?: boolean,
        customAction?: (info: any, ...args: any[]) => void,
        // customActionArgs?: any[]
        customActionArgs?: any
    }) {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [trigger, {isLoading: isLoadingTrigger}] = useLazyGetDownloadFileQuery()
    const sorting = useSelector(selectLinkedFileRawSorting)
    const [deleteLinkedFile, {isLoading: isLoadingDeleteLinkedFile}] = useDeleteLinkedFilesMutation()

    const columnHelper = createColumnHelper<LinkedFileUndirected>()
    const columns = [
            //todo implement custom filter function to handle both of them!!
            columnHelper.accessor(row => `${row.linked.internal_id} ${row.linked.external_id}`, {
                id: 'ids',
                header: t('common.ID'),
                cell: info => <TableCell
                    content={info.row.original.linked.internal_id}
                    subContent={info.row.original.linked.external_id}
                    placeholder={info.row.original.linked.id}
                    isContentFilled={isFilled(info.row.original.linked.internal_id) || isFilled(info.row.original.linked.external_id)}
                    type={CellTypes.CombinedIds}
                    maxLength={11}
                />,
                enableSorting: true,
            }),
            columnHelper.accessor(row => row.linked.name ?? row.linked.original_name, {
                id: 'name',
                header: t('files.fileName'),
                cell: info => <TableCell
                    content={info.getValue() as string}
                    shorten={true}
                    isContentFilled={isFilled(info.row.original.linked.name)}
                />,
                enableSorting: true,
            }),
            columnHelper.accessor('note', {
                id: 'note',
                header: t('common.note', {count: 1}),
                cell: info => <TableCell
                    content={info.getValue()}
                    isContentFilled={isFilled(info.getValue())}
                />,
                enableSorting: false,
            }),
            columnHelper.display({
                id: 'actions',
                meta: {
                    customTd: true,
                },
                cell: info => customAction
                    ? customAction(info, ...customActionArgs)
                    : <Td px={0} bg={info.row.index % 2 === 0 ? "blackAlpha.100 !important" : "whiteAlpha.100 !important"}>
                        <Flex justifyContent={"space-around"}>
                            <Button
                                height={'25px'}
                                pe={0}
                                me={4}
                                colorScheme={'white'}
                                isLoading={isLoadingTrigger}
                                // loadingText='Downloading'
                                onClick={() => trigger({id: info.row.original.linked.id, name: info.row.original.linked.document_name})}
                            >
                                <BsFillCloudDownloadFill
                                    fontSize={25}
                                    color="var(--chakra-colors-teal-400)"
                                />
                            </Button>
                            <Button height={'25px'} ps={0} colorScheme={'white'}
                                    onClick={() => {
                                        if (inModal) {
                                            dispatch(closeFileViewModal())
                                            navigate(`/files/${info.row.original.linked.id}`)
                                        } else {
                                            dispatch(showFileViewModal({file: info.row.original.linked}))
                                        }
                                    }}>
                                <AiFillEye
                                    fontSize={25}
                                    color="var(--chakra-colors-teal-400)"
                                />
                            </Button>
                            {enableUnlink
                                ? <Button height={'25px'} ps={0} colorScheme={'white'}
                                          onClick={() => {
                                              deleteLinkedFile(info.row.original.id)
                                          }}>
                                    <IoRemoveCircleOutline
                                        fontSize={25}
                                        color="var(--chakra-colors-red-400)"
                                    />
                                </Button>
                                : null
                            }
                        </Flex>
                    </Td>,
                enableSorting: false,
            }),
        ]
    ;

    const defaultVisibility = {created_at: !inModal, tasks: !inModal};

    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(() => {
        return {...defaultVisibility, ...hiddenFields.reduce((acc, field) => ({...acc, [field]: false}), {})};
    });

    const handleSortingChange: OnChangeFn<SortingState> = (newSorting) => {
        dispatch(setSorting(newSorting));
    };

    const table = useReactTable({
        data: linkedFiles,
        columns,
        state: {
            columnVisibility,
            sorting,
        },
        onColumnVisibilityChange: setColumnVisibility,
        onSortingChange: handleSortingChange,
        manualSorting: true,
        enableSorting: !inModal,
        getCoreRowModel: getCoreRowModel(),
    })

    return (
        <TableWrapper table={table} noDataMessage={'linkedFiles.noLinked'}/>
    );
};
