import {
    Button,
    Center,
    Flex, FormControl, FormErrorMessage,
    Heading,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightAddon,
    Spacer,
    Stack,
} from "@chakra-ui/react";
import React from "react";
import LoginImg from "../assets/Login";
import {HiUser} from "react-icons/hi";
import {GoKey} from "react-icons/go";
import {ui_colors} from "../theme/colors";
import {Navigate} from "react-router-dom";
import {AiFillEye, AiFillEyeInvisible} from "react-icons/ai";
import {isLoggedIn} from "../slices/authSlice";
import {useAuth} from "../hooks/useAuth";
import {useLoginMutation} from "../services/userService";
import {Field, Form, Formik} from "formik";
import {object, string} from "yup";
import { useTranslation } from "react-i18next";
import {useDispatch} from "react-redux";
import {invalidLoginToast} from "../slices/toastSlice";

export default function Login() {
    const {t} = useTranslation()
    const auth = useAuth()
    const dispatch = useDispatch()

    const [login, {isLoading}] = useLoginMutation()
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);

    const schema = object({
        username: string().email().min(2).max(50).required(),
        password: string().min(2).max(50).required()
    });

    if (isLoggedIn(auth)) {
        return <Navigate to="/"/>
    }

    return (
        <Center>
            <Formik
                validationSchema={schema}
                validateOnMount={true}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    setSubmitting(true);
                    login(values).unwrap()
                        .then()
                        .catch(() => dispatch(invalidLoginToast()) )
                    setSubmitting(false);
                }}
                // enableReinitialize={true}
                initialValues={{
                    username: '',
                    password: '',
                }}>
                {({
                      handleSubmit,
                      handleChange,
                      handleReset,
                      handleBlur,
                      values,
                      dirty,
                      touched,
                      isValid,
                      isSubmitting,
                      errors,
                      setFieldValue,
                      setFieldTouched,
                  }) => (<Form>
                    <Flex bg={"#fff"} minH={500} rounded={"2xl"} overflow={"hidden"}>
                        <Center bg={"#E6FFFA"} p={12}>
                            <LoginImg width="400"/>
                        </Center>
                        <Flex
                            px={12}
                            py={16}
                            flexDirection="column"
                            justifyContent={"center"}
                            gap={1}
                            minW="md"
                        >
                            <Heading size={"md"}>{t('nav.welcome')}</Heading>
                            <Heading size={"sm"} fontWeight={"normal"} color={"gray.500"}>
                            {t('login.subTitle')}
                            </Heading>
                            <Stack mt={6} sx={{svg: {color: ui_colors.primary}}} gap={2}>
                                <FormControl isInvalid={!!errors.username && touched.username}>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents="none" children={<HiUser/>}/>
                                        <Input
                                            as={Field}
                                            name={'username'}
                                            onBlur={handleBlur}
                                            type="text"
                                            placeholder={t('login.username')}
                                        />
                                    </InputGroup>
                                    <FormErrorMessage>{errors.username}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={!!errors.password && touched.password}>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents="none" children={<GoKey/>}/>
                                        <Input
                                            as={Field}
                                            name={'password'}
                                            onBlur={handleBlur}
                                            type={show ? 'text' : 'password'}
                                            placeholder={t('login.password')}/>
                                        <InputRightAddon
                                            cursor={"pointer"}
                                            sx={{}}
                                            onClick={handleClick}
                                            children={show ? <AiFillEyeInvisible/> : <AiFillEye/> }
                                        />
                                    </InputGroup>
                                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                                </FormControl>
                            </Stack>
                            {/*<Flex alignItems={"center"} mt={2}>*/}
                            {/*    <Checkbox colorScheme={"teal"} defaultChecked>*/}
                            {/*        Remember Me*/}
                            {/*    </Checkbox>*/}
                            {/*    <Spacer/>*/}
                            {/*    <Heading color={"teal"} size={"sm"} fontWeight={"normal"}>*/}
                            {/*        Forgot Password*/}
                            {/*    </Heading>*/}
                            {/*</Flex>*/}
                            <Spacer/>
                            <Button type={'submit'} isLoading={isLoading}  loadingText={t('common.actions.login')} variant={"primary"} colorScheme={"teal"} isDisabled={isLoading}>
                              {t('common.actions.login')}
                            </Button>
                            <Heading
                                size={"sm"}
                                fontWeight={"normal"}
                                textAlign={"center"}
                                mt={1}
                                sx={{a: {color: "teal", fontWeight: "bold"}}}
                            >
                                {/*Do you need an account? <Link>Sign Up</Link>*/}
                                {/*Already have an account? <Link>Login</Link>*/}
                            </Heading>
                        </Flex>
                    </Flex>
                </Form>)}
            </Formik>
        </Center>
    );
}
