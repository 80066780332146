import {Box, Container} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import FilesHeader from "../components/Files/FilesHeader";
import FilesFilterModal from "../components/Files/FilesFilter.Modal";
import {
    useGetFilesListQuery,
    useLazyGetFileQuery
} from "../services/fileService";
import {useNavigate, useParams} from "react-router-dom";
import {isFileViewOpen, showFileViewModal} from "../slices/fileViewModalSlice";
import {useDispatch, useSelector} from "react-redux";
import {isFilled} from "../utils/generalHelper";
import {
    Pagination,
    PaginationContainer, PaginationNext, PaginationPage,
    PaginationPageGroup,
    PaginationPrevious, PaginationSeparator,
    usePagination
} from "@ajna/pagination";
import {IoChevronBackCircleOutline, IoChevronForwardCircleOutline} from "react-icons/io5";
import {selectFileFilterModal} from "../slices/fileFilterModalSlice";
import FilesTable from "../components/Files/FilesTable";
import {
    clearFileSort,
    closeFileSortModal,
    isFileSortOpen,
    selectFileSortModal,
    setModalSorting
} from "../slices/fileSortModalSlice";
import SortModal from "../components/SortModal";
import TableLoader from "./Table/TableLoader";
import {useGetCurrentUserQuery} from "../services/userService";
import {hasAllowedRole} from "../utils/userRoleHelper";
import {Role} from "../types";

export default function FilesList() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    let {fileId} = useParams();
    const isModalOpen = useSelector(isFileViewOpen)
    const [open, setOpen] = useState(false);
    const [dataTotal, setDataTotal] = useState(0);
    const fileFilterModal = useSelector(selectFileFilterModal)
    const isSortModalOpen = useSelector(isFileSortOpen)
    const fileSortModal = useSelector(selectFileSortModal)
    const [search, setSearch] = useState(null);
    const {data: user} = useGetCurrentUserQuery()

    const {
        pages,
        pagesCount,
        currentPage,
        setCurrentPage,
        isDisabled,
    } = usePagination({
        total: dataTotal,
        limits: {
            outer: 3,
            inner: 3
        },
        initialState: {
            pageSize: 20,
            isDisabled: false,
            currentPage: 1
        }
    });

    const {data = {data: [], total: 0, per_page: 20}, isLoading, isFetching} = useGetFilesListQuery({
        page: currentPage,
        fileTypeId: fileFilterModal.fileType,
        year: parseInt(fileFilterModal.year),
        sortField: fileSortModal.sortField,
        sortDirection: fileSortModal.sortDirection,
        search: search,
    })
    const [triggerFileLoad, {data: file, isLoading: isLoadingFile}] = useLazyGetFileQuery()

    const sortableFields = [
        {value: 'ids', label: 'common.ID'},
        {value: 'name', label: 'files.fileName'},
        {value: 'externalId', label: 'files.view.externalID'},
        {value: 'internalId', label: 'files.view.internalID'},
        {value: 'createdAt', label: 'files.uploadedAt', isDefault: true},
        {value: 'fileCreatedAt', label: 'common.createdAt'},
    ];

    const handlePageChange = (nextPage) => {
        setCurrentPage(nextPage);
    };

    function handleClose() {
        setOpen(false);
    }

    useEffect(() => {
        setCurrentPage(1)
    }, [setCurrentPage, fileFilterModal.fileType, search])

    useEffect(() => {
        setDataTotal(data.total)
    }, [data.total, setDataTotal])

    useEffect(() => {
        if (isFilled(fileId) && !isModalOpen) {
            if (!isLoadingFile) {
                triggerFileLoad(fileId)
            }
            if (isFilled(file)) {
                dispatch(showFileViewModal({file: file}))
                navigate("/files", {replace: true})
            }
        }
    }, [isLoadingFile, fileId, isModalOpen, file]);

    return (
        <Box flexDirection={"column"}>
            <FilesFilterModal isOpen={open} onClose={handleClose}/>
            <SortModal
                sortableFields={sortableFields}
                isModalOpen={isSortModalOpen}
                sortValues={fileSortModal}
                closeAction={closeFileSortModal}
                clearAction={clearFileSort}
                submitAction={setModalSorting}
            />
            {/* warn */}
            {/*<ErrorAlert />*/}
            <Container maxW={"container.xl"} pt={"10"} px={3}>
                {/* header */}
                <FilesHeader count={data.total} search={search} setSearch={setSearch}/>

                {isLoading || isFetching
                    ? <TableLoader
                        isLoading={true}
                        columns={hasAllowedRole(user, [Role.User])
                            ? [
                                'common.ID',
                                'files.fileName',
                                'common.createdBy',
                                'common.createdAt',
                                'files.uploadedAt',
                                'tasks.task',
                                null]
                            : [
                                'common.ID',
                                'files.fileName',
                                'common.createdBy',
                                'common.createdAt',
                                'files.uploadedAt',
                                null]
                        }
                    />
                    : <FilesTable files={data.data} hiddenFields={hasAllowedRole(user, [Role.User]) ? [] : ['tasks']}/>
                }

                {/*todo extract*/}
                <Pagination
                    pagesCount={pagesCount}
                    currentPage={currentPage}
                    isDisabled={isDisabled}
                    onPageChange={handlePageChange}
                >
                    <PaginationContainer
                        align="center"
                        justify="space-between"
                        p={4}
                        w="full"
                    >
                        <PaginationPrevious
                            _hover={{
                                bg: "teal.500"
                            }}
                            bg="teal.400"
                        >
                            <IoChevronBackCircleOutline/>
                        </PaginationPrevious>
                        <PaginationPageGroup
                            isInline
                            align="center"
                            separator={
                                <PaginationSeparator
                                    bg="teal.300"
                                    fontSize="sm"
                                    w={7}
                                    jumpSize={11}
                                />
                            }
                        >
                            {pages.map((page) => (
                                <PaginationPage
                                    w={7}
                                    bg="teal.400"
                                    key={`pagination_page_${page}`}
                                    page={page}
                                    fontSize="sm"
                                    _hover={{
                                        bg: "teal.500"
                                    }}
                                    _current={{
                                        bg: "teal.500",
                                        fontSize: "sm",
                                        color: "white",
                                        w: 7
                                    }}
                                />
                            ))}
                        </PaginationPageGroup>
                        <PaginationNext
                            _hover={{
                                bg: "teal.500"
                            }}
                            bg="teal.400"
                        >
                            <IoChevronForwardCircleOutline/>
                        </PaginationNext>
                    </PaginationContainer>
                </Pagination>
            </Container>
        </Box>
    );
}
