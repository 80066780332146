import {
    Input,
    InputGroup, InputLeftElement,
    InputRightAddon,
    Stack,
    Text,
} from "@chakra-ui/react";
import React, {ReactElement} from "react";
import {Field, FormikHandlers} from "formik";
import {InputType} from "../types";

export default function AppInput(
    {
        name,
        label,
        placeholder,
        handleBlur,
        icon,
        leftElement,
        type = InputType.text,
        isDisabled = false,
    }: {
        name: string,
        label?: string,
        placeholder?: string,
        handleBlur:FormikHandlers['handleBlur'],
        icon?:ReactElement,
        leftElement?:ReactElement,
        type? : InputType,
        isDisabled? : boolean,
    }) {

    return (
        <Stack>
            {label
                ? <Text fontSize={14} color={isDisabled ? 'gray.400' : 'current'} fontWeight={600}>
                    {label}
                </Text>
                : undefined
            }
            <InputGroup style={{background: 'white'}}>
                {leftElement && type !== InputType.date && (
                    <InputLeftElement
                        color="teal.400"
                        fontSize='1.2em'
                        children={leftElement}
                    />
                )}
                <Input
                    as={Field}
                    // ref={input_ref}
                    placeholder={placeholder}
                    onBlur={handleBlur}
                    //{...(handleChange && { onChange: handleChange })}
                    name={name}
                    type={type}
                    isDisabled={isDisabled}
                    variant={'outline'}
                    // onChange={onChange}
                    _placeholder={{color: "gray.400"}}
                    _focusVisible={{
                        borderColor: "teal.400",
                        boxShadow: "0 0 0 1px var(--chakra-colors-teal-400)",
                    }}
                />
                {icon && type !== InputType.date && (
                    <InputRightAddon
                        cursor={"pointer"}
                        color="teal.400"
                        children={icon}
                    />
                )}
            </InputGroup>
        </Stack>
    );
}

