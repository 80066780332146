import {baseApi} from "./apiService";

const defaultTaskFilter = {
    page: 1,
    status: undefined,
    assignee: undefined,
    sortField: undefined,
    sortDirection: undefined,
}

export const taskApi = baseApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getTask: builder.query({
            query: (id) => (`tasks/${id}`),
            providesTags: (result, error, arg) =>
                result
                    ? [{type: 'Tasks', id: result.id}, 'Tasks']
                    : ['Tasks'],
        }),
        getTasksList: builder.query({
            query: (params) => ({
                url: 'tasks',
                params: {
                    page: params?.page ?? defaultTaskFilter.page,
                    status: params?.status ?? defaultTaskFilter.status,
                    assignee: params?.assignee ?? defaultTaskFilter.assignee,
                    sortField: params?.sortField ?? defaultTaskFilter.sortField,
                    sortDirection: params?.sortDirection ?? defaultTaskFilter.sortDirection,
                },
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [...result.data.map(({id}) => ({type: 'Tasks', id})), 'Tasks']
                    : ['Tasks'],
        }),
        createTask: builder.mutation({
            query: (body) => ({
                url: `tasks`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Tasks', 'Files'],
        }),
        createBulkTask: builder.mutation({
            query: (body) => ({
                url: `tasks/bulk`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Tasks', 'Files', 'UploadHistoryFile'],
        }),
        updateTask: builder.mutation({
            query: ({id, ...body}) => ({
                url: `tasks/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Tasks', 'Files'],

        }),
        updateTaskStatus: builder.mutation({
            query: ({id, ...body}) => ({
                url: `tasks/${id}/status`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Tasks', 'Files'],
        }),
    })
});

export const {useCreateTaskMutation, useGetTaskQuery, useLazyGetTaskQuery, useUpdateTaskMutation,
    useUpdateTaskStatusMutation, useGetTasksListQuery, useCreateBulkTaskMutation} = taskApi
