import {
    Box,
    Button,
    HStack,
    Spacer,
} from "@chakra-ui/react";
import React from "react";
import {array, object, string} from "yup";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import FileUploadLinkFiles from "../Files/FileUploadLinkFiles";
import {useCreateBulkLinkedFilesMutation} from "../../services/linkedFilesService";
import {transformFormValues, transformLinkedFilesToUndirected} from "../../utils/generalHelper";
import {PaginatedLinkedFiles} from "../../types";

export default function CreateLinkedFiles({owningFileId, isLoadingLinkedFiles = false, linkedFiles, onFormSubmitSuccess}: {
    owningFileId: string,
    isLoadingLinkedFiles?: boolean,
    linkedFiles: PaginatedLinkedFiles,
    onFormSubmitSuccess?: () => void,
}) {
    const {t} = useTranslation()
    const [addLinkedFiles, {isLoading: isLoadingAdd}] = useCreateBulkLinkedFilesMutation()

    const schema = object({
        linked_files: array().of(
            object({
                linked_to: string().uuid(),
                note: string(),
            })
        )
    });

    if (isLoadingLinkedFiles) {
        return (
            <Box>
            </Box>
        )
    }

    return (
        <Formik
            validationSchema={schema}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={(values, {setSubmitting, resetForm}) => {
                setSubmitting(true);
                addLinkedFiles(
                    {
                        ...values.linked_files.map((linkedFile) => {
                            return {...transformFormValues(linkedFile), linked_from: owningFileId}
                        })
                    }
                )
                setSubmitting(false);
                if (onFormSubmitSuccess) {
                    onFormSubmitSuccess();
                }
                resetForm()
            }}
            initialValues={{
                linked_files: [],
            }}>
            {({
                  handleSubmit,
                  handleChange,
                  handleReset,
                  handleBlur,
                  values,
                  dirty,
                  touched,
                  isValid,
                  isSubmitting,
                  errors,
                  setFieldValue,
                  setFieldTouched,
                  setValues,
              }) => (<Form>
                {/*// todo hacked by !*/}
                <FileUploadLinkFiles
                    linkedFiles={values.linked_files}
                    existingConnections={[...transformLinkedFilesToUndirected(linkedFiles, owningFileId!).map(linkedFile => linkedFile.linked.id), owningFileId!]}
                    handleBlur={handleBlur}
                    errors={errors}
                    touched={touched}
                />
                <Box pb={8} bg={"#fff"} mt={8} borderRadius={"lg"}>
                    <HStack>
                        <Spacer/>
                        <Button isLoading={isLoadingLinkedFiles || isLoadingAdd}
                                loadingText={t('upload.actions.uploading')}
                                type={'submit'} variant={"primary"}>{t('common.actions.save')}</Button>
                    </HStack>
                </Box>
            </Form>)}
        </Formik>
    );
}
