import React, {useEffect, useState} from 'react'
import {Stack, Text, Button, VStack} from '@chakra-ui/react'
import {IoLockClosed} from 'react-icons/io5'
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

export default function CookiePreference() {
    const {t} = useTranslation();
    const [showNotification, setShowNotification] = useState(true);

    useEffect(() => {
        const hasConsented = localStorage.getItem('cookieConsent');
        if (hasConsented) {
            setShowNotification(false);
        }
    }, []);

    const handleConsent = () => {
        localStorage.setItem('cookieConsent', 'true');
        setShowNotification(false);
    };

    if (!showNotification) return null;

    return (
        <Stack p="4"
               boxShadow="lg"
               m="4"
               borderRadius="xl"
               rounded={'xl'}
               dropShadow={'xl'}
               spacing={4}
               position="fixed"
               bottom="0"
               left="0"
               zIndex="1000"
               bgColor="white">
            <Stack direction={{base: 'column', md: 'row'}} justifyContent="flex-start" alignItems="center"
                   spacing={4}>
                <VStack alignItems="flex-start">
                    <Stack direction="row" textAlign={'left'} alignItems="center" spacing={2}>
                        <Text fontWeight="semibold">{t('cookieNotice.title')}</Text>
                        <IoLockClosed/>
                    </Stack>
                    <Text fontSize={{base: 'sm', md: 'md'}} textAlign={'left'} maxW={'4xl'}>
                        {t('cookieNotice.description')}
                        <NavLink to="/cookies" color={'teal.400'}> {t('cookieNotice.cookiePolicy')}</NavLink>
                    </Text>
                </VStack>
                <Stack direction={{base: 'column', md: 'row'}} spacing={2}>
                    <Button colorScheme="teal" onClick={handleConsent}>{t('cookieNotice.accept')}</Button>
                </Stack>
            </Stack>
        </Stack>
    )
}
