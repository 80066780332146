import {Navigate, useLocation} from 'react-router-dom'
import {useAuth} from '../hooks/useAuth'
import {isLoggedIn} from "../slices/authSlice";
import {useGetCurrentUserQuery} from "../services/userService";
import {hasAllowedRole} from "./userRoleHelper";
import AccessDenied from "../pages/AccessDenied";
import PropTypes from "prop-types";

export function RoleAccess({children, allowedRoles = [], isStrictRole = false, showMessage = false}) {
    const auth = useAuth()
    const location = useLocation()
    const {data} = useGetCurrentUserQuery(undefined, {skip: !isLoggedIn(auth)})

    return isLoggedIn(auth) ? (
        hasAllowedRole(data, allowedRoles, isStrictRole)
            ? children
            : showMessage ? <AccessDenied/> : null //todo create msg
    ) : (
        <Navigate to="/login" state={{from: location}}/>
    )
}

RoleAccess.propTypes = {
    children: PropTypes.element,
    allowedRoles: PropTypes.array,
    isStrictRole: PropTypes.bool,
    showMessage: PropTypes.bool,
};
