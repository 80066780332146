import React from "react";
import {
    Button,
    Center, FormControl, FormErrorMessage,
    Grid,
    GridItem, List, ListIcon, ListItem,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spacer,
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Form, Formik} from "formik";
import {object, string} from "yup";
import {IoArrowDown, IoArrowUp} from "react-icons/io5";
import AppSelect from "./AppSelect";
import {SortValues} from "../types";
import {Action, ActionCreatorWithoutPayload} from "@reduxjs/toolkit";

type SortableField = {
    value: string;
    label: string;
    isDefault?: boolean;  // This is optional since not all fields have the "isDefault" property.
}

interface SortModalProps {
    sortableFields: SortableField[];
    isModalOpen: boolean;
    sortValues: SortValues;
    closeAction: () => ActionCreatorWithoutPayload;
    clearAction: () => ActionCreatorWithoutPayload;
    submitAction: (values: SortValues) => Action<string>;
}

export default function SortModal({sortableFields, isModalOpen, sortValues, closeAction, clearAction, submitAction}: SortModalProps) {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const schema = object({
        sortField: string(),
        sortDirection: string(),
    });

    return (
        <Modal isOpen={isModalOpen} onClose={() => dispatch(closeAction())}>
            <ModalOverlay/>
            <ModalContent borderRadius={"2xl"} minW={700}>
                <Formik
                    validationSchema={schema}
                    validateOnMount={true}
                    enableReinitialize={true}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        dispatch(submitAction(values))
                        dispatch(closeAction())
                        setSubmitting(false);
                    }}
                    initialValues={{
                        sortField: sortValues.sortField ?? '',
                        sortDirection: sortValues.sortDirection
                    }}>
                    {({
                          handleSubmit,
                          handleChange,
                          handleReset,
                          handleBlur,
                          values,
                          dirty,
                          touched,
                          isValid,
                          isSubmitting,
                          errors
                      }) => (<Form>
                        <ModalHeader fontWeight={700} fontSize={22}>
                            {t('sort.sort')}
                        </ModalHeader>
                        <ModalBody mb={6}>
                            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                <GridItem>
                                    <FormControl isInvalid={!!errors.sortField && touched.sortField}>
                                        <AppSelect
                                            name='sortField'
                                            onChange={handleChange}
                                            value={values.sortField}
                                            handleBlur={handleBlur}
                                            label={'sort.sortBy'}
                                        >
                                            {sortableFields.map((sortableField, i) =>
                                                <option key={i} value={sortableField.value}>
                                                    {t(sortableField.label)}{sortableField?.isDefault ? ` - ${t('sort.default')}` : undefined}
                                                </option>
                                            )}
                                        </AppSelect>
                                        <FormErrorMessage>{errors.sortField}</FormErrorMessage>
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={!!errors.sortDirection && touched.sortDirection}>
                                        <AppSelect
                                            name='sortDirection'
                                            onChange={handleChange}
                                            value={values.sortDirection}
                                            handleBlur={handleBlur}
                                            label={'sort.direction'}
                                        >
                                            <option value="asc"><IoArrowUp/> {t('sort.asc')}</option>
                                            <option value="desc"><IoArrowDown/> {t('sort.desc')}</option>
                                        </AppSelect>
                                        <FormErrorMessage>{errors.sortDirection}</FormErrorMessage>
                                    </FormControl>
                                </GridItem>
                                <GridItem/>
                                <GridItem>
                                    <List spacing={3}>
                                        <ListItem>
                                            <ListIcon as={IoArrowUp} color='teal.500'/>
                                            <b>{t('sort.asc')} - </b> {t('sort.ascNote')}
                                        </ListItem>
                                        <ListItem>
                                            <ListIcon as={IoArrowDown} color='teal.500'/>
                                            <b>{t('sort.desc')} - </b> {t('sort.descNote')}
                                        </ListItem>
                                    </List>
                                </GridItem>
                            </Grid>

                        </ModalBody>

                        <Center gap={4} p={8}>
                            <Button
                                variant={"outline"}
                                color={"teal.400"}
                                borderColor={"teal.400"}
                                onClick={() => {
                                    dispatch(clearAction())
                                    dispatch(closeAction())
                                }}
                            >
                                {t('sort.clearSorting')}
                            </Button>
                            <Spacer/>
                            <Button type={'button'} variant={"gray"} onClick={() => dispatch(closeAction())}>
                                {t('common.actions.cancel')}
                            </Button>
                            <Button type={'submit'} variant={"primary"}>{t('common.actions.apply')}
                            </Button>
                        </Center>
                    </Form>)}
                </Formik>
            </ModalContent>
        </Modal>
    );
}
