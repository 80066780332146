import {
    Select,
    Stack,
    Text,
} from "@chakra-ui/react";
import React from "react";
import {RiArrowDownSLine} from "react-icons/ri";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

export default function AppSelect({name, children, icon, label, onChange, handleBlur, value, placeholder = null}) {
    const {t} = useTranslation()

    return (
        <Stack>
            <Text fontSize={14} fontWeight={600}>
                {t(label)}
            </Text>

            <Select
                name={name}
                onChange={(e) => {
                    onChange(e)
                    handleBlur(e)
                }}
                value={value}
                onBlur={handleBlur}
                colorScheme="teal"
                iconColor="teal.400"
                _placeholder={{color: "gray.400"}}
                _focusVisible={{
                    borderColor: "teal.400",
                    boxShadow: "0 0 0 1px var(--chakra-colors-teal-400)",
                }}
                icon={icon ?? <RiArrowDownSLine/>}
                borderRadius={"0.375rem 0 0 0.375rem"}
                placeholder={placeholder}
            >
                {children}
            </Select>
        </Stack>
    );
}

AppSelect.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.object]),
    icon: PropTypes.element,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
};
