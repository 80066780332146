import {
    InputGroup,
    Stack,
    Text,
    Textarea,
    Grid,
    GridItem,
    FormErrorMessage, FormControl,
} from "@chakra-ui/react";
import React from "react";

import {BsFillCalendarFill} from "react-icons/bs";
import {useTranslation} from "react-i18next";
import AppInput from "../../components/AppInput";
import AppSelect from "../../components/AppSelect";
import {useGetFileTypesListQuery} from "../../services/fileTypeService";
import moment from "moment/moment";
import PropTypes from "prop-types";

export default function FileFields({
                                       errors,
                                       handleBlur,
                                       handleChange,
                                       touched,
                                       values,
                                       setValues,
                                       predefinedTasks = [],
                                       withPredefinedTasks = false
                                   }) {
    const {t} = useTranslation()
    const {data: fileTypes} = useGetFileTypesListQuery()

    return (
        <Grid templateColumns="repeat(3, 1fr)" columnGap={8} rowGap={4}>
            <GridItem>
                <FormControl isInvalid={!!errors.name && touched.name}>
                    <AppInput
                        name='name'
                        handleBlur={handleBlur}
                        label={t('files.fileName')}
                        placeholder={t('common.name')}
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
            </GridItem>
            <GridItem>
                <FormControl isInvalid={!!errors.file_type && touched.file_type}>
                    <AppSelect
                        name='file_type'
                        onChange={(e) => {
                            // handleChange(e)
                            // handleBlur(e)
                            // I know, I can simplify it into a few lines without variables
                            // But then only god would know what's happening.
                            if (withPredefinedTasks) {
                                let pastPredefinedTaskIds = predefinedTasks
                                    .filter(
                                        predefinedTask =>
                                            (predefinedTask?.event_id === values.file_type && predefinedTask?.event === 'fileType')
                                    )
                                    .map(predefinedTask => predefinedTask.id)
                                let newPredefinedTasks = predefinedTasks.filter(
                                    predefinedTask => (predefinedTask.event_id === e.target.value && predefinedTask?.event === 'fileType')
                                )

                                setValues({
                                    ...values,
                                    file_type: e.target.value,
                                    tasks: values.tasks
                                        .filter(task => !pastPredefinedTaskIds.includes(task.predefined_task))
                                        .concat(newPredefinedTasks.map(predefinedTask => {
                                            return {
                                                name: predefinedTask?.task_name ?? '',
                                                predefined_task: predefinedTask?.id ?? '',
                                                note: predefinedTask?.task_note ?? '',
                                                assignee_id: predefinedTask?.task_assignee?.id ?? '',
                                                file_id: '',
                                                escalation_date: predefinedTask?.task_days_to_escalate ? moment().add(predefinedTask?.task_days_to_escalate, 'days').format('YYYY-MM-DD') : '',
                                            }
                                        }))
                                })
                            }
                            handleChange(e)
                        }}
                        value={values.file_type}
                        handleBlur={handleBlur}
                        label={'files.fileType'}
                    >
                        <option value="">{t('upload.fileTypeUndefined')}</option>
                        {fileTypes?.map((fileType, i) =>
                            <option key={i} value={fileType?.id}>{fileType.name}</option>
                        )}
                    </AppSelect>
                    <FormErrorMessage>{errors.file_type}</FormErrorMessage>
                </FormControl>
            </GridItem>
            <GridItem>
                <FormControl isInvalid={!!errors.file_created_at && touched.file_created_at}>
                    <AppInput
                        label={t('common.createdAt')}
                        name={'file_created_at'}
                        handleBlur={handleBlur}
                        // value={values.file_created_at}
                        type={"date"}
                        icon={<BsFillCalendarFill/>}
                    />
                    <FormErrorMessage>{errors.file_created_at}</FormErrorMessage>
                </FormControl>
            </GridItem>
            <GridItem>
                <FormControl isInvalid={!!errors.internal_id && touched.internal_id}>
                    <AppInput
                        name='internal_id'
                        handleBlur={handleBlur}
                        label={t('files.view.internalID')}
                        placeholder={'12345'}
                    />
                    <FormErrorMessage>{errors.internal_id}</FormErrorMessage>
                </FormControl>
            </GridItem>
            <GridItem>
                <FormControl isInvalid={!!errors.external_id && touched.external_id}>
                    <AppInput
                        name='external_id'
                        handleBlur={handleBlur}
                        label={t('files.view.externalID')}
                        placeholder={"54321"}
                    />
                    <FormErrorMessage>{errors.external_id}</FormErrorMessage>
                </FormControl>
            </GridItem>
            <GridItem colSpan={3}>
                <Stack>
                    <Text fontSize={14}>{t('common.note', {count: 2})}</Text>
                    <FormControl isInvalid={!!errors.note && touched.note}>
                        <InputGroup>
                            <Textarea
                                name='note'
                                onChange={(e) => {
                                    handleChange(e)
                                    handleBlur(e)
                                }}
                                // handleBlur={handleBlur}
                                _focusVisible={{
                                    borderColor: "teal.400",
                                    boxShadow: "0 0 0 1px var(--chakra-colors-teal-400)",
                                }}
                                value={values.note}
                                minH={150}
                                placeholder={t('common.note', {count: 2})}
                                borderColor={"teal.400"}
                                _placeholder={{color: "gray.400"}}
                            />
                        </InputGroup>
                        <FormErrorMessage>{errors.note}</FormErrorMessage>
                    </FormControl>
                </Stack>
            </GridItem>
        </Grid>
    );
}


FileFields.propTypes = {
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    setValues: PropTypes.func,
    predefinedTasks: PropTypes.array,
    withPredefinedTasks: PropTypes.bool,
};
