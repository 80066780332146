import {
    Button,
    Center, Flex,
    Td,
} from "@chakra-ui/react";
import React from "react";
import {BsFileEarmarkCheckFill} from "react-icons/bs";
import Chip from "../Chip";
import PropTypes from "prop-types";
import {CellTypes, Task, taskType} from "../../types";
import {useTranslation} from "react-i18next";
import {AiFillEye} from "react-icons/ai";
import {useDispatch, useSelector} from "react-redux";
import {closeTaskViewModal, showTaskViewModal} from "../../slices/taskViewModalSlice";
import {getEscalationColour, getTaskColour} from "../../utils/taskHelper";
import {useNavigate} from "react-router-dom";
import {isFilled} from "../../utils/generalHelper";
import {selectTaskRawSorting, setSorting} from "../../slices/taskSortModalSlice";
import {
    createColumnHelper,
    getCoreRowModel,
    OnChangeFn,
    SortingState,
    useReactTable,
    VisibilityState
} from "@tanstack/react-table";
import TableCell from "../../pages/Table/TableCell";
import TableWrapper from "../../pages/Table/TableWrapper";

export default function TasksTable({tasks = [], inModal = false}: {
    tasks: Task[], inModal?: boolean
}) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const sorting = useSelector(selectTaskRawSorting)

    //todo extract
    const getTranslatedText = (key: string, count: number = 1): string => {
        return t(key, {count: count}) ?? key;
    };

    const columnHelper = createColumnHelper<Task>()
    const columns = [
            columnHelper.accessor('name', {
                id: 'name',
                meta: {
                    customTd: true,
                },
                header: getTranslatedText('tasks.name'),
                cell: info => <Td w={"full"}>
                    <TableCell
                        content={info.getValue() as string}
                        shorten={false}
                        isContentFilled={isFilled(info.row.original.name)}
                    /></Td>,
                enableSorting: true,
            }),
            columnHelper.accessor(row => `${row.creator.first_name} ${row.creator.last_name}`, {
                id: 'createdBy',
                header: getTranslatedText('common.createdBy'),
                cell: info => <TableCell
                    content={info.row.original.creator.first_name}
                    subContent={info.row.original.creator.last_name}
                    isContentFilled={isFilled(info.row.original.creator.first_name)}
                    // shorten={true}
                    type={CellTypes.Name}
                />,
                enableSorting: false,
            }),
            columnHelper.accessor(row => `${row.assignee.first_name} ${row.assignee.last_name}`, {
                id: 'assignee',
                header: getTranslatedText('common.assignee'),
                cell: info => <TableCell
                    content={info.row.original.assignee.first_name}
                    subContent={info.row.original.assignee.last_name}
                    isContentFilled={isFilled(info.row.original.assignee.first_name)}
                    // shorten={true}
                    type={CellTypes.Name}
                />,
                enableSorting: false,
            }),
            columnHelper.accessor('status', {
                id: 'status',
                header: getTranslatedText('common.status'),
                cell: info => <Chip color={getTaskColour(info.row.original.status)}>
                    {t(`tasks.status.${info.row.original.status}`).toUpperCase()}
                </Chip>,
                enableSorting: false,
            }),
            columnHelper.accessor('days_left', {
                id: 'daysLeft',
                header: getTranslatedText('tasks.timeLeft'),
                cell: info => <Center gap={2}>
                    <BsFileEarmarkCheckFill
                        fontSize={20}
                        color={`var(--chakra-colors-${getEscalationColour(info.row.original.days_left, info.row.original.status)}-400)`}
                    />

                    {(info.row.original.status !== 'done' && info.row.original.status !== 'closed') && `${info.row.original.days_left} d`}
                </Center>,
            }),
            columnHelper.display({
                id: 'actions',
                meta: {
                    customTd: true,
                },
                cell: info => <Td px={0}
                                  bg={info.row.index % 2 === 0 ? "blackAlpha.100 !important" : "whiteAlpha.100 !important"}>
                    <Flex justifyContent={"space-around"}>
                        <Button height={'25px'} colorScheme={'white'}
                                onClick={() => {
                                    if (inModal) {
                                        dispatch(closeTaskViewModal())
                                        navigate(`/tasks/${info.row.original.id}`)
                                    } else {
                                        dispatch(showTaskViewModal({task: info.row.original}))
                                    }
                                }}>
                            <AiFillEye
                                fontSize={25}
                                color="var(--chakra-colors-teal-400)"
                            />
                        </Button>
                    </Flex>
                </Td>,
                enableSorting: false,
            }),
        ]
    ;

    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(
        {created_at: !inModal, tasks: !inModal}
    )
    const handleSortingChange: OnChangeFn<SortingState> = (newSorting) => {
        dispatch(setSorting(newSorting));
    };

    const table = useReactTable({
        data: tasks,
        columns,
        state: {
            columnVisibility,
            sorting,
        },
        onColumnVisibilityChange: setColumnVisibility,
        onSortingChange: handleSortingChange,
        manualSorting: true,
        enableSorting: !inModal,
        getCoreRowModel: getCoreRowModel(),
    })

    return (
        <TableWrapper table={table} noDataMessage={'tasks.noTasks'}/>
    );
}

TasksTable.propTypes = {
    tasks: PropTypes.arrayOf(taskType),
    inModal: PropTypes.bool,
};
