import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store/store";
import {SortingState} from "@tanstack/react-table";
import {SortDirection} from "../types";

const fileSortModalSliceInitialState = {
    showModal: false,
    //this one is needed due to tanstack table!
    sorting: [{id: 'createdAt', desc: true}],
    sortField:  'createdAt',
    sortDirection:  SortDirection.Desc,
};

type SetModalSortingAction = {
    payload: {
        sortField: string;
        sortDirection: SortDirection;
    };
    type: string;
};

const fileSortModalSlice = createSlice({
    name: 'fileSortModal',
    initialState: fileSortModalSliceInitialState,
    reducers: {
        showFileSortModal: (state) => {
            state.showModal = true
        },
        closeFileSortModal: (state) => {
            state.showModal = false
        },
        clearFileSort: (state) => {
            state.sorting = fileSortModalSliceInitialState.sorting
            state.sortField = fileSortModalSliceInitialState.sortField
            state.sortDirection = fileSortModalSliceInitialState.sortDirection
        },
        setSorting: (state, action: PayloadAction<SortingState | ((oldState: SortingState) => SortingState)>) => {
            const payload = action.payload;
            if (typeof payload === 'function') {
                state.sorting = payload(state.sorting);
                state.sortField = payload(state.sorting)[0]?.id
                state.sortDirection = typeof payload(state.sorting)[0]?.desc === "boolean"
                    ? payload(state.sorting)[0].desc
                        ? SortDirection.Desc
                        : SortDirection.Asc
                    : SortDirection.Desc
            } else {
                state.sorting = payload;
                state.sortField = payload[0]?.id
                state.sortDirection = typeof payload[0]?.desc === "boolean"
                    ? payload[0].desc
                        ? SortDirection.Desc
                        : SortDirection.Asc
                    : SortDirection.Desc
            }
        },
        setModalSorting: (state, action: SetModalSortingAction) => {
            state.sorting[0].id = action.payload.sortField;
            state.sorting[0].desc = action.payload.sortDirection === SortDirection.Desc ;
            state.sortField = action.payload.sortField;
            state.sortDirection = action.payload.sortDirection;
        }
    },
    extraReducers: (builder) => {
        // Needed to refresh file to see new task for example in modal
    },
})

export const {showFileSortModal, closeFileSortModal, clearFileSort, setSorting, setModalSorting} = fileSortModalSlice.actions

export const isFileSortOpen = (state: RootState) => state.fileSortModal.showModal

export const isFileSorted = (state: RootState) => state.fileSortModal.sorting !== fileSortModalSliceInitialState.sorting

export const selectFileSortModal = (state: RootState) => state.fileSortModal
export const selectFileRawSorting = (state: RootState) => state.fileSortModal.sorting

export default fileSortModalSlice.reducer
