import {
    AccordionButton,
    AccordionIcon,
    Heading,
    HStack,
    Text, Tooltip,
} from "@chakra-ui/react";
import React from "react";
import {IoAttach, IoCheckmarkCircle} from "react-icons/io5";
import {File} from "../../types";
import {BsFileEarmarkCheckFill} from "react-icons/bs";
import {useTranslation} from "react-i18next";

export default function FileAccordionButton({file, submittedDetails = []}: { file: File, submittedDetails: string[] }) {
    const {t} = useTranslation()

    const shorten = (content: string|null, maxLength = 40) => {
        return content !== null && content.length > maxLength
            ? <Tooltip hasArrow label={content}>
                {content.substring(0, maxLength) + '...'}
            </Tooltip>
            : content
    }

    return (
        <Heading size={'h2'}>
            <AccordionButton p={3}>
                <HStack flex='1' textAlign='left'>
                    <Text>{file?.name ? shorten(file.name) : '-'} / {file?.document_name ? shorten(file.document_name) : '-'}</Text>
                    <Text> | </Text>
                    <Text fontSize={'xs'}>{t('files.view.intID')}</Text> <Text>{file?.internal_id ?? '-'}</Text>
                    <Text fontSize={'xs'}>{t('files.view.extID')}</Text> <Text>{file?.external_id ?? '-'}</Text>
                    <Text> | </Text>
                    {/*not working item is somewhere in corner!*/}
                    {/*<Tooltip hasArrow label={t('tasks.task', {count:2})}>*/}
                    {/*<>{file?.tasks.length ?? 0} <BsFileEarmarkCheckFill color="var(--chakra-colors-teal-400)"/></>*/}
                    {/*</Tooltip>*/}
                    <Text>{file?.tasks.length ?? 0}</Text> <BsFileEarmarkCheckFill color="var(--chakra-colors-teal-400)"/>
                    <Text> | </Text>
                    <Text>{(file?.count_linked_from + file?.count_linked_to) ?? 0}</Text> <IoAttach color="var(--chakra-colors-teal-400)"/>
                </HStack>
                {submittedDetails.includes(file.id)
                    ? <IoCheckmarkCircle color="var(--chakra-colors-teal-500)"/>
                    : null
                }
                {/* Use when I get validation error */}
                {/*<IoAlertCircle color="var(--chakra-colors-red-500)"/>*/}
                {/* Show also another one for in progress (not submitted, touched)*/}
                <AccordionIcon/>
            </AccordionButton>
        </Heading>
    );
}
