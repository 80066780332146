import {
    Box,
    Button,
    HStack,
    Heading,
    Spacer,
    Stack,
    AccordionPanel,
    AccordionItem,
    Accordion,
    Center,
    Tag,
    Divider,
} from "@chakra-ui/react";
import React, {useState} from "react";
import {IoMdAddCircleOutline} from "react-icons/io";
import {array, date, object, string} from "yup";
import {FieldArray, Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import {useGetPredefinedTasksListQuery} from "../../services/predefinedTaskService";
import TaskCreateFields from "../Tasks/TaskCreateFields";
import {showFilePreviewModal} from "../../slices/filePreviewModalSlice";
import {File, UploadHistory} from "../../types";
import TasksTable from "../Tasks/TasksTable";
import {useCreateBulkTaskMutation} from "../../services/taskService";
import {isNoAccessFile, transformFormValues} from "../../utils/generalHelper";
import FileAccordionButton from "./FileAccordionButton";
import NoAccessFileAccordionButton from "./NoAccessFileAccordionButton";

export default function FileTasks({setStep, uploadHistory}: {
    setStep: (step: number) => void,
    uploadHistory: UploadHistory,
}) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {data: predefinedTasks, isLoading: isLoadingPredefinedTasks} = useGetPredefinedTasksListQuery()
    const [addTasks, {isLoading: isLoadingTasks}] = useCreateBulkTaskMutation()
    const [submittedDetails, setSubmittedDetails] = useState<string[]>([]);
    const [index, setIndex] = useState<number | number[]>(0)

    const schema = object({
        tasks: array().of(
            object({
                name: string().min(2).required(),
                predefined_task: string().uuid(),
                note: string(),
                assignee_id: string().uuid().required(),
                // file_id: string().uuid().required(),
                escalation_date: date().required(),
            })
        )
    });

    const addPredefinedTasks = (file: File) => {
        return predefinedTasks?.filter(predefinedTask => predefinedTask.event === 'all').concat(predefinedTasks?.filter(
            predefinedTask => (predefinedTask.event_id === file.file_type?.id && predefinedTask?.event === 'fileType')
        ))?.map(predefinedTask => {
            return {
                name: predefinedTask?.task_name ?? '',
                predefined_task: predefinedTask?.id ?? '',
                note: predefinedTask?.task_note ?? '',
                assignee_id: predefinedTask?.task_assignee?.id ?? '',
                file_id: file.id,
                escalation_date: predefinedTask?.task_days_to_escalate
                    ? moment().add(predefinedTask?.task_days_to_escalate, 'days').format('YYYY-MM-DD')
                    : moment().format('YYYY-MM-DD')
            }
        }) ?? []
    }
    //
    // if (isLoading) {
    //     return (
    //         <Box>
    //         </Box>
    //     )
    // }

    return (
        <Box>
            {/*
            - first check if file has some tasks
                - if not and it is not first time, show button add predefined tasks. (mark them that they are new/existing) add label or tag
                - if it is first time automatically apply all the stuff (show tag new) - DONE!
                - if it already has tasks click reapply predefined (show note that some tasks might be duplicated)
                - add button to bottom apply all predefined tasks if it is first time and new. (Apply all and Finish)
            */}

            {/* Index is hack to open first */}
            <Accordion allowMultiple allowToggle index={index} onChange={setIndex}>
                {uploadHistory.files.map((file) =>
                    <AccordionItem bg={"#fff"} isDisabled={isNoAccessFile(file)} borderRadius={"lg"} mt={8}>
                        {isNoAccessFile(file)
                            ? <NoAccessFileAccordionButton file={file}/>
                            : <>
                                <FileAccordionButton file={file} submittedDetails={submittedDetails}/>
                                <AccordionPanel pb={4}>
                                    <Stack gap={4}>
                                        <Center mb={4} pe={8}>
                                            <Spacer/>
                                            <Button
                                                bg={"transparent"}
                                                color={"teal.400"}
                                                onClick={() => dispatch(showFilePreviewModal({
                                                    id: file.id,
                                                    name: file.document_name
                                                }))}
                                            >
                                                {t('common.actions.preview')}
                                            </Button>
                                        </Center>
                                    </Stack>
                                    <Box bg={"#fff"} px={8} pb={8} borderRadius={10}>
                                        <Heading size={"sm"}>{t('tasks.existingTask', {count: 2})}</Heading>
                                        <TasksTable tasks={file?.tasks} inModal={true}/>
                                    </Box>

                                    <Formik
                                        validationSchema={schema}
                                        validateOnMount={true}
                                        enableReinitialize={true}
                                        onSubmit={(values, {setSubmitting, resetForm}) => {
                                            setSubmitting(true);
                                            addTasks({
                                                ...values.tasks.map((task) => {
                                                    return {...transformFormValues(task, ['escalation_date'])}
                                                })
                                            })
                                            setSubmitting(false);
                                            setSubmittedDetails([...submittedDetails, file.id])
                                            resetForm()
                                        }}
                                        initialValues={{
                                            tasks: file?.tasks.length === 0 ? addPredefinedTasks(file) : []
                                        }}>
                                        {({
                                              handleSubmit,
                                              handleChange,
                                              handleReset,
                                              handleBlur,
                                              values,
                                              dirty,
                                              touched,
                                              isValid,
                                              isSubmitting,
                                              errors,
                                              setFieldValue,
                                              setFieldTouched,
                                              setValues,
                                          }) => (<Form>
                                            <Stack gap={4} bg={"#fff"} p={8} borderRadius={10}>
                                                <FieldArray name="tasks">
                                                    {({insert, remove, push}) => (<>
                                                        <HStack mb={"-3"} px={2}>
                                                            <Heading size={"sm"}>{t('tasks.task', {count: 2})}</Heading>
                                                            <Spacer/>
                                                            <Button
                                                                variant={"outline"}
                                                                w={"auto"}
                                                                onClick={() => {
                                                                    push({
                                                                            name: '',
                                                                            predefined_task: '',
                                                                            note: '', // creator_id: '',
                                                                            assignee_id: '',
                                                                            file_id: file.id,
                                                                            escalation_date: moment().format('YYYY-MM-DD'),
                                                                            // status: 'id',
                                                                        }
                                                                    )
                                                                }}
                                                                leftIcon={<IoMdAddCircleOutline
                                                                    color="var(--chakra-colors-teal-400)"/>}
                                                            >
                                                                {t('tasks.createTask.button')}
                                                            </Button>
                                                        </HStack>
                                                        {values.tasks.map((task, index) => {
                                                            return (<>
                                                                <Box>
                                                                    <Tag size={'md'} variant='solid' colorScheme='teal'>
                                                                        {t('common.new')}
                                                                    </Tag>
                                                                </Box>
                                                                <TaskCreateFields
                                                                    key={`tasks-${index}`}
                                                                    handleBlur={handleBlur}
                                                                    handleChange={handleChange}
                                                                    predefinedTaskValue={values.tasks[index].predefined_task}
                                                                    assigneeValue={values.tasks[index].assignee_id}
                                                                    noteValue={values.tasks[index].note}
                                                                    namePrefix={`tasks.${index}.`}
                                                                    errors={errors?.tasks?.[index]}
                                                                    touched={touched?.tasks?.[index]}
                                                                    predefinedTasks={predefinedTasks}
                                                                    setFieldValue={setFieldValue}
                                                                />

                                                                <HStack pt={3} key={`btn-${index}`}>
                                                                    <Spacer/>
                                                                    <Button variant={"gray"}
                                                                            onClick={() => remove(index)}>
                                                                        {t('common.actions.delete')}
                                                                    </Button>

                                                                </HStack>
                                                                <Divider/>
                                                            </>)
                                                        })}
                                                    </>)}
                                                </FieldArray>
                                            </Stack>
                                            <Box p={8} bg={"#fff"} mt={8} borderRadius={"lg"}>
                                                <HStack>
                                                    <Spacer/>
                                                    <Button isLoading={isLoadingTasks}
                                                            loadingText={t('upload.actions.uploading')}
                                                            type={'submit'}
                                                            variant={"primary"}>{t('common.actions.save')}</Button>
                                                </HStack>
                                            </Box>
                                        </Form>)}
                                    </Formik>
                                </AccordionPanel>
                            </>
                        }
                    </AccordionItem>
                )}
            </Accordion>
            <Box p={8} bg={"#fff"} mt={8} borderRadius={"lg"}>
                <HStack>
                    <Spacer/>
                    <Button isLoading={isLoadingTasks}
                            loadingText={t('upload.actions.uploading')}
                            onClick={() => setStep(4)}
                            variant={"primary"}>{t('common.actions.next')}</Button>
                </HStack>
            </Box>
        </Box>);
}
