
export const Role = {
    SuperAdmin: 'ROLE_SUPER_ADMIN',
    Admin: 'ROLE_ADMIN',
    Owner: 'ROLE_OWNER',
    Manager: 'ROLE_MANAGER',
    User: 'ROLE_USER',
    AgencyClient: 'ROLE_AGENCY_CLIENT',
    External: 'ROLE_EXTERNAL'
};

export const roleHierarchy = {
    ROLE_SUPER_ADMIN: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OWNER', 'ROLE_MANAGER', 'ROLE_USER', 'ROLE_AGENCY_CLIENT', 'ROLE_EXTERNAL'],
    ROLE_ADMIN: ['ROLE_ADMIN', 'ROLE_OWNER', 'ROLE_MANAGER', 'ROLE_USER', 'ROLE_AGENCY_CLIENT', 'ROLE_EXTERNAL'],
    ROLE_OWNER: ['ROLE_OWNER', 'ROLE_MANAGER', 'ROLE_USER', 'ROLE_AGENCY_CLIENT', 'ROLE_EXTERNAL'],
    ROLE_MANAGER: ['ROLE_MANAGER', 'ROLE_USER', 'ROLE_AGENCY_CLIENT', 'ROLE_EXTERNAL'],
    ROLE_USER: ['ROLE_USER', 'ROLE_AGENCY_CLIENT', 'ROLE_EXTERNAL'],
    ROLE_AGENCY_CLIENT: ['ROLE_AGENCY_CLIENT', 'ROLE_EXTERNAL'],
    ROLE_EXTERNAL: ['ROLE_EXTERNAL'],
};

// // Define roleHierarchy as a TypeScript Record
// export const roleHierarchy: Record<Role, Role[]> = {
//     [Role.SuperAdmin]: [Role.SuperAdmin, Role.Admin, Role.Owner, Role.Manager, Role.User, Role.External],
//     [Role.Admin]: [Role.Admin, Role.Owner, Role.Manager, Role.User, Role.External],
//     [Role.Owner]: [Role.Owner, Role.Manager, Role.User, Role.External],
//     [Role.Manager]: [Role.Manager, Role.User, Role.External],
//     [Role.User]: [Role.User, Role.External],
//     [Role.External]: [Role.External],
