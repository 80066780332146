import {createSlice} from '@reduxjs/toolkit'
import {isFilled} from "../utils/generalHelper";
import moment from "moment";

const fileFilterModalSliceInitialState = {
    showModal: false,
    fileType: null,
    year: moment().year().toString()
};

const fileFilterModalSlice = createSlice({
    name: 'fileFilterModal',
    initialState: fileFilterModalSliceInitialState,
    reducers: {
        showFileFilterModal: (state, action) => {
            state.showModal = true
        },
        closeFileFilterModal: (state, action) => {
            state.showModal = false
        },
        clearFileFilter: (state) => {
            state.fileType = null
        },
        setFileFilter: (state, action) => {
            state.fileType = action.payload.file_type
        },
        setYearFilter: (state, action) => {
            state.year = action.payload
        },
    },
    extraReducers: (builder) => {
        // Needed to refresh file to see new task for example in modal
    },
})

export const {
    showFileFilterModal,
    closeFileFilterModal,
    clearFileFilter,
    setFileFilter,
    setYearFilter
} = fileFilterModalSlice.actions

export const isFileFilterOpen = (state) => state.fileFilterModal.showModal

export const isFileFiltered = (state) => isFilled(state.fileFilterModal.fileType)

export const selectFileFilterModal = (state) => state.fileFilterModal

export const selectYearFilter = (state) => state.fileFilterModal.year

export default fileFilterModalSlice.reducer
