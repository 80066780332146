import React from "react";
import {
    Box,
    Center,
    Flex,
    Heading,
    Spacer,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import FilesTableCell from "../Files/FilesTableCell";
import {isFilled} from "../../utils/generalHelper";
import {useTranslation} from "react-i18next";
import {
    useDeletePredefinedTaskMutation,
    useGetPredefinedTasksListQuery
} from "../../services/predefinedTaskService";
import {useGetFileTypesListQuery} from "../../services/fileTypeService";
import {IoTrash} from "react-icons/io5";
import DeleteDialog from "../DeleteDialog";
import {Role} from "../../constants/roles";
import {RoleAccess} from "../../utils/RoleAccess";
import {CellTypes} from "../../types";

export default function PredefinedTasksTable() {
    const {data: predefinedTasks} = useGetPredefinedTasksListQuery()
    const [remove, {isLoading}] = useDeletePredefinedTaskMutation()
    const {data: fileTypes} = useGetFileTypesListQuery()
    const {t} = useTranslation()

    return (
        <Box
            bg={"white"}
            rounded={"2xl"}
            border={"1px primary var(--chakra-colors-gray-200)"}
            mt={"6"}
        >
            <Center p={8} mt={4} mb={2}>
                <Heading size={"sm"}>{t('predefinedTasks.predefinedTask', {count: 2})}</Heading>
                <Spacer/>
            </Center>
            <TableContainer w={"full"}>
                <Table variant="striped">
                    <Thead>
                        <Tr sx={{th: {color: "black", textAlign: "center"}}}>
                            <Th>{t('common.name')}</Th>
                            <Th>{t('predefinedTasks.taskName')}</Th>
                            <Th>{t('predefinedTasks.taskAssignee')}</Th>
                            <Th>{t('predefinedTasks.event')}</Th>
                            <Th>{t('predefinedTasks.eventId')}</Th>
                            <Th>{t('common.createdBy')} </Th>
                            <Th>{t('common.createdAt')} </Th>
                            <RoleAccess allowedRoles={[Role.Manager]}>
                                <Th></Th>
                            </RoleAccess>
                        </Tr>
                    </Thead>
                    <Tbody
                        sx={{
                            td: {color: "gray.700", textAlign: "center"},
                            "tr:nth-of-type(odd) td": {bg: "gray.50"},
                        }}
                    >
                        {predefinedTasks?.map((predefinedTask, i) => {
                            return (
                                <Tr key={"b" + i}>
                                    <FilesTableCell
                                        content={predefinedTask.name}
                                        shorten={true}
                                    />
                                    <FilesTableCell
                                        content={predefinedTask.task_name}
                                        shorten={true}
                                    />
                                    <FilesTableCell
                                        content={predefinedTask?.task_assignee?.first_name}
                                        subContent={predefinedTask?.task_assignee?.last_name}
                                        type={CellTypes.Name}
                                        shorten={true}
                                    />
                                    <FilesTableCell
                                        content={isFilled(predefinedTask?.event) ? t(`predefinedTasks.events.${predefinedTask?.event}`) : t('predefinedTasks.events.noEvent')}
                                    />
                                    <FilesTableCell
                                        content={fileTypes?.find(fileType => fileType.id === predefinedTask?.event_id)?.name}
                                    />
                                    <FilesTableCell
                                        content={predefinedTask?.user?.first_name}
                                        subContent={predefinedTask?.user?.last_name}
                                        type={CellTypes.Name}
                                        shorten={true}
                                    />
                                    <FilesTableCell
                                        content={predefinedTask.created_at}
                                        type={CellTypes.Date}
                                    />
                                    <RoleAccess allowedRoles={[Role.Manager]}>
                                        <Td px={0} bg={i % 2 === 0 && "blackAlpha.100 !important"}>
                                            <Flex justifyContent={"space-around"}>
                                                {/*<Button*/}
                                                {/*    height={'25px'}*/}
                                                {/*    pe={0}*/}
                                                {/*    colorScheme={'white'}*/}
                                                {/*    isLoading={isLoading}*/}
                                                {/*    // loadingText='Downloading'*/}
                                                {/*    onClick={() => remove(predefinedTask?.id)}*/}
                                                {/*>*/}
                                                {/*    <IoTrash*/}
                                                {/*        fontSize={20}*/}
                                                {/*        color="var(--chakra-colors-red-400)"*/}
                                                {/*    />*/}
                                                {/*</Button>*/}
                                                <DeleteDialog
                                                    buttonProps={{
                                                        height: '20px',
                                                        pe: 0,
                                                        colorScheme: 'white',
                                                        isLoading: isLoading,
                                                    }}
                                                    icon={<IoTrash
                                                        fontSize={20}
                                                        color="var(--chakra-colors-red-400)"
                                                    />}
                                                    infoAlert={'predefinedTasks.delete.infoAlert'}
                                                    deleteAction={() => remove(predefinedTask?.id)}
                                                />
                                            </Flex>
                                        </Td>
                                    </RoleAccess>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
}

PredefinedTasksTable.propTypes = {};
