import React from "react";
import {
    FormControl, FormErrorMessage, Grid, GridItem, InputGroup, Stack, Text, Textarea,
} from "@chakra-ui/react";
import AppSelect from "../AppSelect";
import AppInput from "../AppInput";
import {useGetUsersListQuery} from "../../services/userService";
import {useTranslation} from "react-i18next";
import {BsFillCalendarFill} from "react-icons/bs";
import PropTypes from "prop-types";
import {isFilled} from "../../utils/generalHelper";
import moment from "moment";

export default function TaskCreateFields({
                                             handleChange,
                                             handleBlur,
                                             predefinedTaskValue,
                                             assigneeValue,
                                             noteValue,
                                             namePrefix = '',
                                             errors = {},
                                             touched = {},
                                             predefinedTasks = [],
                                             setFieldValue,
                                         }) {
    const {t} = useTranslation()
    const {data: users} = useGetUsersListQuery()


    return (<Grid pt={4} templateColumns="repeat(3, 1fr)" columnGap={8} rowGap={6}>
        <GridItem colSpan={3}>
            <AppSelect
                label={t('tasks.createTask.predefinedTask')}
                value={predefinedTaskValue}
                handleBlur={handleBlur}
                onChange={(e) => {
                    handleChange(e)
                    let predefinedTask = predefinedTasks.find(predefinedTask => predefinedTask.id === e.target.value)
                    if (isFilled(predefinedTask)) {
                        setFieldValue(`${namePrefix}name`, predefinedTask?.task_name);
                        if (users?.some(user => user.id === predefinedTask?.task_assignee?.id)) {
                            setFieldValue(`${namePrefix}assignee_id`, predefinedTask?.task_assignee?.id);
                        }
                        if (isFilled(predefinedTask?.task_note)) {
                            setFieldValue(`${namePrefix}note`, predefinedTask?.task_note);
                        }

                        if (isFilled(predefinedTask?.task_days_to_escalate)) {
                            setFieldValue(
                                `${namePrefix}escalation_date`,
                                moment().add(predefinedTask?.task_days_to_escalate, 'days').format('YYYY-MM-DD')
                            );
                        }
                    }
                }}
                name={`${namePrefix}predefined_task`}
                placeholder={t('tasks.createTask.noPredefinedTask')}
            >
                {predefinedTasks?.map((predefinedTask) =>
                    <option key={predefinedTask.id} value={predefinedTask.id}>
                        {predefinedTask.name}
                    </option>
                )}
            </AppSelect>
        </GridItem>

        <GridItem>
            <FormControl isInvalid={!!errors.name && touched.name}>
                <AppInput name={`${namePrefix}name`} handleBlur={handleBlur} label={t('common.name')}
                          placeholder={t('common.name')}/>
                <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
        </GridItem>
        <GridItem>
            <FormControl isInvalid={!!errors.assignee_id && touched.assignee_id}>
                <AppSelect name={`${namePrefix}assignee_id`}
                           onChange={handleChange}
                           handleBlur={handleBlur}
                           value={assigneeValue}
                           label={t('common.assignee')}
                           placeholder={t('common.select')}
                >
                    {users?.map((user) =>
                        <option key={user.id} value={user.id}>
                            {user.first_name} {user.last_name}
                        </option>)
                    }
                </AppSelect>
                <FormErrorMessage>{errors.assignee_id}</FormErrorMessage>
            </FormControl>
        </GridItem>
        <GridItem>
            <FormControl isInvalid={!!errors.escalation_date && touched.escalation_date}>
                <AppInput
                    label={t('tasks.completeBy')}
                    name={`${namePrefix}escalation_date`}
                    handleBlur={handleBlur}
                    type={"date"}
                    icon={<BsFillCalendarFill/>}
                />
                <FormErrorMessage>{errors.escalation_date}</FormErrorMessage>
            </FormControl>
        </GridItem>

        <GridItem colSpan={3}>
            <Stack>
                <Text fontSize={14}>{t('common.note', {count: 1})}</Text>
                <FormControl isInvalid={!!errors.note && touched.note}>
                    <InputGroup>
                        <Textarea
                            name={`${namePrefix}note`}
                            onChange={(e) => {
                                handleChange(e)
                                handleBlur(e)
                            }}
                            value={noteValue}
                            _focusVisible={{
                                borderColor: "teal.400", boxShadow: "0 0 0 1px var(--chakra-colors-teal-400)",
                            }}
                            minH={150}
                            placeholder={t('common.note', {count: 1})}
                            borderColor={"teal.400"}
                            _placeholder={{color: "gray.400"}}
                        />
                    </InputGroup>
                    <FormErrorMessage>{errors.note}</FormErrorMessage>
                </FormControl>
            </Stack>
        </GridItem>
    </Grid>);
}

TaskCreateFields.propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    predefinedTaskValue: PropTypes.string,
    assigneeValue: PropTypes.string,
    noteValue: PropTypes.string.isRequired,
    namePrefix: PropTypes.string,
    errors: PropTypes.object,
    touched: PropTypes.object,
    predefinedTasks: PropTypes.array,
    setFieldValue: PropTypes.func.isRequired,
};
