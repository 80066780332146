import {
    AccountType,
    Company,
    File,
    FileAccessResponse,
    LinkedFileUndirected,
    NoAccessFile,
    PaginatedLinkedFiles
} from "../types";
import moment from "moment";

type FormValues = { [key: string]: any };

export const isFilled = <T>(value: T | null | undefined): value is T => value !== null && value !== undefined;

export const isEnterprise = (company: Company) => {
    return [AccountType.companyEnterprise, AccountType.agencyEnterprise].includes(company.account_type)
}

export const isAgencyClient = (company: Company) => {
    return company.account_type === AccountType.agencyClient
}

export const transformFormValues = (
    values: FormValues,
    dateKeys: string[] = [],
    nullifyAll: boolean = true,
    nullifyKeys: string[] = [],
    dateFormat: string = 'YYYY-MM-DD'
): FormValues => {
    return Object.entries(values).reduce((acc, [key, value]) => {
        acc[key] = value;

        if ((nullifyKeys.includes(key) || nullifyAll) && value === '') {
            acc[key] = null;
        }

        if (dateKeys.includes(key) && value) {
            acc[key] = moment(value).format(dateFormat);
        }

        return acc;
    }, {} as FormValues);
}


export const transformLinkedFilesToUndirected = (
    linkedFiles: PaginatedLinkedFiles | undefined,
    owningFileId: string
): LinkedFileUndirected[] =>
    linkedFiles?.data.flatMap((linkedFile): LinkedFileUndirected[] => {
        const linkedSide = linkedFile.linked_from.id === owningFileId
            ? linkedFile.linked_to
            : linkedFile.linked_from.id === owningFileId
                ? null
                : linkedFile.linked_from;

        return linkedSide ? [{
            id: linkedFile.id,
            linked: linkedSide,
            note: linkedFile.note,
        }] : [];
    }) ?? [];



export const isNoAccessFile = (file: File | NoAccessFile): file is NoAccessFile => {
    return (file as NoAccessFile).name === FileAccessResponse.AccessDenied;
}
