import React, { useRef, useState } from "react";
import {
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { BsFillCalendarFill } from "react-icons/bs";

export default function TasksFilterModal(props) {
  const [input, setInput] = useState({});
  const input_ref = useRef(null);

  function handleChange(e) {
    setInput((p) => ({ ...p, [e.target.name]: e.target.value }));
  }

  function handleDatePicker() {
    // console.log(input_ref.current.target);
    input_ref.current.datepicker();
  }
  const item = [
    {
      title: "Date From",
      type: "date",
      name: "date-from",
    },
    {
      title: "Type of File ",
      type: "text",
      name: "type-of-file ",
    },
    {
      title: "Date To",
      type: "date",
      name: "date-to",
    },
    {
      title: "Created For",
      type: "text",
      name: "created-for",
    },
    {
      title: "Created By",
      type: "text",
      name: "created-by",
    },
  ];
  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent minW={700} borderRadius={"2xl"}>
        <ModalHeader fontWeight={700} fontSize={22}>
          Filter
        </ModalHeader>
        <ModalBody mb={6}>
          <Flex
            columnGap={8}
            rowGap={4}
            flexWrap={"wrap"}
            px={2}
            justifyContent={"space-between"}
          >
            {item.map((value, i) => {
              return (
                <Stack key={value.name + i} maxW={300} w={"full"}>
                  <HStack>
                    <Checkbox
                      defaultChecked
                      sx={{
                        span: { borderRadius: "50%", p: "1px" },
                        svg: { width: "1em" },
                      }}
                    />
                    <Text>{value.title}</Text>
                  </HStack>
                  <InputGroup pl={"20px"}>
                    <Input
                      ref={input_ref}
                      type={value.type}
                      onChange={handleChange}
                      value={input[value.name]}
                      placeholder="Enter Username"
                      name={value.name}
                    />
                    {/* {value.type === "date" && (
                      <InputRightElement
                        sx={{ pointerEvents: "inherit" }}
                        pointerEvents="none"
                        onClick={handleDatePicker}
                        children={
                          <BsFillCalendarFill color="var(--chakra-colors-gray-300)" />
                        }
                      />
                    )} */}
                  </InputGroup>
                </Stack>
              );
            })}
          </Flex>
        </ModalBody>

        <Center gap={4} p={8}>
          <Button
            variant={"outline"}
            color={"teal.400"}
            borderColor={"teal.400"}
          >
            Clear Filter
          </Button>
          <Spacer />
          <Button onClick={props.onClose}>Cancel</Button>
          <Button colorScheme="blue" bg={"teal.400"}>
            Apply
          </Button>
        </Center>
      </ModalContent>
    </Modal>
  );
}
