import { Tag } from "@chakra-ui/react";
import React from "react";
import PropTypes from "prop-types";

export default function Chip({ children, color, ...props }) {
  return (
    <Tag
      py={2}
      bg={color + ".100"}
      color={color + ".800"}
      w={100}
      justifyContent={"center"}
      {...props}
    >
      {children}
    </Tag>
  );
}

Chip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  color: PropTypes.string,
};
