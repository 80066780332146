import {
    Box,
    Button,
    HStack,
    Heading,
    Spacer,
    Stack,
    AccordionPanel,
    AccordionItem,
    Accordion,
    Center,
} from "@chakra-ui/react";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useGetFileQuery} from "../../services/fileService";
import {showFilePreviewModal} from "../../slices/filePreviewModalSlice";
import {useNavigate} from "react-router-dom";
import FileAccordionButton from "./FileAccordionButton";
import LinkedFilesTable from "../LinkedFiles/LinkedFilesTable";
import {useGetLinkedFilesForFileListQuery} from "../../services/linkedFilesService";
import {transformLinkedFilesToUndirected} from "../../utils/generalHelper";
import CreateLinkedFiles from "../LinkedFiles/CreateLinkedFiles";
import {File} from "../../types";

export default function FileLinkedFiles({file, submittedDetails, setSubmittedDetails}: {
    file: File,
    submittedDetails: string[],
    setSubmittedDetails: React.Dispatch<React.SetStateAction<string[]>>,
}) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {data: linkedFiles, isLoading: isLoadingLinkedFiles} = useGetLinkedFilesForFileListQuery({id: file.id})

    if (isLoadingLinkedFiles) {
        return (
            <Box>
            </Box>
        )
    }

    return (
        <>
            <Stack gap={4}>
                <Center mb={4} pe={8}>
                    <Spacer/>
                    <Button
                        bg={"transparent"}
                        color={"teal.400"}
                        onClick={() => dispatch(showFilePreviewModal({
                            id: file.id,
                            name: file.document_name
                        }))}
                    >
                        {t('common.actions.preview')}
                    </Button>
                </Center>
            </Stack>
            <Box bg={"#fff"} px={8} pb={8} borderRadius={10}>
                <Heading size={"sm"}>{t('linkedFiles.existingLinked', {count: 2})}</Heading>
                {/*TODO HACKED BY !*/}
                <LinkedFilesTable linkedFiles={transformLinkedFilesToUndirected(linkedFiles, file.id)}/>
            </Box>
            <Box p={8}>
                <CreateLinkedFiles
                    owningFileId={file.id!}
                    isLoadingLinkedFiles={isLoadingLinkedFiles}
                    linkedFiles={linkedFiles!}
                    onFormSubmitSuccess={() => setSubmittedDetails([...submittedDetails, file.id])
                    }
                />
            </Box>
        </>
    );
}
