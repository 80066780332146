import {baseApi} from "./apiService";

export const userApi = baseApi.injectEndpoints({
        overrideExisting: false,
        endpoints: (builder) => ({
            login: builder.mutation({
                query: (credentials) => ({
                    url: 'login_check',
                    method: 'POST',
                    body: credentials,
                }),
            }),
            getCurrentUser: builder.query({
                query: () => ({
                    url: 'users/me',
                    method: 'GET',
                    providesTags: ['CompanyDependent', 'CurrentUser'],
                }),
            }),
            getUsersList: builder.query({
                query: (page) => ({
                    url: 'users',
                    params: {page: page},
                }),
                providesTags: (result, error, arg) =>
                    result
                        ? [...result.map(({id}) => ({type: 'Users', id})), 'Users']
                        : ['Users'],
            }),
            changePassword: builder.mutation({
                query(data) {
                    const {id, ...body} = data
                    return {
                        url: `users/${id}/change_password`,
                        method: 'PUT',
                        body,
                    }
                },
                // invalidatesTags: (result, error, {id}) => [{type: 'Projects', id}],
            }),
            inviteUser: builder.mutation({
                query(data) {
                    return {
                        url: `users/invite`,
                        method: 'POST',
                        body: data,
                    }
                },
                invalidatesTags: [{type: 'Users'}, {type: 'Usage'}],
            }),
        }),
    }
)

export const {
    useLoginMutation,
    useGetCurrentUserQuery,
    useGetUsersListQuery,
    useChangePasswordMutation,
    useInviteUserMutation,
} = userApi
