import {baseApi} from "./apiService";

const defaultFileFilter = {
    page: 1,
    per_page: undefined,
    fileTypeId: undefined,
    year: undefined,
    sortField: undefined,
    sortDirection: undefined,
    search: undefined,
}

export const fileApi = baseApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getDownloadFile: builder.query({
            queryFn: async ({id, name}, api, extraOptions, baseQuery) => {
                const result = await baseQuery({
                    url: `documents/${id}/download`,
                    responseHandler: ((response) => response.blob())
                })
                if (result.error) {
                    return {error: {message: 'No file'}}
                }

                let hiddenElement = document.createElement('a');
                let url = window.URL || window.webkitURL;
                hiddenElement.href = url.createObjectURL(result.data);
                hiddenElement.target = '_blank';
                hiddenElement.download = name;
                hiddenElement.click();
                return {data: null}
            }
        }),
        getPreviewFile: builder.query({
            queryFn: async ({id, name}, api, extraOptions, baseQuery) => {
                const result = await baseQuery({
                    url: `documents/${id}/download`,
                    responseHandler: ((response) => response.blob())
                })
                if (result.error) {
                    return {error: {message: 'No file'}}
                }
                let url = window.URL || window.webkitURL;
                return {data: {url: url.createObjectURL(result.data), type: result.data.type}}
            }
        }),
        getFilesList: builder.query({
            query: (params) => ({
                url: 'documents',
                params: {
                    page: params?.page ?? defaultFileFilter.page,
                    per_page: params?.per_page ?? defaultFileFilter.per_page,
                    fileTypeId: params?.fileTypeId ?? defaultFileFilter.fileTypeId,
                    year: params?.year ?? defaultFileFilter.year,
                    sortField: params?.sortField ?? defaultFileFilter.sortField,
                    sortDirection: params?.sortDirection ?? defaultFileFilter.sortDirection,
                    search: params?.search ?? defaultFileFilter.search,
                },
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [...result.data.map(({id}) => ({type: 'Files', id})), 'Files']
                    : ['Files'],
        }),
        getFile: builder.query({
            query: (id) => ({
                url: `documents/${id}`,
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [{type: 'Files', id: result.id}, 'Files']
                    : ['Files'],
        }),
        uploadFile: builder.mutation({
            query: (body) => ({
                url: `documents/upload`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Files'],
        }),
        updateFileInfo: builder.mutation({
            query: ({id, ...body}) => ({
                url: `documents/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) =>
                result
                    ? ['Files', {type: 'Files', id: result.id}, {type: 'UploadHistoryFile', id: result.id}]
                    : ['Files'],
        }),
        deleteFile: builder.mutation({
            query: (id) => ({
                url: `documents/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Files', 'Tasks'],
        }),
    })
});

export const {
    useLazyGetDownloadFileQuery,
    useGetPreviewFileQuery,
    useLazyGetPreviewFileQuery,
    useGetFilesListQuery,
    useGetFileQuery,
    useLazyGetFileQuery,
    useUploadFileMutation,
    useUpdateFileInfoMutation,
    useDeleteFileMutation,
} = fileApi
