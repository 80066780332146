import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useGetBlogsQuery} from "../../services/blogService";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import {Box, Container, Grid, GridItem, Heading, HStack, LinkBox, LinkOverlay, Text} from "@chakra-ui/react";

const Blogs = () => {
    const {t} = useTranslation()
    const {data, isLoading} = useGetBlogsQuery()

    useEffect(() => {
        document.title = 'Quickg - Blog';
    });

    if (data === undefined || isLoading) {
        return <Box flexDirection={"column"}>
            <Container maxW={"container.xl"} pt={"10"} px={3}>
                <HStack mb={2}>
                    <Text color={"gray.600"}>Filetica / </Text>
                    <Heading size={"md"} color={"teal.400"}>
                        {t('blog.blog')}
                    </Heading>
                </HStack>
                <Heading>{t('common.actions.loading')}</Heading>
            </Container>
        </Box>
    }

    return (
        <Container maxW={"container.xl"} pt={"10"} px={3}>
            <HStack mb={2}>
                <Text color={"gray.600"}>Filetica / </Text>
                <Heading size={"md"} color={"teal.400"}>
                    {t('blog.blog')}
                </Heading>
            </HStack>
            <Grid templateColumns="repeat(1, 1fr)" columnGap={8} rowGap={4}>
                {data.map(blog =>
                    <GridItem>
                        <LinkBox key={blog.id} bg={"#fff"} as='article'  p='5' borderWidth='1px' rounded='md'>
                            <Box>
                                <Moment fromNow>{blog.created_at}</Moment>
                            </Box>
                            <Heading size='md' my='2'>
                                <LinkOverlay as={Link} to={`/blog/${blog.id}`}>
                                    {blog.title}
                                </LinkOverlay>
                            </Heading>
                            <Text>
                                {blog.description}
                            </Text>
                        </LinkBox>
                    </GridItem>
                )}
            </Grid>
        </Container>
    )
}

Blogs.propTypes = {};

export default Blogs;

