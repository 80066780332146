import {
    Box,
    Button,
    HStack,
    Spacer,
    Stack,
    AccordionPanel, AccordionItem, Accordion, Center,
} from "@chakra-ui/react";
import React, {useState} from "react";
import {date, object, string} from "yup";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useUpdateFileInfoMutation} from "../../services/fileService";
import {showFilePreviewModal} from "../../slices/filePreviewModalSlice";
import FileFields from "../Files/FileFields";
import {isNoAccessFile, transformFormValues} from "../../utils/generalHelper";
import moment from "moment";
import FileAccordionButton from "./FileAccordionButton";
import {UploadHistory} from "../../types";
import NoAccessFileAccordionButton from "./NoAccessFileAccordionButton";


export default function FileDetails({setStep, uploadHistory}: {
    setStep: (step: number) => void,
    uploadHistory: UploadHistory,
}) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [edit, {isLoading: isLoadingUpdate}] = useUpdateFileInfoMutation()
    const [submittedDetails, setSubmittedDetails] = useState<string[]>([]);
    const [index, setIndex] = useState<number | number[]>(0)

    const schema = object({
        name: string(),
        file_type: string(),
        file_created_at: date().nullable().transform((value, originalValue) => {
            return originalValue === '' ? null : value;
        }),
        internal_id: string(),
        external_id: string(),
        note: string(),
    });

    // if (isLoading || isFetching) {
    //     // todo if already fetched do not refresh full!
    //     return (
    //         <Box>
    //         </Box>
    //     )
    // }

    return (
        <Box>
            {/* Index is hack to open first */}
            <Accordion allowMultiple allowToggle index={index} onChange={setIndex}>
                {uploadHistory.files.map((file) =>
                    <AccordionItem bg={"#fff"} isDisabled={isNoAccessFile(file)} borderRadius={"lg"} mt={8}>
                        {isNoAccessFile(file)
                            ? <NoAccessFileAccordionButton file={file}/>
                            : <>
                                <FileAccordionButton file={file} submittedDetails={submittedDetails}/>
                                <AccordionPanel pb={4}>
                                    <Stack gap={4}>
                                        <Center mb={4} pe={8}>
                                            <Spacer/>
                                            <Button
                                                bg={"transparent"}
                                                color={"teal.400"}
                                                onClick={() => dispatch(showFilePreviewModal({
                                                    id: file.id,
                                                    name: file.document_name
                                                }))}
                                            >
                                                {t('common.actions.preview')}
                                            </Button>
                                        </Center>
                                    </Stack>
                                    <Formik
                                        validationSchema={schema}
                                        validateOnMount={true}
                                        enableReinitialize={true}
                                        onSubmit={(values, {setSubmitting, resetForm}) => {
                                            setSubmitting(true);
                                            edit(transformFormValues(values, ['file_created_at']))
                                            setSubmitting(false);
                                            setSubmittedDetails([...submittedDetails, file.id])
                                        }}
                                        initialValues={{
                                            id: file.id,
                                            name: file.name ?? '',
                                            file_type: file.file_type?.id ?? '',
                                            file_created_at: file.file_created_at !== null ? moment(file.file_created_at).format('YYYY-MM-DD') : '',
                                            internal_id: file.internal_id ?? '',
                                            external_id: file.external_id ?? '',
                                            note: file.note ?? '',
                                        }}>
                                        {({
                                              handleSubmit,
                                              handleChange,
                                              handleReset,
                                              handleBlur,
                                              values,
                                              dirty,
                                              touched,
                                              isValid,
                                              isSubmitting,
                                              errors,
                                              setFieldValue,
                                              setFieldTouched,
                                              setValues,
                                          }) => (<Form>
                                            <Box bg={"#fff"} p={8} borderRadius={10}>
                                                <FileFields
                                                    handleBlur={handleBlur}
                                                    touched={touched}
                                                    handleChange={handleChange}
                                                    values={values}
                                                    errors={errors}
                                                    setValues={setValues}
                                                />
                                            </Box>


                                            <Box p={8} bg={"#fff"} mt={8} borderRadius={"lg"}>
                                                <HStack>
                                                    <Spacer/>
                                                    <Button isLoading={isLoadingUpdate}
                                                            loadingText={t('upload.actions.uploading')}
                                                            type={'submit'}
                                                            variant={"primary"}>{t('common.actions.save')}</Button>
                                                </HStack>
                                            </Box>
                                        </Form>)}
                                    </Formik>
                                </AccordionPanel>
                            </>
                        }
                    </AccordionItem>
                )}
            </Accordion>
            <Box p={8} bg={"#fff"} mt={8} borderRadius={"lg"}>
                <HStack>
                    <Spacer/>
                    <Button isLoading={isLoadingUpdate}
                        // isDisabled={isLoading}
                            loadingText={t('upload.actions.uploading')}
                            onClick={() => setStep(3)}
                            variant={"primary"}>{t('common.actions.next')}</Button>
                </HStack>
            </Box>
        </Box>);
}
