import {baseApi} from "./apiService";
import {PaginatedLinkedFiles} from "../types";

export const linkedFilesService = baseApi.injectEndpoints({
        overrideExisting: false,
        endpoints: (builder) => ({
            getLinkedFilesForFileList: builder.query<PaginatedLinkedFiles,  {id: string, page?:number|void}>({
                query: ({id, page}) => ({
                    url: `documents/${id}/linked_files`,
                    params: {page: page},
                }),
                providesTags: (result: PaginatedLinkedFiles|undefined) =>
                    result
                        ? [...result.data.map(({id}) => ({type: 'LinkedFiles' as const, id})), 'LinkedFiles']
                        : ['LinkedFiles'],
            }),
            createLinkedFiles: builder.mutation({
                query(body) {
                    return {
                        url: `linked_files`,
                        method: 'POST',
                        body: body,
                    }
                },
                invalidatesTags: [{type: 'LinkedFiles'}],
                // invalidatesTags: [{type: 'LinkedFiles', id:body.linked_from}],
            }),
            createBulkLinkedFiles: builder.mutation({
                query(body) {
                    return {
                        url: `linked_files/bulk`,
                        method: 'POST',
                        body: body,
                    }
                },
                invalidatesTags: [{type: 'LinkedFiles'}, {type: 'UploadHistoryFile'}],
                // invalidatesTags: [{type: 'LinkedFiles', id:body.linked_from}],
            }),
            deleteLinkedFiles: builder.mutation({
                query: (id) => ({
                    url: `linked_files/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: [{type: 'LinkedFiles'}],
            }),
        }),
    }
)

export const {
    useCreateLinkedFilesMutation,
    useCreateBulkLinkedFilesMutation,
    useGetLinkedFilesForFileListQuery,
    useDeleteLinkedFilesMutation,
} = linkedFilesService
