import {baseApi} from "./apiService";

export const userCompanyApi = baseApi.injectEndpoints({
        overrideExisting: false,
        endpoints: (builder) => ({
            switchCompany: builder.mutation({
                query: (id) => ({
                    url: `users/switchCompany/${id}`,
                    method: 'GET',
                    invalidatesTags: ['CompanyDependent'],
                }),
            }),
        }),
    }
)

export const {
    useSwitchCompanyMutation,
} = userCompanyApi
