import React, {ReactElement} from "react";
import {Badge, Box, Flex, Heading, HStack, Spacer, Stack, Text} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";


interface CardProps {
    heading: string
    description: string
    icon: ReactElement
    iconBg: string
    isSoon?: boolean
    href: string
}

export const Card = ({heading, description, icon, iconBg, isSoon = false, href}: CardProps) => {
    const {t} = useTranslation()

    return (
        <Box
            maxW={{base: 'full', md: '275px'}}
            w={'full'}
            borderWidth="1px"
            borderRadius="xl"
            boxShadow={'lg'}
            // bg={'white'}
            rounded={'xl'}
            overflow="hidden"
            p={5}>
            <Stack align={'start'} spacing={2}>
                <HStack>
                    <Flex
                        w={16}
                        h={16}
                        align={'center'}
                        justify={'center'}
                        // color={'gray.100'}
                        rounded={'full'}
                        bg={iconBg}>
                        {icon}
                    </Flex>
                    <Spacer/>
                    {isSoon ? <Badge colorScheme={'teal'}>{t('common.soon')}</Badge> : null}
                </HStack>
                <Box mt={2}>
                    <Heading size="md">{heading}</Heading>
                    <Text mt={1} fontSize={'sm'}>
                        {description}
                    </Text>
                </Box>
                {/*<Button variant={'link'} colorScheme={'blue'} size={'sm'}>*/}
                {/*    Learn more*/}
                {/*</Button>*/}
            </Stack>
        </Box>
    )
}
