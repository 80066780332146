import {createSlice} from '@reduxjs/toolkit'
import {fileApi} from "../services/fileService";

const filePreviewModalSliceInitialState = {
    showModal: false,
    initialized: false,
    fileId: null,
    name: null,
};

const filePreviewModalSlice = createSlice({
    name: 'filePreviewModal',
    initialState: filePreviewModalSliceInitialState,
    reducers: {
        showFilePreviewModal: (state, action) => {
            state.showModal = true
            state.fileId = action.payload.id
            state.name = action.payload.name
        },
        closeFilePreviewModal: (state, action) => {
            return filePreviewModalSliceInitialState
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(fileApi.endpoints.getPreviewFile.matchFulfilled,
            (state, action) => {
                state.initialized = true
            }
        )
    },
})

export const {showFilePreviewModal, closeFilePreviewModal} = filePreviewModalSlice.actions

export const isFilePreviewOpen = (state) => state.filePreviewModal.showModal
export const selectFilePreviewModal = (state) => state.filePreviewModal

export default filePreviewModalSlice.reducer
