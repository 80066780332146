import React, {useState} from "react";
import {
    Box, Button, ButtonGroup,
    Center, Collapse, FormControl, FormErrorMessage, Grid, GridItem,
    Heading, HStack,
    Spacer, Text,
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {hasExtraCompany} from "../../utils/userRoleHelper";
import {useGetSubscriptionQuery} from "../../services/stripeSubscriptionService";
import {useGetUsageQuery} from "../../services/usageService";
import {object} from "yup";
import {IoMdAddCircleOutline} from "react-icons/io";
import {Form, Formik} from "formik";
import AppInput from "../AppInput";
import {AccountType} from "../../types";
import * as yup from "yup";
import {useCreateAgencyCompanyMutation} from "../../services/agencyService";

export default function InviteClient() {
    const {t, i18n} = useTranslation()
    const {data: subscription, isLoading: isLoadingSubscription} = useGetSubscriptionQuery(undefined);
    const {data: usage, isLoading: isLoadingUsage} = useGetUsageQuery(undefined)
    const [create, {isLoading}] = useCreateAgencyCompanyMutation()

    const [isOpen, setIsOpen] = useState(false);

    function handleCollapse() {
        setIsOpen((p) => !p);
    }

    const schema = object({
        name: yup.string().min(2).required(),
        legal_name: yup.string().min(2).required(),
        public_email: yup.string().email().required(),
    });

    if (isLoadingSubscription || isLoadingUsage) {
        return <Box p={8} bg={"#fff"} mt={4} borderRadius={"lg"}>
            <Center mb={2}>
                <HStack spacing={4}>
                    <Heading size={"sm"}>{t('agency.company.invite.heading')}</Heading>
                </HStack>
                <Spacer/>
                <Button
                    variant={"outline"}
                    w={"auto"}
                    isDisabled={true}
                    leftIcon={
                        <IoMdAddCircleOutline color="var(--chakra-colors-teal-400)"/>
                    }
                >
                    {t('agency.company.invite.invite')}
                </Button>
            </Center>
        </Box>
    }

    return (
        <Box p={8} bg={"#fff"} mt={4} borderRadius={"lg"}>
            <Center mb={2}>
                <HStack spacing={4}>
                    <Heading size={"sm"}>{t('agency.company.invite.heading')}</Heading>
                </HStack>
                <Spacer/>
                <Button
                    variant={"outline"}
                    w={"auto"}
                    isDisabled={!hasExtraCompany(subscription) && usage?.agency?.companies?.used >= usage?.agency?.companies?.available}
                    onClick={handleCollapse}
                    leftIcon={
                        <IoMdAddCircleOutline color="var(--chakra-colors-teal-400)"/>
                    }
                >
                    {t('agency.company.invite.invite')}
                </Button>
            </Center>
            <Text mb="4">
                {!hasExtraCompany(subscription) && usage?.agency?.companies?.used >= usage?.agency?.companies?.available
                    ? t('agency.company.invite.noSubscription')
                    : undefined}

            </Text>
            <Collapse in={isOpen} animateOpacity>
                <Formik
                    validationSchema={schema}
                    validateOnMount={true}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        create(values)
                        setSubmitting(false);
                        resetForm()
                        handleCollapse()
                    }}
                    // enableReinitialize={true}
                    initialValues={{
                        name: '',
                        legal_name: '',
                        public_email: '',
                        account_type: AccountType.agencyClient,
                        language: i18n.language,
                        // todo add company manager!
                    }}>
                    {({
                          handleSubmit,
                          handleChange,
                          handleReset,
                          handleBlur,
                          values,
                          dirty,
                          touched,
                          isValid,
                          isSubmitting,
                          errors,
                          setFieldValue,
                          setFieldTouched,
                      }) => (<Form>
                        <Grid pt={4} templateColumns="repeat(2, 1fr)" columnGap={8} rowGap={6}>
                            <GridItem>
                                <FormControl isInvalid={!!errors.name && touched.name}>
                                    <AppInput name={`name`} handleBlur={handleBlur} label={t('company.name')}
                                              placeholder={t('company.name', {count: 1})}/>
                                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl isInvalid={!!errors.legal_name && touched.legal_name}>
                                    <AppInput name={`legal_name`} handleBlur={handleBlur} label={t('company.legalName')}
                                              placeholder={t('company.legalName', {count: 1})}/>
                                    <FormErrorMessage>{errors.legal_name}</FormErrorMessage>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl isInvalid={!!errors.public_email && touched.public_email}>
                                    <AppInput name={`public_email`} handleBlur={handleBlur}
                                              label={t('company.publicEmail')}
                                              placeholder={t('company.publicEmail')}/>
                                    <FormErrorMessage>{errors.public_email}</FormErrorMessage>
                                </FormControl>
                            </GridItem>
                        </Grid>
                        <Center
                            mt={16}
                            sx={{
                                button: {
                                    minW: 160,
                                },
                            }}
                        >
                            <Spacer/>
                            <ButtonGroup gap={3}>
                                <Button
                                    isLoading={isLoading}
                                    loadingText={t('common.actions.saving')} type={'submit'}
                                    variant={"primary"}>{t('agency.company.invite.action')}</Button>
                            </ButtonGroup>
                        </Center>
                    </Form>)
                    }
                </Formik>
            </Collapse>
        </Box>
    );
}
