import {
    Box,
    Button,
    Container, Flex,
    Heading,
    Text,
} from "@chakra-ui/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {IoHandLeftOutline} from "react-icons/io5";

export default function AccessDenied() {
    const {t} = useTranslation()
    const navigate = useNavigate()

    return (
        <Container maxW={"container.xl"} p={4}>
            <Box textAlign="center" py={10} px={6}>
                <Box display="inline-block">
                    <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        bg={'red.500'}
                        rounded={'50px'}
                        w={'55px'}
                        h={'55px'}
                        textAlign="center">
                        <IoHandLeftOutline size={'30px'} color={'white'} />
                    </Flex>
                </Box>
                <Heading as="h2" size="xl" mt={6} mb={2}>
                    {t('accessDenied.title')}
                </Heading>
                <Text color={'gray.500'} mb={6}>
                    {t('accessDenied.text')}
                </Text>
                <Button
                    onClick={() => navigate('/')}
                    colorScheme="teal"
                    bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                    color="white"
                    variant="solid">
                    {t('notFound.action')}
                </Button>
            </Box>
        </Container>
    );
}
