import {Container, Heading, HStack, Progress, Text} from "@chakra-ui/react";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import FilesUpload from "../components/Upload/FilesUpload";
import FileDetails from "../components/Upload/FileDetails";
import FileTasks from "../components/Upload/FileTasks";
import {useGetCurrentUserQuery} from "../services/userService";
import {Role} from "../types";
import {AccessLimit} from "../utils/AccessLimit";
import FileLinkedFilesStep from "../components/Upload/FileLinkedFilesStep";
import {useGetUploadHistoryQuery} from "../services/uploadHistoryService";
import {useParams} from "react-router-dom";
import NotFound from "./NotFound";

export default function FileUpload() {
    const {t} = useTranslation()
    let {uploadHistoryParamId} = useParams();
    const [step, setStep] = useState(uploadHistoryParamId === undefined ? 1 : 2);
    const [uploadHistoryId, setUploadHistoryId] = useState<string | null>(uploadHistoryParamId ?? null);
    const {data: user} = useGetCurrentUserQuery(undefined)
    // TODO HACK !
    const {
        data: uploadHistory,
        isLoading
    } = useGetUploadHistoryQuery({id: uploadHistoryId!}, {skip: uploadHistoryId === null})

//todo add some setting is new/editing

    //In accordion
    //use state and map ID of file with if it is filled or not. (for now do not validate),
    // later also add orange if changed and not submited
    //later add warning that you forgot to submit one (maybe highlight by border or some).


    if ((step !== 1 && uploadHistoryId === null) || isLoading) {
        return (
            <Container maxW={"container.xl"} p={8}>
                <HStack mb={2}>
                    <Text color={"gray.600"}>{t('files.file', {count: 2})} / </Text>
                    <Heading size={"md"} color={"teal.400"}>
                        {t('nav.upload')} {step === 1
                        ? null
                        : step === 2
                            ? `/ ${t('upload.fileDetail', {count: 1})}`
                            : step === 3
                                ? `/ ${t('tasks.task', {count: 2})}`
                                : `/ ${t('linkedFiles.linked', {count: 2})}`
                    }
                    </Heading>
                </HStack>
            </Container>
        )
    }

    if (step !== 1 && !isLoading && uploadHistory === undefined) {
        return (<NotFound/>)
    }

    return (
        <Container maxW={"container.xl"} p={8}>
            <HStack mb={2}>
                <Text color={"gray.600"}>{t('files.file', {count: 2})} / </Text>
                <Heading size={"md"} color={"teal.400"}>
                    {t('nav.upload')} {step === 1
                    ? null
                    : step === 2
                        ? `/ ${t('upload.fileDetail', {count: 1})}`
                        : step === 3
                            ? `/ ${t('tasks.task', {count: 2})}`
                            : `/ ${t('linkedFiles.linked', {count: 2})}`
                }
                </Heading>
            </HStack>
            <AccessLimit allowedRoles={[Role.User]}>
                <>
                    {step === 1
                        ? <FilesUpload setStep={setStep} setUploadHistoryId={setUploadHistoryId} user={user}/>
                        : step === 2
                            ? <FileDetails setStep={setStep} uploadHistory={uploadHistory!}/>
                            : step === 3
                                ? <FileTasks setStep={setStep} uploadHistory={uploadHistory!}/>
                                : <FileLinkedFilesStep setStep={setStep} uploadHistory={uploadHistory!}/>
                    }
                    <Progress colorScheme={'teal'} value={step * 25 - 25}/>
                </>
            </AccessLimit>
            <AccessLimit allowedRoles={[Role.AgencyClient]} isStrictRole={true}>
                <>
                    <FilesUpload setStep={setStep} setUploadHistoryId={setUploadHistoryId} user={user}/>
                    <Progress colorScheme={'teal'} value={0}/>
                </>
            </AccessLimit>
        </Container>
    );
}
