import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {File} from "../../types";
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import {Dashboard} from '@uppy/react';
import Slovak from '@uppy/locales/lib/sk_SK';
import English from '@uppy/locales/lib/en_US';
import {URL} from "../../constants/API";
import {useAuth} from "../../hooks/useAuth";
import {selectAuth} from "../../slices/authSlice";
import {FileIdsContext} from "../Upload/FilesUpload";


export default function UppyFileUpload() {
    const isDevelopment = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    const {i18n} = useTranslation()
    const [uppy, setUppy] = useState<Uppy>();
    const uAuth = useAuth();
    const auth = selectAuth(uAuth);
    const {addFiles} = useContext(FileIdsContext);

    useEffect(() => {
        const uppyInstance = new Uppy({
            restrictions: {
                maxNumberOfFiles: 20,
            },
            autoProceed: true,
            debug: isDevelopment,
        }).use(XHRUpload, {
            endpoint: `${URL}/api/documents/uploadForm`,
            fieldName: 'files',
            limit: 1,
            headers: {
                'Authorization': `Bearer ${auth.token}`,
            }
        });

        uppyInstance.on('complete', (result) => {
            const uploadedFileIds = result.successful.flatMap(file => {
                const fileDataArray = file?.response?.body as unknown as File[];
                return fileDataArray.map(fileData => fileData.id);
            });

            addFiles(uploadedFileIds);
        });

        setUppy(uppyInstance);

        return () => uppyInstance.close();
    }, []);

    useEffect(() => {
        const localeMapping: Record<string, any> = {
            en: English,
            sk: Slovak,
        };

        const currentLocale = localeMapping[i18n.language] || English;
        uppy?.setOptions({
            locale: currentLocale,
        });
    }, [i18n.language, uppy]);

    if (!uppy) return null;

    return (
        <Dashboard
            uppy={uppy}
            width={'100%'}
            showProgressDetails={true}
            proudlyDisplayPoweredByUppy={false}
            hideCancelButton={true}
            hideProgressAfterFinish={true}
        />

    );
}
