import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store/store";
import {SortingState} from "@tanstack/react-table";
import {SortDirection} from "../types";

const linkedFileSortModalSliceInitialState = {
    showModal: false,
    //this one is needed due to tanstack table!
    sorting: [{id: 'createdAt', desc: true}],
    sortField:  'createdAt',
    sortDirection:  SortDirection.Desc,
};

type SetModalSortingAction = {
    payload: {
        sortField: string;
        sortDirection: SortDirection;
    };
    type: string;
};

const linkedFileSortModalSlice = createSlice({
    name: 'linkedFileSortModal',
    initialState: linkedFileSortModalSliceInitialState,
    reducers: {
        showLinkedFileSortModal: (state) => {
            state.showModal = true
        },
        closeLinkedFileSortModal: (state) => {
            state.showModal = false
        },
        clearLinkedFileSort: (state) => {
            state.sorting = linkedFileSortModalSliceInitialState.sorting
            state.sortField = linkedFileSortModalSliceInitialState.sortField
            state.sortDirection = linkedFileSortModalSliceInitialState.sortDirection
        },
        setSorting: (state, action: PayloadAction<SortingState | ((oldState: SortingState) => SortingState)>) => {
            const payload = action.payload;
            if (typeof payload === 'function') {
                state.sorting = payload(state.sorting);
                state.sortField = payload(state.sorting)[0]?.id
                state.sortDirection = typeof payload(state.sorting)[0]?.desc === "boolean"
                    ? payload(state.sorting)[0].desc
                        ? SortDirection.Desc
                        : SortDirection.Asc
                    : SortDirection.Desc
            } else {
                state.sorting = payload;
                state.sortField = payload[0]?.id
                state.sortDirection = typeof payload[0]?.desc === "boolean"
                    ? payload[0].desc
                        ? SortDirection.Desc
                        : SortDirection.Asc
                    : SortDirection.Desc
            }
        },
        setModalSorting: (state, action: SetModalSortingAction) => {
            state.sorting[0].id = action.payload.sortField;
            state.sorting[0].desc = action.payload.sortDirection === SortDirection.Desc ;
            state.sortField = action.payload.sortField;
            state.sortDirection = action.payload.sortDirection;
        }
    },
    extraReducers: (builder) => {
        // Needed to refresh linkedFile to see new task for example in modal
    },
})

export const {showLinkedFileSortModal, closeLinkedFileSortModal, clearLinkedFileSort, setSorting, setModalSorting} = linkedFileSortModalSlice.actions

export const isLinkedFileSortOpen = (state: RootState) => state.linkedFileSortModal.showModal

export const isLinkedFileSorted = (state: RootState) => state.linkedFileSortModal.sorting !== linkedFileSortModalSliceInitialState.sorting

export const selectLinkedFileSortModal = (state: RootState) => state.linkedFileSortModal
export const selectLinkedFileRawSorting = (state: RootState) => state.linkedFileSortModal.sorting

export default linkedFileSortModalSlice.reducer
