import {createSlice} from '@reduxjs/toolkit'

const taskViewModalSliceInitialState = {
    showModal: false,
    initialized: false,
    task: null,
};

const taskViewModalSlice = createSlice({
    name: 'taskViewModal',
    initialState: taskViewModalSliceInitialState,
    reducers: {
        showTaskViewModal: (state, action) => {
            state.showModal = true
            state.task = action.payload.task
        },
        closeTaskViewModal: () => {
            return taskViewModalSliceInitialState
        },
    },
    extraReducers: (builder) => {
        // Needed to refresh task to see new change
        // builder.addMatcher(taskApi.endpoints.getTasksList.matchFulfilled,
        //     (state, action) => {
        //         if (state.task !== null) {
        //             state.task = action.payload.find((task) => task.id === state.task.id)
        //         }
        //     }
        // )
        // builder.addMatcher(taskApi.endpoints.getPreviewTask.matchFulfilled,
        //     (state, action) => {
        //         state.initialized = true
        //     }
        // )
    },
})

export const {showTaskViewModal, closeTaskViewModal} = taskViewModalSlice.actions

export const isTaskViewOpen = (state) => state.taskViewModal.showModal
export const selectTaskViewModal = (state) => state.taskViewModal

export default taskViewModalSlice.reducer
