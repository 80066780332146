import React from "react";
import {Box, Container, Heading, HStack, ListItem, Text, UnorderedList, VStack} from "@chakra-ui/react";
import Seo from "../../components/Seo";
import {Link} from "react-router-dom";

const Privacy = () => {

    return (
        <Container maxW={"container.xl"} pt={"10"} px={3}>
            <Seo
                title={`Filetica - Privacy`}
                description={'Filetica privacy policy'}
                // ogType={'article'}
            />
            <HStack mb={2}>
                <Text color={"gray.600"}>Filetica / </Text>
                <Heading size={"md"} color={"teal.400"}>
                    Privacy policy
                </Heading>
            </HStack>
            <Box bg={"#fff"} p={8} borderRadius={10}>
                <VStack paddingTop="40px" spacing="4" alignItems="flex-start">
                    <Text>
                        Filetica is a platform that aims to simplify document management and make it easier
                        for users to organise and archive their files. At
                        Filetica, your privacy is important to us, and we want you to feel confident
                        that your personal information is secure when using our products and our platform.
                        The services are provided by Martive s.r.o. (parent company and
                        hereinafter referred to as “Filetica”), a Slovak company with a registered address at
                        Hlinská 2614/12, 010 01, Žilina, Žilinský kraj, Slovakia . It is Filetica’s policy
                        to respect your privacy regarding any information we may collect while operating our
                        website.
                    </Text>
                    <Heading size={'md'}>Website Visitors</Heading>
                    <Text>
                        Like most website operators, Filetica collects non-personally-identifying
                        information of the sort that web browsers and servers typically make available, such
                        as the browser type, language preference, referring site, and the date and time of
                        each visitor request. Filetica&apos;s purpose in collecting non-personally
                        identifying information is to better understand how Filetica&apos;s visitors use
                        its website. From time to time, Filetica may release
                        non-personally-identifying information in the aggregate, e.g., by publishing a
                        report on trends in the usage of its website.
                        Filetica also collects potentially personally-identifying information like
                        Internet Protocol (IP) addresses for logged-in users and for users making payments
                        on filetica.com. Filetica only discloses logged in user and commenter IP
                        addresses under the same circumstances that it uses and discloses
                        personally-identifying information as described below, except that payee IP
                        addresses and email addresses are visible and disclosed to the administrators of
                        Filetica and is handled by payment processors at the time of processing the payments.
                    </Text>
                    <Heading size={'md'}>Payments</Heading>
                    <Text>
                        When making a payment on Filetica, users are required to provide our trusted payment processor,
                        Stripe, with their payment details. In return, Stripe supplies us with a secure token
                        representing the user's account, along with the card's expiration date, card type, and the last
                        four digits of the credit card. Should users opt to share their name and email address with
                        Stripe, this information is also relayed to us. We gather and process data about the documents
                        or services users access, their frequency of usage, and any premium features or services they
                        might avail.
                    </Text>
                    <Heading size={'md'}>Gathering of Personally-Identifying Information</Heading>
                    <Text>
                        Filetica is committed to the utmost confidentiality and protection of user data. We disclose
                        potentially identifiable information solely to our dedicated team members, contractors, and
                        affiliated entities who:

                        Require this information to process on behalf of Filetica or to deliver the services offered on
                        our platform.
                        Have committed to maintaining the confidentiality of this information.
                        It's worth noting that some of these individuals or entities might be based outside your country
                        of residence. By utilizing Filetica, users consent to the transfer of their information to these
                        parties. Filetica stands firm in its policy of not renting or selling potentially identifiable
                        information to third parties. Exceptions are made only under legal obligations, such as in
                        response to legal processes or when we believe, in good faith, that such disclosure is essential
                        to safeguard Filetica's rights, the broader public, or third-party rights.

                        For our registered users who have provided their email addresses, Filetica might occasionally
                        reach out with updates, new features, or to gather feedback to enhance our offerings. Should
                        users communicate with us, for instance through email or feedback tools, we might use their
                        input to better our services or assist other users. Rest assured, Filetica employs rigorous
                        measures to guard against unauthorized access, alterations, or potential misuse of user
                        information. Visitors can
                        always refuse to supply personally-identifying information, with the caveat that it
                        may prevent them from engaging in certain website-related activities.
                    </Text>
                    <Heading size={'md'}>Aggregated Statistics</Heading>
                    <Text>
                        Filetica may collect statistics about the behavior of visitors to its
                        websites. Filetica may display this information publicly or provide it to
                        others. However, Filetica does not disclose personally-identifying
                        information other than as described below.
                    </Text>
                    <Heading size={'md'}>Protection of User Information</Heading>
                    <Text>
                        Protection of Certain Personally-Identifying Information
                        Filetica discloses potentially personally-identifying and
                        personally-identifying information only to those of its employees, contractors, and
                        affiliated organizations that (i) need to know that information in order to process
                        it on Filetica&apos;s behalf or to provide services available at Filetica&apos;s
                        websites, and (ii) that have agreed not to disclose it to others. Some of those
                        employees, contractors, and affiliated organizations may be located outside of your
                        home country; by using Filetica&apos;s websites, you consent to the transfer of
                        such information to them. Filetica will not rent or sell potentially
                        personally-identifying and personally-identifying information to anyone. Other than
                        to its employees, contractors and affiliated organizations, as described above,
                        Filetica discloses potentially personally-identifying and personally-identifying
                        information only in response to a subpoena, court order, or another governmental
                        request, or when Filetica believes in good faith that disclosure is
                        reasonably necessary to protect the property or rights of Filetica, third
                        parties or the public at large. If you are a registered user of a Filetica
                        website and have supplied your email address, Filetica may occasionally send
                        you an email to tell you about new features, solicit your feedback, or just keep you
                        up to date with what&apos;s going on with Filetica and our products. If you send
                        us a request (for example via email or via one of our feedback mechanisms), we
                        reserve the right to publish it in order to help us clarify or respond to your
                        request or to help us support other users. Filetica takes all measures
                        reasonably necessary to protect against the unauthorized access, use, alteration, or
                        destruction of potentially personally-identifying and personally-identifying
                        information.
                    </Text>
                    <Heading size={'md'}>Cookies</Heading>
                    <Text>
                        A cookie is a string of information that a website stores on a visitor&apos;s
                        computer,
                        and that the visitor&apos;s browser provides to the website each time the visitor
                        returns. For more information, visit All About Cookies.
                        Filetica uses cookies to help Filetica identify and track visitors,
                        their usage of Filetica website, and their website access preferences. Some
                        cookies expire after a certain amount of time, or upon logging out (session
                        cookies), others remain on your computer or terminal device for a longer period
                        (persistent cookies). Our Site uses first-party cookies (cookies set directly by
                        Anchor) as well as third-party cookies, as described below
                        Strictly Necessary Cookies: Used to provide Users with the Services available
                        through our Site and to use some of their features, such as the ability to log-in
                        and access to secure areas. These cookies are served by Anchor and are essential for
                        using and navigating the Site. Without these cookies, the basic functions of our
                        Site would not work. Because these cookies are strictly necessary to deliver the
                        Site and the Services, you cannot refuse them.
                        Analytics/Performance: Used to better understand the behavior of the Users on our
                        Site and improve our Site accordingly, for example by making sure users are finding
                        what they need easily. The Site uses Google Analytics, a web analytics service
                        provided by Google Inc. (“Google”). The information collected by Google (including
                        your IP address) will be transmitted to and stored by Google on servers in the
                        United States (Google is certified to the Privacy Shield for data transfers). How
                        long a Google Analytics cookie remains on your computer or device depends on what it
                        is and what it is used for. Some Google Analytics cookies expire at the end of your
                        browser session, whilst others can remain for up to two years. You can prevent your
                        data from being collected by Google Analytics on our Site by downloading and
                        installing the Google Analytics Opt-out Browser Add-on for your current web browser.
                        For more information on Google Analytics privacy practices, read here.
                        Filetica visitors who do not wish to have cookies placed on their computers
                        should set their browsers to refuse cookies before using Filetica&apos;s websites,
                        with the drawback that certain features of Filetica&apos;s websites may not
                        function properly without the aid of cookies.
                    </Text>
                    <Heading size={'md'}>Business Transfers</Heading>
                    <Text>
                        If Filetica, or substantially all of its assets, were acquired, or in the
                        unlikely event that Filetica goes out of business or enters bankruptcy, user
                        information would be one of the assets that is transferred or acquired by a third
                        party. You acknowledge that such transfers may occur, and that any acquirer of
                        Filetica may continue to use your personal information as outlined in this
                        policy.
                    </Text>
                    <Heading size={'md'}>Ads</Heading>
                    <Text>
                        We do not run any external advertisements on Filetica. However, we use ad
                        networks such as Twitter Ads to collect retargeting information to compile
                        information about our users. This Privacy Policy covers the use of cookies by Filetica
                        and does not cover the use of cookies by any advertisers.
                    </Text>
                    <Heading size={'md'}>International Users</Heading>
                    <Text>
                        Filetica is based in the Slovak republic. If you are accessing our Services
                        from the European Union or other regions with laws governing data collection and
                        use, please note that your Personal Data will be transmitted to our servers in the
                        European Union and the data may be transmitted to our service providers supporting
                        our business operations (described above). The European Union may have data
                        protection laws less stringent than or otherwise different from the laws in effect
                        in the country in which you are located. Where we transfer your Personal Data we
                        will take steps to ensure that your Personal Data receives an adequate level of
                        protection where it is processed and your rights continue to be protected (GDPR).
                        <Link to='/gdpr'> https://filetica.com/gdpr</Link>
                        By providing your information to the Services you agree to the transfer of your
                        information to the EU and processing globally in accordance with this Privacy
                        Policy.
                    </Text>
                    <Heading size={'md'}>Data Storage and Security</Heading>
                    <Text>
                        Filetica partners with Amazon S3 to ensure the secure storage of documents. We place the utmost
                        importance on the safety and integrity of your data. All documents stored on Amazon S3 benefit
                        from encryption both during transmission and while at rest. Our team is dedicated to
                        continuously updating and monitoring our security measures to safeguard against unauthorized
                        access, loss, or misuse of your information. However, in the ever-evolving realm of digital
                        security, absolute protection cannot be guaranteed. While we diligently work to shield your
                        data, unforeseen breaches or malicious attacks remain a possibility. Thus, we encourage users to
                        actively participate in their security by safeguarding their login credentials and updating
                        their passwords regularly.
                    </Text>
                    <Heading size={'md'}>User-Generated Content</Heading>
                    <Text>
                        When users upload files and documents to Filetica, we deeply respect the confidentiality of this
                        content. We do not scan, read, or process the content of your uploaded files beyond essential
                        security checks, such as malware detection, to safeguard the integrity of our platform and our
                        users. However, upon user request, we may scan and process files to extract data on their
                        behalf, ensuring that this data is stored exclusively for their use. While we prioritize the
                        confidentiality of user content and take every precaution, it's crucial for users to exercise
                        discretion when sharing, especially with sensitive or personal information. Despite our best
                        efforts, we cannot be held liable for any unauthorized access or breaches of user-generated
                        content.
                    </Text>
                    <Heading size={'md'}>Rights of Users</Heading>
                    <Text>
                        In compliance with the General Data Protection Regulation (GDPR) and other data protection laws,
                        users have the right to:
                    </Text>
                    <VStack spacing="4" alignItems="flex-start">
                        <UnorderedList>
                            <ListItem>Access their personal data.</ListItem>
                            <ListItem>Rectify inaccurate or incomplete data.</ListItem>
                            <ListItem>Obtain a copy of their data for portability purposes.</ListItem>
                            <ListItem>Object to the processing of their data in certain circumstances.</ListItem>
                        </UnorderedList>
                    </VStack>
                    <Text>Please note that while many of these rights are available to all users, some specific features
                        or services associated with these rights may be part of our premium offerings and could incur
                        additional costs. If you wish to exercise any of these rights or inquire about potential
                        associated fees, please contact us at info@filetica.com.</Text>
                    <Heading size={'md'}>Data Retention</Heading>
                    <Text>
                        Filetica retains user data for as long as necessary to provide our services, comply with legal
                        obligations, resolve disputes, and enforce our agreements. However, users are encouraged to
                        maintain backups of their essential data. We are not responsible for any loss of data due to
                        unforeseen circumstances. After this period, we either delete or anonymize your data. Specific
                        retention periods vary based on the type of data and its use.
                    </Text>
                    <Heading size={'md'}>Third-Party Services</Heading>
                    <Text>
                        Filetica integrates with select third-party services to enhance the user experience. Notably, we
                        utilize Google services such as Recaptcha and Analytics. The data shared with Google is strictly
                        limited to what's essential for these services' functionality. By using Filetica, users consent
                        to share non-personal data with these third-party services. We prioritize not sharing sensitive
                        personal information. However, for a comprehensive understanding of data practices, users are
                        advised to refer to the privacy policies of these third-party providers, especially regarding
                        potential data breaches or privacy violations beyond Filetica's control.
                    </Text>
                    <Heading size={'md'}>Children's Privacy</Heading>
                    <Text>
                        Filetica is not designed for use by children under the age of 13. We do not knowingly collect or
                        solicit personal information from minors. If we discover that we have inadvertently gathered
                        personal data from a child under 13, we will take appropriate measures to delete this information as
                        soon as possible after discovering the account.
                    </Text>
                    <Heading size={'md'}>Opt-Out Options</Heading>
                    <Text>
                        Users who wish to opt-out of our data collection practices, especially concerning cookies and
                        analytics, can adjust their browser settings to refuse cookies or notify them when a cookie is
                        being sent. Additionally, users can opt-out of Google Analytics data collection by installing
                        the Google Analytics Opt-out Browser Add-on. However, it's important to note that doing so may
                        impact the functionality of Filetica, potentially leading to limited usage or certain features
                        not working as intended.
                    </Text>
                    <Heading size={'md'}>Data Breach Procedures</Heading>
                    <Text>
                        In the unlikely event of a data breach, Filetica will promptly notify affected users and
                        relevant authorities within 72 hours of discovering the breach. While Filetica will make every
                        effort to prevent damages or losses resulting from such breaches, outcomes may be influenced by
                        external factors beyond our control. We will transparently communicate details regarding the
                        nature of the breach, the affected data, potential consequences, and the measures being taken to
                        address and mitigate the situation.
                    </Text>

                    <Heading size={'md'}>General Disclaimer</Heading>
                    <Text>
                        Filetica strives to offer reliable and efficient services to its users. We invest in ensuring
                        the functionality, security, and availability of our platform. However, given the complexities
                        of online platforms and the inherent vulnerabilities of internet-based services, there are
                        certain risks associated with using Filetica.
                    </Text>
                    <Text>
                        While we take measures to safeguard against potential breaches and ensure the continuous
                        operation of our services, we cannot guarantee uninterrupted service or absolute security. The
                        nature of online data storage and transmission means that there are inherent risks, and despite
                        our best efforts, unforeseen issues, breaches, or malicious attacks might occur.
                    </Text>
                    <Text>
                        Therefore, Filetica provides its services "as is" without any express or implied warranties.
                        Users are advised to use our platform with an understanding of these risks and are responsible
                        for taking appropriate measures, such as maintaining their own backups.
                    </Text>
                    <Text>
                        We are not liable for any direct, indirect, incidental, or consequential damages resulting from
                        the use or inability to use our services, even if we've been advised of the possibility of such
                        damages. This includes, but is not limited to, damages from potential breaches, data loss, or
                        service interruptions.
                    </Text>
                    <Text>
                        By using Filetica, users acknowledge and accept the inherent risks of online data storage and
                        the limitations of our liability as outlined in this disclaimer.
                    </Text>


                    Service Level Agreement (SLA): Detail the security measures and standards users can expect



            Data Storage and Security:
                DONE - Privacy Policy: Mention the use of Amazon S3 for storage and the encryption methods employed.
                DONE - Disclaimer: Highlight that while Filetica uses Amazon S3 and has security measures in place, absolute security cannot be guaranteed.
                    Service Level Agreement (SLA): Detail the security measures and standards users can expect.
            External Accounts:
                    External Accounts Policy: Detail the creation, management, and security measures related to external accounts with read-only access.
            Usesr-Generated Content:
                    Terms of Service (ToS): Outline the responsibilities and guidelines for users when uploading content.
                    Acceptable Use Policy: Define what constitutes acceptable content and the repercussions for violations.
            Rights of Users:
                DONE -  Privacy Policy: Detail users' rights concerning their data.
                    Data Processing Agreement (DPA): Explain how user data is processed, especially for EU users.
            Data Retention:
                DONE -   Privacy Policy: Describe how long user data is retained and the reasons for retention.
                    Data Processing Agreement (DPA): Detail the data retention periods in compliance with GDPR.
            Third-Party Services:
                DONE - Privacy Policy: Mention the integration with third-party services and what data is shared.
                    Cookie Policy: If third-party services use cookies, detail the types and purposes of these cookies.
            Opt-Out Options:
                DONE - Privacy Policy: Explain the data collection practices and how users can opt out.
                    Cookie Policy: Detail how users can opt out of cookie tracking.
            Data Breach Procedures:
                DONE - Privacy Policy: Outline the procedures in place for potential data breaches and the communication methods used to inform affected users.
                    Service Level Agreement (SLA): Mention the response times and actions taken in the event of a breach.
            Clarifications and Communication:
                    Terms of Service (ToS): Direct users to the support team for any clarifications or feedback.
                DONE - Privacy Policy: Provide contact details for users to reach out with questions about data practices.

            General Assurance and Limited Liability:
                DONE - Disclaimer: Emphasize the platform's limited liability in the face of potential breaches and the inherent risks of online data storage.
                    Terms of Service (ToS): Highlight the collaborative nature of online security and the shared responsibility between Filetica and its users.

                    <Heading size={'md'}>Privacy Policy Changes</Heading>
                    <Text>
                        Although most changes are likely to be minor, Filetica may change its Privacy
                        Policy from time to time, and in Filetica&apos;s sole discretion. Filetica
                        encourages visitors to frequently check this page for any changes to its Privacy
                        Policy. If you have a filetica.com account, you might also receive an alert
                        informing you of these changes. Your continued use of this site after any change in
                        this Privacy Policy will constitute your acceptance of such change.
                    </Text>
                    <Heading size={'md'}>Other Terms and Conditions</Heading>
                    <Text>
                        Your access to and use of the Filetica is subject to any additional terms
                        applicable to such Services that may be posted on the Terms from time to time,
                        including without limitation, Filetica’s Terms of Use available at
                        <Link to='/terms'> https://filetica.com/term</Link>
                        <br/>
                        By agreeing to our privacy policy you are also agreeing to the Google&apos;s privacy
                        policy for the services which we use like Google Sign In, Recaptcha, Analytics, etc.
                        which can be found here: https://policies.google.com/privacy?hl=en-US
                    </Text>
                    <Heading size={'md'}>Contact us if you have Privacy policy related questions</Heading>
                    <Text>
                        Please feel free to contact us if you have any questions about Filetica’s
                        Privacy Policy or the information practices.
                        You may contact us as follows:
                    </Text>
                    <VStack spacing="4" alignItems="flex-start">
                        <UnorderedList>
                            <ListItem>Website: <Link to=''>https://filetica.com</Link></ListItem>
                            <ListItem>Email: <a href="mailto:info@filetica.com?Subject=Privacy"
                                                target="_top">info@filetica.com</a></ListItem>
                        </UnorderedList>
                    </VStack>
                    {/*Phone: Phone Number*/}
                </VStack>
            </Box>
        </Container>
    )
}

export default Privacy;

