import {
    Box,
    Button,
    Container, Flex,
    Heading, List, ListIcon, ListItem,
    Text, VStack,
} from "@chakra-ui/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {IoHandLeftOutline, IoHelpCircleOutline} from "react-icons/io5";

export default function InvalidSubscription() {
    const {t} = useTranslation()
    const navigate = useNavigate()

    return (
        <Container maxW={"container.xl"} p={4}>
            <Box textAlign="center" py={10} px={6}>
                <Box display="inline-block">
                    <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        bg={'red.500'}
                        rounded={'50px'}
                        w={'55px'}
                        h={'55px'}
                        textAlign="center">
                        <IoHandLeftOutline size={'30px'} color={'white'} />
                    </Flex>
                </Box>
                <Heading as="h2" size="xl" mt={6} mb={2}>
                    {t('subscriptions.invalid.title')}
                </Heading>
                <Text color={'gray.500'} mb={6}>
                    {t('subscriptions.invalid.message')}
                </Text>
                <Heading mt={6}>{t('subscriptions.invalid.nextSteps.intro')}</Heading>
                <VStack
                    py={4}
                    mb={2}
                >
                    <List spacing={3} textAlign="start" px={12}>
                        <ListItem>
                            <ListIcon as={IoHelpCircleOutline} color='teal.400'/>
                            <strong>{t('subscriptions.invalid.nextSteps.step1.title')}</strong> {t('subscriptions.invalid.nextSteps.step1.content')}
                        </ListItem>
                        <ListItem>
                            <ListIcon as={IoHelpCircleOutline} color='teal.400'/>
                            <strong>{t('subscriptions.invalid.nextSteps.step2.title')}</strong> {t('subscriptions.invalid.nextSteps.step2.content')}
                        </ListItem>
                        <ListItem>
                            <ListIcon as={IoHelpCircleOutline} color='teal.400'/>
                            <strong>{t('subscriptions.invalid.nextSteps.step3.title')}</strong> {t('subscriptions.invalid.nextSteps.step3.content')}
                        </ListItem>
                    </List>
                </VStack>

                <Button
                    onClick={() => navigate('/')}
                    colorScheme="teal"
                    bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                    color="white"
                    variant="solid">
                    {t('notFound.action')}
                </Button>
            </Box>
        </Container>
    );
}
