import React from "react";
import {useGetBlogsQuery} from "../../services/blogService";
import RenderBlog from "../../components/Blog/RenderBlog";
import {useParams} from "react-router-dom";
// import Seo from "../../components/Seo";
import {Box, Container, Heading, HStack, Text} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import Seo from "../../components/Seo";

const ViewBlog = () => {
    const {t} = useTranslation()
    let {blogId} = useParams();

    const {blog, isLoading} = useGetBlogsQuery(undefined, {
        selectFromResult: ({data}) => ({
            blog: data?.find((blog) => {
                return blog.id === blogId
            }),
        }),
    })


    if (blog === undefined || isLoading) {
        return <Box flexDirection={"column"}>
            <Container maxW={"container.xl"} pt={"10"} px={3}>
                <HStack mb={2}>
                    <Text color={"gray.600"}>Filetica / </Text>
                    <Heading size={"md"} color={"teal.400"}>
                        {t('blog.blog')}
                    </Heading>
                </HStack>
                <Heading>{t('common.actions.loading')}</Heading>
            </Container>
        </Box>
    }

    if (blog === null && !isLoading) {
        return <Box flexDirection={"column"}>
            <Container maxW={"container.xl"} pt={"10"} px={3}>
                <HStack mb={2}>
                    <Text color={"gray.600"}>Filetica / </Text>
                    <Heading size={"md"} color={"teal.400"}>
                        {t('blog.blog')}
                    </Heading>
                </HStack>
                <Heading>{t('common.actions.notFound')}</Heading>
            </Container>
        </Box>
    }

    return (
        <Container maxW={"container.xl"} pt={"10"} px={3}>
            <Seo
                title={`Filetica - ${blog.title}`}
                description={blog.description}
                ogType={'article'}
            />
            <HStack mb={2}>
                <Text color={"gray.600"}>Filetica / {t('blog.blog')} / </Text>
                <Heading size={"md"} color={"teal.400"}>
                    {blog.title}
                </Heading>
            </HStack>
            <Box bg={"#fff"} p={8} borderRadius={10}>
                <RenderBlog post={blog.post}/>
            </Box>
        </Container>
    )
}

export default ViewBlog;

