import React from "react";
import {
    Button,
    Center,
    FormControl,
    FormErrorMessage,
    Grid,
    GridItem,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spacer,
} from "@chakra-ui/react";
import {
    clearFileFilter,
    closeFileFilterModal,
    isFileFilterOpen,
    selectFileFilterModal, setFileFilter
} from "../../slices/fileFilterModalSlice";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import AppSelect from "../AppSelect";
import {useGetFileTypesListQuery} from "../../services/fileTypeService";
import {Form, Formik} from "formik";
import {object, string} from "yup";

export default function FilesFilterModal() {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const isModalOpen = useSelector(isFileFilterOpen)
    const fileFilterModal = useSelector(selectFileFilterModal)
    const {data: fileTypes} = useGetFileTypesListQuery()
    const schema = object({
        file_type: string(),
    });

    return (
        <Modal isOpen={isModalOpen} onClose={() => dispatch(closeFileFilterModal())}>
            <ModalOverlay/>
            <ModalContent borderRadius={"2xl"} minW={700}>
                <Formik
                    validationSchema={schema}
                    validateOnMount={true}
                    enableReinitialize={true}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        // todo extract!!!!
                        dispatch(setFileFilter({
                            ...Object.keys(values).reduce((item, key) => ({
                                ...item, [key]: values[key] === '' ? null : values[key],
                            }), {})
                        }))
                        dispatch(closeFileFilterModal())
                        setSubmitting(false);
                    }}
                    initialValues={{
                        file_type: fileFilterModal.fileType ?? '',
                    }}>
                    {({
                          handleSubmit,
                          handleChange,
                          handleReset,
                          handleBlur,
                          values,
                          dirty,
                          touched,
                          isValid,
                          isSubmitting,
                          errors
                      }) => (<Form>
                        <ModalHeader fontWeight={700} fontSize={22}>
                            {t('filter.filter')}
                        </ModalHeader>
                        <ModalBody mb={6}>
                            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                <GridItem>
                                    <FormControl isInvalid={!!errors.file_type && touched.file_type}>
                                        <AppSelect
                                            name='file_type'
                                            onChange={handleChange}
                                            value={values.file_type}
                                            handleBlur={handleBlur}
                                            label={'files.fileType'}
                                        >
                                            <option value="">{t('upload.fileTypeUndefined')}</option>
                                            {fileTypes?.map((fileType, i) =>
                                                <option key={i} value={fileType?.id}>{fileType.name}</option>
                                            )}
                                        </AppSelect>
                                        <FormErrorMessage>{errors.file_type}</FormErrorMessage>
                                    </FormControl>
                                </GridItem>
                            </Grid>
                        </ModalBody>

                        <Center gap={4} p={8}>
                            <Button
                                variant={"outline"}
                                color={"teal.400"}
                                borderColor={"teal.400"}
                                onClick={() => {
                                    dispatch(clearFileFilter())
                                    dispatch(closeFileFilterModal())
                                }}
                            >
                                {t('common.actions.clear_filter')}
                            </Button>
                            <Spacer/>
                            <Button type={'button'} variant={"gray"} onClick={() => dispatch(closeFileFilterModal())}>
                                {t('common.actions.cancel')}
                            </Button>
                            <Button type={'submit'} variant={"primary"}>{t('common.actions.apply')}
                            </Button>
                        </Center>
                    </Form>)}
                </Formik>
            </ModalContent>
        </Modal>
    );
}
