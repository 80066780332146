import {store} from "../store/store";
import {isRejectedWithValue} from "@reduxjs/toolkit";
import {accessDeniedToast} from "../slices/toastSlice";

export const rtkQueryNotifications = (api) => (next) => (action) => {

    if (!['api/executeQuery/rejected', 'api/executeMutation/rejected'].includes(action.type)) {
        return next(action)
    }

    if (isRejectedWithValue(action)) {
        console.log(action.payload.originalStatus)
        if (action.payload.originalStatus === 403) {
            store.dispatch(accessDeniedToast())
        }
        // //todo add array of actions if they should be excluded from 5XX errors
        // if (![401, 404].includes(action.payload.originalStatus)) {
        //     store.dispatch(errorAlert());
        // }
    }

    return next(action)
}
