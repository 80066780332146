import {baseApi} from "./apiService";
import {Company} from "../types";

export const agencyService = baseApi.injectEndpoints({
        overrideExisting: false,
        endpoints: (builder) => ({
            getAgencyCompaniesList: builder.query<Company[],number|void>({
                query: (page) => ({
                    url: 'agencies',
                    // params: {page: page},
                }),
                providesTags: (result: Company[]|undefined) =>
                    result
                        ? [...result.map(({id}) => ({type: 'AgencyClients' as const, id})), 'AgencyClients']
                        : ['AgencyClients'],
            }),
            createAgencyCompany: builder.mutation({
                query(body) {
                    return {
                        url: `agencies`,
                        method: 'POST',
                        body: body,
                    }
                },
                invalidatesTags: [{type: 'AgencyClients'}, {type: 'CurrentUser'}, {type:'CompanyDependent'}, {type:'Usage'}],
            }),
        }),
    }
)

export const {
    useGetAgencyCompaniesListQuery,
    useCreateAgencyCompanyMutation,
} = agencyService
