import {
    Badge,
    Box,
    Button,
    Container, Flex,
    Heading, ListItem, OrderedList,
    Text, VStack,
} from "@chakra-ui/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {IoCheckmark} from "react-icons/io5";

export default function SuccessfulSubscription(
    {
        setSuccess,
    }: {
        setSuccess: (value: boolean) => void
    }) {
    const {t} = useTranslation()

    const clearSuccess = () => {
        setSuccess(false)
    };

    return (
        <Container maxW={"container.xl"} p={4}>
            <Box textAlign="center" py={10} px={6}>
                <Box display="inline-block">
                    <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        bg={'green.500'}
                        rounded={'50px'}
                        w={'55px'}
                        h={'55px'}
                        textAlign="center">
                        <IoCheckmark size={'30px'} color={'white'}/>
                    </Flex>
                </Box>
                <Heading as="h2" size="xl" mt={6} mb={2}>
                    {t('subscriptions.success.title')}
                </Heading>
                <Text color={'gray.500'} mb={6}>
                    {t('subscriptions.success.message')}
                </Text>
                <Heading mt={6}>{t('subscriptions.success.nextSteps.intro')}</Heading>
                <VStack
                    py={4}
                    mb={2}
                >
                    <OrderedList spacing={3} textAlign="start" px={12}>
                        <ListItem>
                            <strong>{t('subscriptions.success.nextSteps.step1.title')}</strong> {t('subscriptions.success.nextSteps.step1.content')}
                            <Badge colorScheme='teal' ms={2}>{t('common.done')}!</Badge>

                        </ListItem>
                        <ListItem>
                            <strong>{t('subscriptions.success.nextSteps.step2.title')}</strong> {t('subscriptions.success.nextSteps.step2.content')}
                        </ListItem>
                        <ListItem>
                            <strong>{t('subscriptions.success.nextSteps.step3.title')}</strong> {t('subscriptions.success.nextSteps.step3.content')}
                        </ListItem>
                        <ListItem>
                            <strong>{t('subscriptions.success.nextSteps.step4.title')}</strong> {t('subscriptions.success.nextSteps.step4.content')}
                        </ListItem>
                    </OrderedList>
                </VStack>
                <Button
                    onClick={() => clearSuccess()}
                    colorScheme="teal"
                    bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                    color="white"
                    variant="solid">
                    {t('subscriptions.success.buttonText')}
                </Button>
            </Box>
        </Container>
    );
}
