import {
    Button,
    Heading,
    Stack,
    Text, Td, Flex, FormControl, FormErrorMessage, GridItem, Grid, Box, Divider,
} from "@chakra-ui/react";
import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import {IoMdAddCircleOutline} from "react-icons/io";
import {FieldArray, FieldArrayRenderProps, FormikErrors, FormikHandlers, FormikTouched} from "formik";
import {useGetFilesListQuery} from "../../services/fileService";
import {useTranslation} from "react-i18next";
import FilesTable from "../../components/Files/FilesTable";
import SearchSlideButton from "../../components/SearchSlideButton";
import {
    Pagination,
    PaginationContainer, PaginationNext, PaginationPage,
    PaginationPageGroup,
    PaginationPrevious,
    PaginationSeparator,
    usePagination
} from "@ajna/pagination";
import {IoCheckmarkCircle, IoChevronBackCircleOutline, IoChevronForwardCircleOutline} from "react-icons/io5";
import TableLoader from "../../pages/Table/TableLoader";
import AppInput from "../AppInput";
import {LinkedFile, LinkedFileForm} from "../../types";


interface Task {
    name: string;
    predefined_task: string; // Assuming UUIDs are represented as strings
    note: string;
    assignee_id: string; // Assuming UUIDs are represented as strings
    escalation_date: Date;
}

interface FileUploadFormValues {
    document_name: string;
    document: any;
    name?: string;
    file_type?: string;
    file_created_at: string | null;
    linked_files: LinkedFile[];
    internal_id?: string;
    external_id?: string;
    note?: string;
    tasks: Task[];
}

export default function FileUploadLinkFiles(
    {
        linkedFiles, existingConnections, handleBlur, errors, touched
    }: {
        linkedFiles: LinkedFileForm[],
        existingConnections: string[],
        handleBlur: FormikHandlers['handleBlur'],
        errors: FormikErrors<FileUploadFormValues>,
        touched: FormikTouched<FileUploadFormValues>
    }
) {
    const {t} = useTranslation()
    const [search, setSearch] = useState<string | null>(null);
    const [dataTotal, setDataTotal] = useState(0);
    const arrayHelpersRef: MutableRefObject<FieldArrayRenderProps | null> = useRef(null);

    const {
        pages,
        pagesCount,
        currentPage,
        setCurrentPage,
        isDisabled,
    } = usePagination({
        total: dataTotal,
        limits: {
            outer: 3,
            inner: 3
        },
        initialState: {
            pageSize: 5,
            isDisabled: false,
            currentPage: 1
        }
    });

    const handlePageChange = (nextPage: number) => {
        setCurrentPage(nextPage);
    };

    const {
        data: files = {data: [], total: 0, per_page: 5},
        isLoading: isLoadingFiles,
        isFetching: isFetchingFiles,
    } = useGetFilesListQuery({
        page: currentPage,
        per_page: 5,
        // fileTypeId: fileFilterModal.fileType,
        // sortField: fileSortModal.sortField,
        // sortDirection: fileSortModal.sortDirection,
        search: search,
    })

    useEffect(() => {
        setCurrentPage(1)
    }, [search, setCurrentPage])

    useEffect(() => {
        setDataTotal(files.total)
    }, [files.total, setDataTotal])

    return (
        <Stack gap={4} mt={8} bg={"#fff"} borderRadius={10}>
            <Heading size={"sm"}>{t('linkedFiles.linked', {count: 2})}</Heading>
            <FieldArray
                name="linked_files"
                render={(arrayHelpers) => {
                    arrayHelpersRef.current = arrayHelpers;

                    return (
                        <Box mx={"3"} px={2}>
                            {linkedFiles.length === 0
                                ? <>
                                    <Text textAlign="center" py={5}>{t('linkedFiles.noNewLinked')}</Text>
                                    <Divider/>
                                </>
                                : linkedFiles.map((linkedFile, index) => (
                                    <>
                                        <Grid key={index} templateColumns="repeat(6, 1fr)" columnGap={8} rowGap={4}
                                              my={5}>
                                            <GridItem display="flex" alignItems="end" colSpan={2}>
                                                <Stack>
                                                    <Text fontSize={'xs'}>File name</Text>
                                                    <Heading size="md" color={"teal.400"}> {linkedFile?.name}</Heading>
                                                </Stack>
                                            </GridItem>
                                            <GridItem key={index} colSpan={3}>
                                                <FormControl isInvalid={
                                                    !!errors?.linked_files &&
                                                    typeof errors.linked_files[index] !== 'string' &&
                                                    !!((errors.linked_files[index] as FormikErrors<LinkedFile>)?.note) &&
                                                    !!touched?.linked_files &&
                                                    !!touched.linked_files[index]?.note
                                                }>
                                                    <AppInput
                                                        name={`linked_files[${index}].note`}
                                                        handleBlur={handleBlur}
                                                        label={t('common.note', {count: 2})}
                                                        placeholder={t('common.note', {count: 2})}
                                                    />
                                                    <FormErrorMessage>
                                                        {errors?.linked_files && typeof errors.linked_files[index] !== 'string' && (errors.linked_files[index] as FormikErrors<LinkedFile>)?.note}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            </GridItem>
                                            <GridItem display="flex" alignItems="end" justifyContent={'end'}
                                                      colSpan={1}>
                                                <Button variant={"gray"} onClick={() => arrayHelpers.remove(index)}>
                                                    {t('common.actions.delete')}
                                                </Button>
                                            </GridItem>
                                        </Grid>
                                        <Divider/>
                                    </>

                                ))}
                        </Box>
                    )
                }}/>
            <SearchSlideButton search={search} setSearch={setSearch}/>
            {search
                ? <Text fontSize={'xs'}>Files - {search} ({files.total})</Text>
                : <Text fontSize={'xs'}>Recent files</Text>
            }
            {isLoadingFiles || isFetchingFiles
                ? <TableLoader
                    isLoading={true}
                    columns={[
                        'common.ID',
                        'files.fileName',
                        'common.createdBy',
                        'common.createdAt',
                        'files.uploadedAt',
                        null]}
                />
                : <FilesTable
                    files={files.data}
                    hiddenFields={['fileCreatedAt', 'tasks']}
                    customActionArgs={linkedFiles}
                    customAction={(info, ...linkedFiles) => {
                        return (<Td px={0}
                                    bg={info.row.index % 2 === 0 ? "blackAlpha.100 !important" : "whiteAlpha.100 !important"}>
                            <Flex justifyContent={"space-around"}>
                                {linkedFiles.some(file => file.linked_to === info.row.original.id) || existingConnections.includes(info.row.original.id)
                                    ? <Button
                                        height={'25px'}
                                        pe={0}
                                        me={4}
                                        colorScheme={'white'}
                                    >
                                        <IoCheckmarkCircle color="var(--chakra-colors-teal-500)"/>
                                    </Button>
                                    : <Button
                                        height={'25px'}
                                        pe={0}
                                        me={4}
                                        colorScheme={'white'}
                                        // isLoading={isLoadingTrigger}
                                        // loadingText='Downloading'
                                        onClick={() => arrayHelpersRef.current?.push({
                                                linked_to: info.row.original.id,
                                                name: info.row.original.name ?? info.row.original.original_name,
                                                note: '',
                                            }
                                        )}
                                    >
                                        <IoMdAddCircleOutline color="var(--chakra-colors-teal-400)"/>
                                    </Button>}
                            </Flex>
                        </Td>)
                    }}
                />
            }

            <Pagination
                pagesCount={pagesCount}
                currentPage={currentPage}
                isDisabled={isDisabled}
                onPageChange={handlePageChange}
            >
                <PaginationContainer
                    align="center"
                    justify="space-between"
                    p={4}
                    w="full"
                >
                    <PaginationPrevious
                        _hover={{
                            bg: "teal.500"
                        }}
                        bg="teal.400"
                    >
                        <IoChevronBackCircleOutline/>
                    </PaginationPrevious>
                    <PaginationPageGroup
                        isInline
                        align="center"
                        separator={
                            <PaginationSeparator
                                bg="teal.300"
                                fontSize="sm"
                                w={7}
                                jumpSize={11}
                            />
                        }
                    >
                        {pages.map((page) => (
                            <PaginationPage
                                w={7}
                                bg="teal.400"
                                key={`pagination_page_${page}`}
                                page={page}
                                fontSize="sm"
                                _hover={{
                                    bg: "teal.500"
                                }}
                                _current={{
                                    bg: "teal.500",
                                    fontSize: "sm",
                                    color: "white",
                                    w: 7
                                }}
                            />
                        ))}
                    </PaginationPageGroup>
                    <PaginationNext
                        _hover={{
                            bg: "teal.500"
                        }}
                        bg="teal.400"
                    >
                        <IoChevronForwardCircleOutline/>
                    </PaginationNext>
                </PaginationContainer>
            </Pagination>
        </Stack>
    );
}
