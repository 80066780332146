import React, {useState} from "react";
import {
    Button,
    ButtonGroup,
    Center,
    Heading,
    Spacer,
    Box, Collapse, HStack,
} from "@chakra-ui/react";
import {IoMdAddCircleOutline} from "react-icons/io";
import {Form, Formik} from "formik";
import {date, object, string} from "yup";
import {useTranslation} from "react-i18next";
import {useCreateTaskMutation} from "../../services/taskService";
import PropTypes from "prop-types";
import TaskCreateFields from "./TaskCreateFields";
import {useGetPredefinedTasksListQuery} from "../../services/predefinedTaskService";
import moment from "moment";

export default function TaskCreateSingleModal({fileId}) {
    const {t} = useTranslation()
    const [add, {isLoading}] = useCreateTaskMutation()
    const [isOpen, setIsOpen] = useState(false);
    const {data: predefinedTasks} = useGetPredefinedTasksListQuery()

    function handleCollapse() {
        setIsOpen((p) => !p);
    }

    const schema = object({
        name: string().min(2).required(),
        note: string(),
        predefined_task: string().uuid(),
        // creator_id: '',
        assignee_id: string().uuid().required(),
        file_id: string().uuid().required(),
        escalation_date: date().required(),
        // status: 'id',
    });

    return (
        <Box py={4} bg="white" rounded={"md"}>
            <HStack>
                <Heading size={"sm"}>{t('tasks.createTask.title')}</Heading>
                <Spacer/>
                <Button
                    variant={"outline"}
                    w={"auto"}
                    onClick={handleCollapse}
                    leftIcon={
                        <IoMdAddCircleOutline color="var(--chakra-colors-teal-400)"/>
                    }
                >
                    {t('tasks.createTask.button')}
                </Button>
            </HStack>

            <Collapse in={isOpen} animateOpacity>
                <Formik
                    validationSchema={schema}
                    validateOnMount={true}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        // todo extract!!!!!!
                        // add({...values})
                        add({
                            ...Object.keys(values).reduce(
                                (item, key) => ({
                                    ...item,
                                    [key]: values[key] === '' ? null : values[key],
                                }),
                                {}
                            ),
                            escalation_date: moment(values.escalation_date).format('YYYY-MM-DD')
                        })
                        setSubmitting(false);
                        resetForm()
                        handleCollapse()
                    }}
                    // enableReinitialize={true}
                    initialValues={{
                        name: '',
                        note: '',
                        predefined_task: '',
                        assignee_id: '',
                        file_id: fileId,
                        escalation_date: moment().format('YYYY-MM-DD')
                        // status: 'id',
                    }}>
                    {({
                          handleSubmit,
                          handleChange,
                          handleReset,
                          handleBlur,
                          values,
                          dirty,
                          touched,
                          isValid,
                          isSubmitting,
                          errors,
                          setFieldValue,
                          setFieldTouched,
                      }) => (<Form>
                        <TaskCreateFields
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            assigneeValue={values.assignee_id}
                            noteValue={values.note}
                            predefinedTaskValue={values.predefined_task}
                            predefinedTasks={predefinedTasks}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                        />
                        <Center
                            mt={16}
                            sx={{
                                button: {
                                    minW: 160,
                                },
                            }}
                        >
                            <Spacer/>
                            <ButtonGroup gap={3}>
                                {/*<Button*/}
                                {/*    color={"red.400"}*/}
                                {/*    bg="red.50"*/}
                                {/*    variant={"unstyled"}*/}
                                {/*    _hover={{bg: "red.100"}}*/}
                                {/*>*/}
                                {/*    Delete*/}
                                {/*</Button>*/}
                                <Button
                                    isLoading={isLoading}
                                    loadingText={t('common.actions.saving')} type={'submit'}
                                    variant={"primary"}>{t('common.actions.save')}</Button>
                            </ButtonGroup>
                        </Center>
                    </Form>)}
                </Formik>
            </Collapse>
        </Box>
    );
}

TaskCreateSingleModal.propTypes = {
    fileId: PropTypes.string.isRequired,
};
