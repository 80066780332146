import React from "react";
import {
    Box,
    Button,
    Center,
    Container,
    HStack,
    Heading,
    Spacer,
    Stack,
    Text,
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {useDeleteFileMutation, useGetFileQuery, useUpdateFileInfoMutation} from "../services/fileService";
import {useNavigate, useParams} from "react-router-dom";
import TasksTable from "../components/Tasks/TasksTable";
import {showFilePreviewModal} from "../slices/filePreviewModalSlice";
import {useDispatch} from "react-redux";
import {date, object, string} from "yup";
import moment from "moment";
import {Form, Formik} from "formik";
import FileFields from "../components/Files/FileFields";
import DeleteDialog from "../components/DeleteDialog";
import {RoleAccess} from "../utils/RoleAccess";
import {Role} from "../constants/roles";
import LinkedFilesTable from "../components/LinkedFiles/LinkedFilesTable";
import {transformFormValues, transformLinkedFilesToUndirected} from "../utils/generalHelper";
import {useGetLinkedFilesForFileListQuery} from "../services/linkedFilesService";

export default function FilesEdit() {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    let {fileId} = useParams();
    const navigate = useNavigate();
    const {
        data: linkedFiles,
        isLoading: isLoadingLinkedFiles
    } = useGetLinkedFilesForFileListQuery({id: fileId})
    const [edit, {isLoading: isLoadingUpdate}] = useUpdateFileInfoMutation()
    const [deleteFile, {isLoading: isLoadingDelete}] = useDeleteFileMutation()

    const {data: file, isLoading} = useGetFileQuery(fileId)

    const schema = object({
        name: string(),
        file_type: string(),
        file_created_at: date().nullable().transform((value, originalValue) => {
            return originalValue === '' ? null : value;
        }),
        internal_id: string(),
        external_id: string(),
        note: string(),
    });

    if (isLoading || isLoadingLinkedFiles) {
        return (
            <Container maxW={"container.xl"} p={4}>
                <HStack>
                    <Text color={"gray.600"}>{t('files.file', {count: 2})} / -- / </Text>
                    <Heading size={"md"} color={"teal.400"}>
                        {t('common.actions.edit')}
                    </Heading>
                </HStack>
                <Heading>{t('common.actions.loading')}</Heading>
            </Container>
        )
    }

    if (file === undefined || file === null) {
        return (
            <Container maxW={"container.xl"} p={4}>
                <HStack>
                    <Text color={"gray.600"}>{t('files.file', {count: 2})} / -- / </Text>
                    <Heading size={"md"} color={"teal.400"}>
                        {t('common.actions.edit')}
                    </Heading>
                </HStack>
                <Heading>{t('common.actions.loading')} - Not FOUND!</Heading>
            </Container>
        )
    }

    return (
        <Container maxW={"container.xl"} p={4}>
            <HStack>
                <Text color={"gray.600"}>{t('files.file', {count: 2})} / {file.name ?? file.original_name} / </Text>
                <Heading size={"md"} color={"teal.400"}>
                    {t('common.actions.edit')}
                </Heading>
            </HStack>
            <Formik
                validationSchema={schema}
                validateOnMount={true}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    setSubmitting(true);
                    edit(transformFormValues(values, ['file_created_at']))
                    setSubmitting(false);
                    resetForm()
                }}
                initialValues={{
                    id: file.id,
                    name: file.name ?? '',
                    file_type: file.file_type?.id ?? '',
                    file_created_at: file.file_created_at !== null ? moment(file.file_created_at).format('YYYY-MM-DD') : '',
                    internal_id: file.internal_id ?? '',
                    external_id: file.external_id ?? '',
                    note: file.note ?? '',
                }}>
                {({
                      handleSubmit,
                      handleChange,
                      handleReset,
                      handleBlur,
                      values,
                      dirty,
                      touched,
                      isValid,
                      isSubmitting,
                      errors
                  }) => (<Form>
                    <Box p={8} bg={"#fff"} mt={4} borderRadius={"lg"}>
                        <Center>
                            <Spacer/>
                            <Button variant={"ghost"} color={"teal.400"} onClick={() => dispatch(showFilePreviewModal({
                                id: file.id,
                                name: file.document_name
                            }))}>
                                {t('common.actions.preview')}
                            </Button>
                        </Center>
                        <FileFields handleBlur={handleBlur} touched={touched} handleChange={handleChange}
                                    values={values} errors={errors}/>

                        <HStack pt={10}>
                            <Spacer/>
                            <Button isLoading={isLoading || isLoadingUpdate}
                                    loadingText={t('upload.actions.uploading')}
                                    type={'submit'} variant={"primary"}>{t('common.actions.save')}</Button>
                        </HStack>
                    </Box>
                </Form>)}
            </Formik>

            <Box p={8} bg={"#fff"} mt={4} borderRadius={"lg"}>
                <Stack>
                    <Heading size={"sm"}>{t('tasks.task', {count: 2})}</Heading>
                    <TasksTable tasks={file?.tasks} inModal={true}/>
                </Stack>
            </Box>

            <Box p={8} bg={"#fff"} mt={4} borderRadius={"lg"}>
                <Stack>
                    <Heading size={"sm"}>{t('linkedFiles.linked', {count: 2})}</Heading>
                    {file?.id && <LinkedFilesTable
                        linkedFiles={transformLinkedFilesToUndirected(linkedFiles, file.id)}
                        enableUnlink={true}
                    />}
                </Stack>
            </Box>
            <RoleAccess allowedRoles={[Role.Manager]}>
                <Box p={8} bg={"#fff"} mt={4} borderRadius={"lg"}>
                    <HStack>
                        <Spacer/>
                        <DeleteDialog
                            actionLabel={'files.delete'}
                            deleteAction={() => {
                                deleteFile(file.id)
                                navigate('/files')
                            }
                            }/>
                    </HStack>
                </Box>
            </RoleAccess>
        </Container>
    );
}
