import {baseApi} from "./apiService";

export const subscriptionApi = baseApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getSubscription: builder.query({
            query: () => ({
                url: 'subscriptions',
                method: 'GET',
                providesTags: ['Subscription'],
            }),
        }),
        createCheckoutSession: builder.mutation({
            query: (body) => ({
                url: `subscriptions/checkout`,
                method: 'POST',
                body,
            }),
            // transformResponse: (response) => JSON.parse(response).url,
            invalidatesTags: ['Subscription'],
        }),
        createCustomerSession: builder.mutation({
            query: (body) => ({
                url: `subscriptions/customerPortal`,
                method: 'POST',
                body,
            }),
            // transformResponse: (response) => JSON.parse(response).url,
            invalidatesTags: ['Subscription'],
        }),
        // getEmailSubscribers: builder.query({
        //     query: () => ({
        //         url: `subscribe`,
        //         method: 'GET',
        //     }),
        // }),
    })
});

export const {useGetSubscriptionQuery, useCreateCheckoutSessionMutation, useCreateCustomerSessionMutation} = subscriptionApi
