import React from "react";
import {
    Center, Stack,
    Td, Text,
    Tooltip
} from "@chakra-ui/react";
import {BsFillFileEarmarkXFill,} from "react-icons/bs";
import Moment from "react-moment";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {isFilled} from "../../utils/generalHelper";
import {CellTypes} from "../../types";

export default function FilesTableCell({
                                           content,
                                           subContent,
                                           placeholder = '',
                                           shorten = false,
                                           maxLength = 10,
                                           isContentFilled = true,
                                           type = null,
                                       }) {
    const {t, i18n} = useTranslation();

    const shortenCombinedId = () => {
        if (isFilled(content) && isFilled(subContent)) {
            return <Text>
                {`${content.length > Math.round((maxLength) / 2 + 2)
                    ? content.substring(0, Math.round((maxLength) / 2)) + '...'
                    : content
                } / ${subContent.length > Math.round((maxLength) / 2 + 2)
                    ? subContent.substring(0, Math.round((maxLength) / 2)) + '...'
                    : subContent
                }`}
            </Text>
        }
        if (isFilled(content) && !isFilled(subContent)) {
            return <Text>
                {`${content.length > Math.round((maxLength) / 2 + 2)
                    ? content.substring(0, Math.round((maxLength) / 2)) + '...'
                    : content
                } / - `}
            </Text>
        }
        if (!isFilled(content) && isFilled(subContent)) {
            return <Text>
                {` - / ${subContent.length > Math.round((maxLength) / 2 + 2)
                    ? subContent.substring(0, Math.round((maxLength) / 2)) + '...'
                    : subContent
                }`}
            </Text>
        }
    }


    const cell = () => {
        switch (type) {
            case CellTypes.Regular:
                return shorten && content.length > maxLength
                    ? <Tooltip hasArrow label={content}>
                        {content.substring(0, maxLength) + '...'}
                    </Tooltip>
                    : content
            case CellTypes.Date: {
                return isFilled(content) ?
                    <Moment format={'MMM D, YYYY'} locale={i18n.language}>{content}</Moment> : null;
            }
            case CellTypes.CombinedIds: {
                return isContentFilled
                    ? <Tooltip hasArrow label={<Stack>
                        <Text>{`${t('files.view.internalID')}: ${content ?? ''}`}</Text>
                        <Text>{`${t('files.view.externalID')}: ${subContent ?? ''}`}</Text>
                    </Stack>}>
                        <Text>{shortenCombinedId()}</Text>
                    </Tooltip>
                    : placeholder.length > maxLength ? <Tooltip hasArrow label={placeholder}>
                            <Text>{placeholder.substring(0, maxLength) + '...'}</Text>
                        </Tooltip>
                        : {placeholder}
            }
            case CellTypes.Name: {
                return isContentFilled
                    ? shorten ? <Tooltip hasArrow label={`${content ?? ''} ${subContent ?? ''}`}>
                            <Text>{content}</Text>
                        </Tooltip>
                        : `${content ?? ''} ${subContent ?? ''}`
                    : null
            }
            default:
                return shorten && content.length > maxLength
                    ? <Tooltip hasArrow label={content}>
                        {content.substring(0, maxLength) + '...'}
                    </Tooltip>
                    : content
        }
    };

    return (
        <Td>
            <Center gap={2}>
                {!isContentFilled
                    ? <BsFillFileEarmarkXFill
                        color={'var(--chakra-colors-red-400)'}
                        fontSize={20}
                        // color="var(--chakra-colors-teal-400)"
                    />
                    : null
                }
                {cell()}
            </Center>
        </Td>
    );
}

FilesTableCell.propTypes = {
    content: PropTypes.string,
    subContent: PropTypes.string,
    placeholder: PropTypes.string,
    shorten: PropTypes.bool,
    maxLength: PropTypes.number,
    isContentFilled: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(CellTypes)),
};
