import React, {useState} from "react";
import {
    Box, Button, ButtonGroup,
    Center, Collapse, FormControl, FormErrorMessage, Grid, GridItem,
    Heading, HStack,
    Spacer, Text,
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {hasSubscription} from "../../utils/userRoleHelper";
import {useGetSubscriptionQuery} from "../../services/stripeSubscriptionService";
import {useGetUsageQuery} from "../../services/usageService";
import {object, string} from "yup";
import {IoMdAddCircleOutline} from "react-icons/io";
import {Form, Formik} from "formik";
import AppInput from "../AppInput";
import AppSelect from "../AppSelect";
import {Role} from "../../types";
import {useInviteUserMutation} from "../../services/userService";
import {isAgencyClient} from "../../utils/generalHelper";

export default function InviteUser({user}: { user: any }) {
    const {t, i18n} = useTranslation()
    const {data: subscription, isLoading: isLoadingSubscription} = useGetSubscriptionQuery(undefined);
    const {data: usage, isLoading: isLoadingUsage} = useGetUsageQuery(undefined)
    const [invite, {isLoading}] = useInviteUserMutation()

    const [isOpen, setIsOpen] = useState(false);

    function handleCollapse() {
        setIsOpen((p) => !p);
    }

    const schema = object({
        firstName: string().min(2).required(),
        lastName: string().min(2).required(),
        email: string().email().required(),
        role: string().oneOf([Role.AgencyClient, Role.User, Role.Manager]).required(),
    });

    if (isLoadingSubscription || isLoadingUsage) {
        return <Box p={8} bg={"#fff"} mt={4} borderRadius={"lg"}>
            <Center mb={2}>
                <HStack spacing={4}>
                    <Heading size={"sm"}>{t('users.invite.heading')}</Heading>
                </HStack>
                <Spacer/>
                <Button
                    variant={"outline"}
                    w={"auto"}
                    isDisabled={true}
                    leftIcon={
                        <IoMdAddCircleOutline color="var(--chakra-colors-teal-400)"/>
                    }
                >
                    {t('users.invite.invite')}
                </Button>
            </Center>
        </Box>
    }

    return (
        <Box p={8} bg={"#fff"} mt={4} borderRadius={"lg"}>
            <Center mb={2}>
                <HStack spacing={4}>
                    <Heading size={"sm"}>{t('users.invite.heading')}</Heading>
                </HStack>
                <Spacer/>
                <Button
                    variant={"outline"}
                    w={"auto"}
                    isDisabled={(!hasSubscription(subscription) || usage?.seat?.used >= usage?.seat?.available) && !isAgencyClient(user?.company)}
                    onClick={handleCollapse}
                    leftIcon={
                        <IoMdAddCircleOutline color="var(--chakra-colors-teal-400)"/>
                    }
                >
                    {t('users.invite.invite')}
                </Button>
            </Center>
            <Text mb="4">
                {(!hasSubscription(subscription) || usage?.seat?.used >= usage?.seat?.available) && !isAgencyClient(user?.company) ? t('users.invite.noSeats') : undefined}
            </Text>
            <Collapse in={isOpen} animateOpacity>
                <Formik
                    validationSchema={schema}
                    validateOnMount={true}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        invite(values)
                        setSubmitting(false);
                        resetForm()
                        handleCollapse()
                    }}
                    // enableReinitialize={true}
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                        role: isAgencyClient(user?.company) ? Role.AgencyClient : Role.User,
                        language: i18n.language,
                    }}>
                    {({
                          handleSubmit,
                          handleChange,
                          handleReset,
                          handleBlur,
                          values,
                          dirty,
                          touched,
                          isValid,
                          isSubmitting,
                          errors,
                          setFieldValue,
                          setFieldTouched,
                      }) => (<Form>
                        <Grid pt={4} templateColumns="repeat(2, 1fr)" columnGap={8} rowGap={6}>
                            <GridItem>
                                <FormControl isInvalid={!!errors.firstName && touched.firstName}>
                                    <AppInput name={`firstName`} handleBlur={handleBlur} label={t('users.firstName')}
                                              placeholder={t('users.firstName', {count: 1})}/>
                                    <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl isInvalid={!!errors.lastName && touched.lastName}>
                                    <AppInput name={`lastName`} handleBlur={handleBlur} label={t('users.lastName')}
                                              placeholder={t('users.lastName', {count: 1})}/>
                                    <FormErrorMessage>{errors.lastName}</FormErrorMessage>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl isInvalid={!!errors.email && touched.email}>
                                    <AppInput name={`email`} handleBlur={handleBlur} label={t('users.email')}
                                              placeholder={t('users.email')}/>
                                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl isInvalid={!!errors.role && touched.role}>
                                    <AppSelect name={`role`}
                                               onChange={handleChange}
                                               handleBlur={handleBlur}
                                               value={values.role}
                                               label={'users.role'}
                                        // placeholder={t('common.select')}
                                    >
                                        {isAgencyClient(user?.company)
                                            ? <option value={Role.AgencyClient}>{t(`users.roles.${Role.AgencyClient}`)}</option>
                                            : <>
                                                <option value={Role.User}>{t(`users.roles.${Role.User}`)}</option>
                                                <option value={Role.Manager}>{t(`users.roles.${Role.Manager}`)}</option>
                                            </>
                                        }
                                    </AppSelect>
                                    <FormErrorMessage>{errors.role}</FormErrorMessage>
                                </FormControl>
                            </GridItem>
                        </Grid>
                        <Center
                            mt={16}
                            sx={{
                                button: {
                                    minW: 160,
                                },
                            }}
                        >
                            <Spacer/>
                            <ButtonGroup gap={3}>
                                <Button
                                    isLoading={isLoading}
                                    loadingText={t('common.actions.saving')} type={'submit'}
                                    variant={"primary"}>{t('users.invite.action')}</Button>
                            </ButtonGroup>
                        </Center>
                    </Form>)
                    }
                </Formik>
            </Collapse>
        </Box>
    );
}
