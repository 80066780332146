import React from "react";

function Choose(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            // width="1466.667"
            // height="1066.667"
            version="1.1"
            viewBox="0 0 1466.667 1066.667"
            xmlSpace="preserve"
            {...props}
        >
            f
            <g transform="matrix(1.33333 0 0 -1.33333 0 1066.667)">
                <g
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    transform="scale(.1)"
                >
                    {/*<path fill="#b5d6ca" d="M0 0h11000v8000H0z"></path>*/}
                    <path
                        fill="#c2ddd3"
                        d="M6790.25 596.582c-1018.99 0-2037.95 12.969-3056.73 31.109-379.22 6.75-758.68 18.059-1138.11 26.098-222.04 4.703-444.06 8.27-666.01 9.16-3.07.012-6.15.02-9.24.02-75.92 0-154.38-3.68-233.99-7.348-79.62-3.672-160.43-7.351-240.95-7.351-189.92 0-378.42 20.429-547.286 109.468-550.239 290.172 157.746 573.122 19.66 895.032l-.133.31c-61.059 141.94-313.027 158.98-425.191 194.43-51.715 16.35-106.372 34.8-142.567 82.11-192.203 251.18 219.371 518.13 375.215 553.39 167.746 37.95 343.422 46 519.202 46 64.25 0 128.48-1.07 192.39-2.16v-33.78H7009.4v58.31c648.2-13.9 1295.31-37.5 1941.15-74.56 110.72-6.36 221.58-7.81 332.46-7.81 46.63 0 93.26.26 139.9.51 46.59.26 93.18.52 139.76.52 162.63 0 325.15-3.13 487.33-20.26 179.8-19.02 365-51.78 526-129.71 59.5-28.75 162.7-73.59 176.2-138.77 14.5-69.98-62.6-94.77-68.9-157.67-4.3-42.55 52.2-103 58.1-151.65 12.3-102.41-44.5-195.13-109.6-274.66-122.2-149.14-45.9-275.2-104.5-459.29-21.9-68.47-27.3-144.608-57.7-208.178C10299.9 574.98 8118.75 603 7556.89 599.148c-255.54-1.75-511.09-2.566-766.64-2.566"
                    ></path>
                    <path
                        fill="#474747"
                        d="M7805.27 4590.6l-19.41-696.87-171.74-1175.07h-132.05l-83.41 1084.25-156.33 923.57 562.94-135.88"
                    ></path>
                    <path
                        fill="#426362"
                        d="M7482.82 2662.98l142.81-71.4 71.4 71.4-71.4 71.4h-142.81v-71.4"
                    ></path>
                    <path
                        fill="#474747"
                        d="M6929.59 4740.38l-2.6-527.26 163.8-1559.16h132.05l146.18 1035.28 117.93 1133.21-557.36-82.07"
                    ></path>
                    <path
                        fill="#426362"
                        d="M7222.08 2598.27l-142.8-71.4-71.4 71.4 71.4 71.41h142.8v-71.41"
                    ></path>
                    <path
                        fill="#3ababa"
                        d="M7604.69 6311.78l-442.78 49.01s-197.75-29.39-353.11-573.13c-155.35-543.74-116.47-1650-116.47-1650s1118.21 159.59 1294.12 194.12l-122.94 1002.94-258.82 977.06"
                    ></path>
                    <path
                        fill="#b97262"
                        d="M6826.83 7250.9l63.4 143.34 55.25-21.86-24.25-106.86 31.55 3.35-67.45-116.68-60.89 13.97 2.39 84.74"
                    ></path>
                    <path
                        fill="#3ababa"
                        d="M7176.72 6359.15l-389.84 137.62 132.83 687.66-122.65 20.84-382.27-796.18 581.87-468.43 180.06 418.49"
                    ></path>
                    <path
                        fill="#b97262"
                        d="M7546.66 6827.1c-100.87 33.33-204.98 64.6-310.39 54.75-23.09-2.16-48.17-7.54-62.45-25.81-5.53-7.08-8.95-15.56-12.15-23.95-36.01-94.23-83.81-234.48-29.18-330.31 10.42-18.27 25.37-33.46 41.81-46.24 21.96-17.07 46.92-29.71 73.26-37.78a411.595 411.595 0 00-30.33-68c51.21-7.19 102.41-14.37 153.61-21.56 4.18 29.43 8.35 58.87 12.52 88.3 13.97 4.04 27.61 9.3 40.6 16.07 50.28 26.19 86.14 70.08 109.57 120.89 25.5 55.33 58.71 107.01 82.63 163.62 8.72 20.62 16.37 44.39 7.14 64.79-9.82 21.72-34.85 31.33-57.46 38.86-6.39 2.13-12.77 4.25-19.18 6.37"
                    ></path>
                    <path
                        fill="#1a3151"
                        d="M7197.5 6806.92c54.37 27.57 132.33 33.85 179.94 25.45 143.92-25.37 186.11-137.3 180.19-271.41 45.14 46.67 126.66 102.84 137.89 167.71 9.19 53.09 9.27 126.76-15.95 176.02-23.6 46.08-92.85 77.17-132.91 106.58-37.75 27.73-75.52 55.45-113.28 83.17-7.85 5.77-15.93 11.65-25.3 14.29-11.1 3.11-22.9 1.41-34.29-.33-97.49-14.8-194.98-29.61-292.46-44.41-18.73-2.84-40.23-7.6-48.75-24.51-5.19-10.3-4.01-22.5-2.71-33.95 5.73-50.41 21.26-102.92 21.26-153.39 2.83-29.61 4.87-180.09 58.01-168.2 17.62 23.12 22.99 53.27 37.45 78.48 10.69 18.63 28.86 33.32 50.91 44.5"
                    ></path>
                    <path fill="#fff" d="M1436.51 2493.07H7009.4v3573.39H1436.51z"></path>
                    <path
                        fill="#145566"
                        d="M7009.4 6130.59H1436.51v214.4H7009.4v-214.4"
                    ></path>
                    <path
                        fill="#9ea6ad"
                        d="M1436.51 5849.15H7009.4v281.441H1436.51z"
                    ></path>
                    <path
                        fill="#bec3c8"
                        d="M2013.53 5907.15h4739.4v120.152h-4739.4z"
                    ></path>
                    <path
                        fill="#ebb044"
                        d="M1669.78 6246.68c0-19.48-15.79-35.27-35.27-35.27-19.48 0-35.27 15.79-35.27 35.27 0 19.48 15.79 35.27 35.27 35.27 19.48 0 35.27-15.79 35.27-35.27"
                    ></path>
                    <path
                        fill="#ebb044"
                        d="M1773.07 6246.68c0-19.48-15.79-35.27-35.27-35.27-19.48 0-35.27 15.79-35.27 35.27 0 19.48 15.79 35.27 35.27 35.27 19.48 0 35.27-15.79 35.27-35.27"
                    ></path>
                    <path
                        fill="#ebb044"
                        d="M1876.36 6246.68c0-19.48-15.79-35.27-35.27-35.27-19.47 0-35.26 15.79-35.26 35.27 0 19.48 15.79 35.27 35.26 35.27 19.48 0 35.27-15.79 35.27-35.27"
                    ></path>
                    <path
                        fill="#bdc4ca"
                        d="M1821.01 5909.58v115.29l41.04-57.64-41.04-57.65"
                    ></path>
                    <path
                        fill="#bdc4ca"
                        d="M1654.59 5909.58l-41.04 57.65 41.04 57.64v-115.29"
                    ></path>
                    <path
                        fill="#b97262"
                        d="M8405.12 4571.83l-72.05 37.48-70.21-19.49-11.88 11.65 15 14.27c-49.04-4.94-107.5 20.72-120.71 72.04-5.22 20.28-2.05 65.24 19.13 77.44 23.43 13.5 89.42-14.27 117.37-18.56l62.12-47.65 101.05-47.33-39.82-79.85"
                    ></path>
                    <path
                        fill="#145566"
                        d="M8373.19 4699.31l674.43-132.41 268.94 286.36 225.15-119.55-293.21-517.88-954.32 355.02 79.01 128.46"
                    ></path>
                    <path
                        fill="#145566"
                        d="M9999.14 1445.64l9.76-74.95-152.03-93.03-90.67 65.9 71.14 84 152.04 93.03 9.76-74.95"
                    ></path>
                    <path
                        fill="#fff"
                        d="M9958.67 3736.47l29.64-227.62-199.14-1022.97 215.33-1015.14-156.64-47.67-533.85 1202.14-35.43 369.74 53.44 768.12 626.65-26.6"
                    ></path>
                    <path
                        fill="#fff"
                        d="M8957.85 3648.84l-12.98-963.57 211.57-1242.37h162.67l102.74 1146.36 222.6 1146.35-686.6-86.77"
                    ></path>
                    <path
                        fill="#145566"
                        d="M9318.17 1384.02l-175.91-75.48-87.96 75.48 87.96 75.49h175.91v-75.49"
                    ></path>
                    <path
                        fill="#145566"
                        d="M10143 3342.67c2.3-104.25-.9-244.74-40.3-249.89-7.4-.96-14.4 2.75-20.9 6.33-68 37.81-116.17 91.03-183.25 127.56-70.3 38.28-150.72 58.63-229.59 72.87-117.93 21.93-238.48 35.54-358.64 37.68-85.77 1.51-185.72 4.79-269.79-13.34-123.74-26.69-74.02-29.67-145.45-133.74-36.76 13.56-73.52 27.13-110.28 40.69-17.64 6.51 56.08 667.28 91.26 748.85 82.74 191.78 169.98 381.06 257.42 570.21 37.1 80.25 69.7 191.5 127.71 252.8 56.93 60.14 173.29 144.97 252.34 151.05 76.94 5.92 200.14-47.91 269.47-85.89 97.44-53.39 124.52-139.35 168.64-237.96 40.43-90.37 95.26-333.01 110.66-430.15 18.8-117.66 36.1-235.55 49.6-353.88 15-132.05 25.3-264.7 27.5-397.56.3-14.76 2.6-56.1 3.6-105.63"
                    ></path>
                    <path
                        fill="#3ababa"
                        d="M9331.65 4787.03s-213.66-299.12-320.49-555.52c-54.59-131.01-122.85-555.51-149.56-614.27-26.71-58.75-51.51-395.74-51.51-395.74l84.99-31.36s110.74 752.93 158.82 902.49c48.06 149.56 122.85 261.74 133.53 384.59 10.69 122.85 176.27 309.81 176.27 309.81h-32.05"
                    ></path>
                    <path
                        fill="#b97262"
                        d="M7113.43 5318.38l-76.2 13.53-11.08-14.69 17.54-14.36-66.46-6.87-37.99-38.7 12.5-71.07 55.34-49.97 69.25 19.9 59.46 61.7 25.53 16.8-28.17 97.97-19.72-14.24"
                    ></path>
                    <path
                        fill="#37afac"
                        d="M7839.36 6161.16c-34.09 37.43-234.67 150.62-234.67 150.62l52.46-701.78-549.39-274.06 49.87-173.44 786.33 318.09-104.6 680.57"
                    ></path>
                    <path
                        fill="#ffd559"
                        d="M6781.16 4725.97v52.79c0 85.72-71.32 155.21-159.29 155.21H4960.86c-87.97 0-159.29-69.49-159.29-155.21v-2798.8h3959.19v2590.79c0 85.72-71.32 155.22-159.3 155.22h-1820.3"
                    ></path>
                    <path
                        fill="#fff"
                        d="M8622.01 1951.58H4977.32v2550.73h3644.69V1951.58"
                    ></path>
                    <path
                        fill="#ebb044"
                        d="M6724.7 4183.38l-58.77-158.77H4960.86c-87.97 0-159.29-69.48-159.29-155.21V1893.25c0-85.72 71.32-155.2 159.29-155.2h3640.6c87.98 0 159.3 69.48 159.3 155.2v2237.47c0 85.72-71.32 155.21-159.3 155.21H6874.55c-67.14 0-127.07-41.01-149.85-102.55"
                    ></path>
                    <path
                        fill="#b97262"
                        d="M8618.38 4089.67l-77.74-23.54-65.4 32.11-13.83-9.27 12.12-16.79-61.91 12.4-44.43-22-7.17-62.84 37.05-56.89 67.81-2.25 69.85 35.37 108.05 27.87-24.4 85.83"
                    ></path>
                    <path
                        fill="#12505e"
                        d="M8551.66 4084.34l579.88 208.93 494.24 652.57 194.5-67.76 94.85-165.53-549.04-674.62-804.75-104.1-9.68 150.51"
                    ></path>
                    <path
                        fill="#eae9e9"
                        d="M3697.8 2802.08l-78.75 216.24 906.9 2155.47 199.53 70.85L3697.8 2802.08m-1142.48 60.2l-164.21 173.85-167.93 177.81 57.36 54.18 26.61-28.17 164.2-173.86 833.8 787.5 76.71-210.66-826.54-780.65m295.7 1227.77l-622.75 267.94 213.54 75.82L2973 4205.27l-121.98-115.22"
                    ></path>
                    <path
                        fill="#b97262"
                        d="M2610.28 3998.99l116.6 56.73 28.39-34.72 97.97-3.59-1.35-62.27-169.78-43.99-71.83 87.84"
                    ></path>
                    <path
                        fill="#1a3151"
                        d="M1068.6 2746.06l159.07-706.61 146.25-840.74h147.89l60.47 2045.64-488.96-8.88-24.72-489.41"
                    ></path>
                    <path
                        fill="#1a3151"
                        d="M2004.82 2789.28l20.53-685.92-206.55-904.65h-132.77l-13.57 954.02-145.22 780.79-36.12 249.24 407.11 48.02 106.59-441.5"
                    ></path>
                    <path
                        fill="#3ababa"
                        d="M1682.77 4634.55l-461.54-34.05-264.57-428.05c73.33-261.3 58.89-171.72 103.49-407.89l-131.951-650.27 172.191-50.18 625.72-11.13 205.78 36.01-56.27 620.45 29.18 828.52-222.03 96.59"
                    ></path>
                    <path
                        fill="#3ababa"
                        d="M1773.76 4296.52l232.1-358.35 223.45-32.55 509.5-21.29-46.55 150.97-538.51 138.01-248.95 364.65-131.04-241.44"
                    ></path>
                    <path
                        fill="#b97262"
                        d="M1658.32 5032.58c-70 203.52-338.69 127.25-394.08-53.96-36.13-118.21 55.17-271.61 177.65-305.57.54-4.65.95-9 .96-12.68.08-18.98 2.42-37.68 5.24-56.64 1.05-7.07 108.24 13.53 120.19 15.88 4.19.82 8.85 1.96 11.1 5.47 4.33 6.73-2.71 26.55-3.49 34.03-.93 8.82-1.85 17.65-2.78 26.48 60.42 29.49 98.55 92.31 109.19 156.25 10.69 64.24-2.94 129.58-23.98 190.74"
                    ></path>
                    <path
                        fill="#1a3151"
                        d="M1465.46 5222.27c-13.25-15.68-17.35-36.45-20.01-56.3-1.58-11.85-2.79-24.08.59-35.44 13.45-45.32 58.96-39.43 95.42-51.59 44.97-14.99 85.12-45.85 112.47-82.82 42.83-57.89 31.6-137.43 21.21-205.67-3.67-24.05-11.98-60.02 7.95-79.7 20.25-20.01 63.05-13.42 84.52-.71 19.62 11.63 34.94 31.52 57.04 36.98 23.38 5.77 56.18-2.22 65.56 20.36 2.6 6.28 2.32 13.69 5.78 19.52 11.66 19.72 45.88.27 63.32 14.87 10.73 8.98 9.33 26.27 4.45 39.66-4.87 13.39-12.39 26.77-10.78 40.89 1.39 12.14 9.3 22.26 13.81 33.54 8.89 22.31 3.13 50.03-13.94 66.99-31.74 31.54-87.7 20.84-121.37 50.21-17.54 15.3-25.66 38.68-33.45 60.8-7.79 22.11-16.89 45.36-35.46 59.34-22.94 17.26-53.74 11.69-76.65 32.49-13.47 12.23-23.08 28.74-37.94 39.13-19.59 13.71-56.52 22.58-80.04 28.54-37.31 9.46-77.39-1.42-102.48-31.09"
                    ></path>
                    <path
                        fill="#1a3151"
                        d="M1320.65 4885.53c3.83-66.89 42.51-136.57 16.22-197.71-26.54-61.7-103.13-78.28-168.87-86.4-10.81-1.34-149.13-23.95-149.92-21.36-13.96 45.73-28.357 92.36-55.779 131.36-27.422 39-70.656 69.76-117.438 69.12 13.258 3.35 18.782 20.31 16.172 34.05-2.613 13.75-10.742 25.72-15.906 38.72-12.691 31.96-5.672 70.91 17.258 95.8 45.761 49.65 136.508 37.38 174.713 93.32 23.69 34.68 16.97 81.87 27.65 122.82 20.65 79.2 103.92 101.78 164.42 141.44 63.87 41.86 125.11 66.81 203.03 59.84 96.6-8.64 174.89-100.35 145.06-194.54-38.26-120.8-227.67-125.04-253.93-246.74-2.82-13.07-3.45-26.34-2.68-39.72"
                    ></path>
                    <path
                        fill="#1a3151"
                        d="M1373.92 4611.34l136.06-192.31 63.97 5.23 86.82 208.75-286.85-21.67"
                    ></path>
                    <path
                        fill="#145566"
                        d="M1521.81 1198.71l78.8-101.37-73.37-80.23-102.93 90.8-50.39 90.8h147.89"
                    ></path>
                    <path
                        fill="#145566"
                        d="M1829.18 1198.71l78.8-101.37-73.38-80.23-102.92 90.8-50.39 90.8h147.89"
                    ></path>
                    <path
                        fill="#145566"
                        d="M3613.83 3005.9l1027.68 2442.56-2497.21-886.65 828.7-356.54-833.79-787.51 332.14-351.67 833.8 787.5 308.68-847.69"
                    ></path>
                    <path
                        fill="#175160"
                        d="M3613.83 3005.9l-231.97 637.03-76.71 210.66-833.8-787.5-164.2 173.86 2334.36 2208.51-115.56-274.67-906.9-2155.47-5.22-12.42"
                    ></path>
                    <path
                        fill="#b97262"
                        d="M2297.9 3486.05l128.88-14.22 5.5-44.52 80.94-55.3-34.36-51.95-167.07 53.39-13.89 112.6"
                    ></path>
                    <path
                        fill="#37afac"
                        d="M956.66 4172.45l466.62-491.16 211.25-79.79 687.33-246.31-13.03 157.45-690.6 366.53-397.06 723.09-182.22-100.22-82.29-329.59"
                    ></path>
                    <path
                        fill="#b97262"
                        d="M9497.8 4906.72c38 2.4 127.89 23.25 127.98 19.71-.85 34.36-4.45 68.65-10.75 102.44 35.25 13.88 75.84 34.24 80.69 71.82 1.54 11.84-.94 23.78-3.41 35.46-10.19 48.28-20.38 96.56-30.56 144.84-3.52 16.7-7.25 33.88-16.92 47.95-16.97 24.67-48.49 34.58-78.16 38.61-110.04 14.95-210.36-62.14-306.62-103.69-8.02-3.46-16.34-7.14-21.97-13.81-9.8-11.59-8.8-28.58-6.71-43.6 10.8-77.87 32.4-191.71 122.94-213.35 19.77-4.73 40.51-4.34 60.22-9.42 47.49-12.24 33.36-57.77 68.01-75.93 2.4-1.26 7.84-1.5 15.26-1.03"
                    ></path>
                    <path
                        fill="#1a3151"
                        d="M9046.06 5377.65c-10.95 38.8-15.25 77.88-11.67 108.94 8.39 72.73 58.06 198.27 152.49 146.97 51.93-28.21 91.27-56.55 154.84-45.85 72.5 12.19 112.16 29.3 186.67 5.77 65.15-20.57 151.68-22.81 211.08-53.06 24.81-12.63 44.98-33.05 60.97-55.85 55.36-78.97 59.76-190.52 10.79-273.6-11-18.65-24.33-35.8-38.4-52.24-29.13-34.02-63.92-76-105.21-89.73-2.37 37.3 27.3 156.28-2.49 185.83-47.3 46.88-149.56 26.15-205.57 12.11-61.09-15.3-114.36-60.93-175.07-72.5-43.68-8.32-100.53 11.16-138.73 32.48-47.07 26.29-82.03 88.15-99.7 150.73"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export default Choose;
