import {
    Box,
    Button, Collapse, Heading,
    HStack,
    Spacer,
} from "@chakra-ui/react";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {PaginatedLinkedFiles} from "../../types";
import {IoMdAddCircleOutline} from "react-icons/io";
import CreateLinkedFiles from "./CreateLinkedFiles";

export default function CreateLinkedFilesModal({owningFileId, isLoadingLinkedFiles = false, linkedFiles}: {
    owningFileId: string,
    isLoadingLinkedFiles?: boolean,
    linkedFiles: PaginatedLinkedFiles,
}) {
    const {t} = useTranslation()
    const [isOpen, setIsOpen] = useState(false);

    function handleCollapse() {
        setIsOpen((p) => !p);
    }

    return (
        <Box py={4} bg="white" rounded={"md"}>
            <HStack>
                <Heading size={"sm"}>{t('linkedFiles.createLink.title')}</Heading>
                <Spacer/>
                <Button
                    variant={"outline"}
                    w={"auto"}
                    onClick={handleCollapse}
                    leftIcon={
                        <IoMdAddCircleOutline color="var(--chakra-colors-teal-400)"/>
                    }
                >
                    {t('linkedFiles.createLink.button')}
                </Button>
            </HStack>
            <Collapse in={isOpen} animateOpacity>
                <CreateLinkedFiles
                    owningFileId={owningFileId!}
                    isLoadingLinkedFiles={isLoadingLinkedFiles}
                    linkedFiles={linkedFiles!}
                    onFormSubmitSuccess={() => handleCollapse()}
                />
            </Collapse>
        </Box>
    );
}
