import React from 'react';
import {Button, Collapse, Box, Icon, useDisclosure, Slide, FormControl, FormErrorMessage} from '@chakra-ui/react';
import {IoCloseSharp, IoSearchSharp} from "react-icons/io5";
import {object, string} from "yup";
import {Formik, FormikProps} from "formik";
import AppInput from "./AppInput";
import {useTranslation} from "react-i18next";
import {AutoSubmit} from "./AutoSubmit";

const defaultSetSearch = (value: string | null) => {
};

type InitialValuesType = {
    search: string | null;
};

const SearchSlideButton = (
    {
        search = null,
        setSearch,
    }: {
        search: string | null,
        setSearch: (value: string | null) => void;
    }) => {

    const {t} = useTranslation()
    const {isOpen, onToggle} = useDisclosure()
    const formikRef = React.useRef<FormikProps<InitialValuesType>>(null);

    const handleCloseClick = () => {
        onToggle();
        formikRef.current?.resetForm();
    };

    const schema = object({
        search: string().nullable().min(3),
    });

    return (
        <Box position="relative" display="flex" flexDirection="column" alignItems="start">
            <Formik
                innerRef={formikRef}
                validationSchema={schema}
                validateOnMount={true}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    setSubmitting(true);
                    setSearch(values.search === '' ? null : values.search)
                    setSubmitting(false);
                }}
                initialValues={{
                    search: '',
                }}>
                {formik => (
                    <>
                        <AutoSubmit formik={formik}/>
                        <Box display="flex" alignItems="center">
                            <Collapse in={isOpen}>
                                {/*<Slide direction='right' in={isOpen} style={{position: "static", background: 'white'}}>*/}
                                <Slide direction='right' in={isOpen} style={{position: "static"}}>
                                    <FormControl isInvalid={!!formik.errors.search && formik.touched.search}>
                                        <AppInput
                                            // label={t('common.createdAt')}
                                            name={'search'}
                                            handleBlur={formik.handleBlur}
                                            // value={values.file_created_at}
                                            // type={"date"}
                                            leftElement={<IoSearchSharp/>}
                                        />
                                        <FormErrorMessage>{formik.errors.search}</FormErrorMessage>
                                    </FormControl>
                                </Slide>
                            </Collapse>
                            <Button onClick={onToggle} rightIcon={isOpen ? undefined : <IoSearchSharp/>}>
                                {isOpen ?
                                    <Icon as={IoCloseSharp} onClick={handleCloseClick}/> : t('common.actions.search')}
                            </Button>
                        </Box>
                    </>
                )}
            </Formik>
        </Box>
    );
};

export default SearchSlideButton;
