import React, {ReactNode} from "react";
import {FileSource} from "../types";
import {IoGlobeSharp} from "react-icons/io5";

export const fileSourceIcon = (fileSource: string): ReactNode | null => {
    switch (fileSource) {
        case FileSource.UserUpload:
            return <IoGlobeSharp
                fontSize={20}
                color={`var(--chakra-colors-teal-300)`}
            />
        default:
            return undefined
    }
}
