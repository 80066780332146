import {Navigate, useLocation} from 'react-router-dom'
import {useAuth} from '../hooks/useAuth'
import {isLoggedIn} from "../slices/authSlice";
import {useGetCurrentUserQuery} from "../services/userService";
import {hasAllowedRole} from "./userRoleHelper";
import AccessDenied from "../pages/AccessDenied";
import {ReactElement} from "react";
import {AccountType, Role} from "../types";

export function AccessLimit(
    {
        children, allowedRoles = [], allowedCompanyTypes = [], isStrictRole = false, showMessage = false
    }: {
        children: ReactElement,
        allowedCompanyTypes?: AccountType[],
        allowedRoles?: Role[],
        isStrictRole?: boolean,
        showMessage?: boolean,
    }) {
    const auth = useAuth()
    const location = useLocation()
    const {data} = useGetCurrentUserQuery(undefined, {skip: !isLoggedIn(auth)})

    return isLoggedIn(auth) ? (
        allowedCompanyTypes.length === 0 || allowedCompanyTypes.includes(data.company.account_type)
            ? hasAllowedRole(data, allowedRoles, isStrictRole)
                ? children
                : showMessage ? <AccessDenied/> : null //todo create msg
            : showMessage ? <AccessDenied/> : null //todo create msg that they need to or be different!!!
    ) : (
        <Navigate to="/login" state={{from: location}}/>
    )
}
