import React from 'react';
import {Avatar, Heading, HStack, Stack} from '@chakra-ui/react';
import Moment from 'react-moment';
import {fileSourceIcon} from "../utils/generalHelperElements";
import {useTranslation} from "react-i18next";
import {FieldConfig, FieldType} from "../types";


interface DisplayFieldProps<T> {
    config: FieldConfig;
    data: T;
}

const DisplayField = <T extends { [key: string]: any }>({config, data}: DisplayFieldProps<T>) => {
    const {t} = useTranslation()
    const {title, field, type} = config;
    const fieldValue = data[field];

    const renderFieldValue = () => {
        switch (type) {
            case FieldType.Profile:
                if (fieldValue) {
                    return (
                        <>
                            <Avatar size="sm" name={`${fieldValue.first_name} ${fieldValue.last_name}`} src=""/>
                            <Heading size="xs"
                                     fontWeight={600}>{`${fieldValue.first_name} ${fieldValue.last_name}`}</Heading>
                        </>
                    );
                }
                break;
            case FieldType.Date:
                if (fieldValue) {
                    return <Moment format="MMM D, YYYY">{fieldValue}</Moment>;
                }
                break;
            case FieldType.Source:
                if (fieldValue && fileSourceIcon) {
                    return (
                        <>
                            {fileSourceIcon(fieldValue)}
                            <Heading size="xs" fontWeight={400}>{t(`fileSources.${fieldValue}`)}</Heading>
                        </>
                    );
                }
                break;
            case FieldType.Text:
                return <Heading size="xs" fontWeight={400}>{fieldValue}</Heading>;
            default:
                return <Heading size="xs" fontWeight={400}>{fieldValue}</Heading>;
        }
    };

    return (
        <Stack>
            <Heading size="sm">{t(title)}</Heading>
            <HStack>
                {renderFieldValue()}
            </HStack>
        </Stack>
    );
};

export default DisplayField;
