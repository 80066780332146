import {
    Box,
    chakra,
    Container,
    SimpleGrid,
    Stack,
    Text,
    VisuallyHidden,
    Input,
    IconButton,
    useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import {FaFacebook, FaLinkedin, FaTwitter} from 'react-icons/fa';
import {BiMailSend} from 'react-icons/bi';
import LogoRight from "../assets/FileticaLogoRight.png";
import {useTranslation} from "react-i18next";
import {NavLink, useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
import {useSubscribeEmailMutation} from "../services/subscriptionService";
import {object, string} from "yup";
import {IoCheckmark} from "react-icons/io5";
import PropTypes from "prop-types";
import {AppStoreBadge} from "./Footer/AppStoreBadge";
import {PlayStoreBadge} from "./Footer/PlayStoreBadge";


const SocialButton = ({children, label, href}) => {
    return (
        <chakra.button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

SocialButton.propTypes = {
    children: PropTypes.element.isRequired,
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
};

const ListHeader = ({children}) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    );
};

ListHeader.propTypes = {
    children: PropTypes.string.isRequired,
};

export default function Footer() {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const [subscribe, {isUninitialized}] = useSubscribeEmailMutation()
    const schema = object({
        email: string().email('Invalid Email address').required(),
    });
    const inputBg = useColorModeValue('blackAlpha.100', 'whiteAlpha.100');
    const iconBg = useColorModeValue('green.400', 'green.800');
    const iconColor = useColorModeValue('white', 'gray.800');

    return (
        <Box
            mt={'auto'}
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}>
            <Container as={Stack} maxW={'6xl'} py={10}>
                <SimpleGrid
                    templateColumns={{sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr'}}
                    spacing={8}>
                    <Stack spacing={6}>
                        <Box>
                            <img alt={'Filetica logo'} width={180} onClick={() => navigate('')} src={LogoRight}/>
                        </Box>
                        <Text fontSize={'sm'}>
                            © {new Date().getFullYear()} Martive s.r.o. {t('footer.rights')}
                        </Text>
                        <Stack direction={'row'} spacing={6}>
                            <SocialButton label={'Twitter'} rel="noreferrer noopener"
                                          href={'https://twitter.com/filetica'} target="_blank">
                                <FaTwitter/>
                            </SocialButton>
                            <SocialButton label={'Facebook'} rel="noreferrer noopener"
                                          href={'https://www.facebook.com/thefiletica'} target="_blank">
                                <FaFacebook/>
                            </SocialButton>
                            <SocialButton label={'LinkedIn'} rel="noreferrer noopener"
                                          href={'https://www.linkedin.com/company/filetica/'} target="_blank">
                                <FaLinkedin/>
                            </SocialButton>
                            {/*<SocialButton label={'YouTube'} rel="noreferrer noopener" href={'#'}>*/}
                            {/*    <FaYoutube/>*/}
                            {/*</SocialButton>*/}
                            {/*<SocialButton label={'Instagram'} rel="noreferrer noopener" href={'#'} target="_blank">*/}
                            {/*    <FaInstagram/>*/}
                            {/*</SocialButton>*/}
                        </Stack>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>{t('footer.company')}</ListHeader>
                        {/*<Link href={'#'}>About us</Link>*/}
                        <NavLink to={'/blog'}>{t('blog.blog')}</NavLink>
                        {/*<Link href={'#'}>Contact us</Link>*/}
                        {/*<Link href={'#'}>Pricing</Link>*/}
                        {/*<Link href={'#'}>Testimonials</Link>*/}
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>{t('footer.support')}</ListHeader>
                        {/*    <Link href={'#'}>Help Center</Link>*/}
                        <NavLink to={'/terms'}>{t('footer.termsOfService')}</NavLink>
                        {/*    <NavLink href={'/'}>Legal</NavLink>*/}
                        <NavLink to={'/privacy'}>{t('footer.privacyPolicy')}</NavLink>
                        <NavLink to={'/gdpr'}>{t('footer.dataProcessingAgreement')}</NavLink>
                        <NavLink to={'/cookies'}>{t('footer.cookiesPolicy')}</NavLink>
                        {/*    <Link href={'#'}>Status</Link>*/}
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>{t('footer.subscribe')}</ListHeader>
                        <Formik
                            onSubmit={(values, {setSubmitting, resetForm}) => {
                                setSubmitting(true);
                                subscribe(values)
                                setSubmitting(false);
                            }}
                            enableReinitialize={true}
                            validationSchema={schema}
                            initialValues={{
                                email: '',
                                source: 'footer',
                            }}>
                            {({
                                  handleSubmit,
                                  handleChange,
                                  handleReset,
                                  handleBlur,
                                  values,
                                  dirty,
                                  touched,
                                  isValid,
                                  isSubmitting,
                                  errors,
                                  setFieldValue,
                                  setFieldTouched,
                              }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Stack direction={'row'}>
                                        <Input
                                            placeholder={t('landing.waitingList.placeholder')}
                                            aria-label={t('landing.waitingList.placeholder')}
                                            disabled={!isUninitialized}
                                            name={'email'}
                                            type={'email'}
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={!!errors.email && touched.email}
                                            required
                                            bg={inputBg}
                                            border={0}
                                            _focus={{
                                                bg: 'whiteAlpha.300',
                                            }}
                                        />
                                        <IconButton
                                            bg={iconBg}
                                            color={iconColor}
                                            _hover={{
                                                bg: 'green.600',
                                            }}
                                            aria-label="Subscribe"
                                            type={isUninitialized ? 'submit' : 'button'}
                                            icon={isUninitialized ? <BiMailSend/> : <IoCheckmark/>}
                                        />

                                    </Stack>
                                </Form>
                            )}
                        </Formik>
                        <ListHeader>{t('footer.appComingSoon')}</ListHeader>
                        <Stack direction={'row'}>
                            <AppStoreBadge/>
                            <PlayStoreBadge/>
                        </Stack>
                    </Stack>
                </SimpleGrid>
            </Container>
        </Box>
    );
}
