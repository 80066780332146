import React from "react";
import {Box, Container, Heading, HStack, ListItem, Text, UnorderedList, VStack} from "@chakra-ui/react";
import Seo from "../../components/Seo";
import {Link} from "react-router-dom";

const TermsOfService = () => {

    return (
        <Container maxW={"container.xl"} pt={"10"} px={3}>
            <Seo
                title={`Filetica - Terms of service`}
                description={'Filetica Terms of service'}
                // ogType={'article'}
            />
            <HStack mb={2}>
                <Text color={"gray.600"}>Filetica / </Text>
                <Heading size={"md"} color={"teal.400"}>
                    Terms of service
                </Heading>
            </HStack>
            <Box bg={"#fff"} p={8} borderRadius={10}>
                <VStack paddingTop="40px" spacing="4" alignItems="flex-start">
                    <Text>
                        Welcome to Filetica, a platform designed to simplify document management and enhance user
                        experience in organizing and archiving files. When you use Filetica, you’re
                        agreeing to all the rules on this page. Some of them need to be expressed in legal
                        language, but we’ve done our best to offer you clear and simple explanations of what
                        everything means.
                    </Text>

                    <Heading size={'md'}>Acceptance of Terms</Heading>
                    <Text>
                        When you access or use Filetica, you agree to be bound by these Terms of Service and all
                        applicable laws and regulations. If you do not agree with any of these terms, you are prohibited
                        from using or accessing this site.
                    </Text>

                    <Heading size={'md'}>Registration and Account Responsibilities</Heading>
                    <Text>
                        To sign up for a Filetica account, you must be of legal age in your country or over 18 years
                        old. You’re responsible for your account and all activity on it. You can browse Filetica without
                        registering, but to access most features, you need to register, provide an accurate email, and
                        set a password. When you do that, the information you give us has to be accurate and complete.
                        Impersonation or choosing offensive names that violate rights is prohibited. Failure to adhere
                        may result in account cancellation without right to refund.
                        You’re responsible for all the activity on your account, and for keeping your password
                        confidential. If you find out that someone has used your account without your permission, you
                        should report it to info@filetica.com immediately.
                        To sign up for an account, you need to be at least 18 years old, or old enough to form a binding
                        contract where you live. If necessary, we may ask you for proof of age
                        <br/>
                        You're responsible for all activity on your account and for maintaining the confidentiality of
                        your password. We recommend using strong, unique passwords and changing them periodically. If
                        you suspect unauthorized use of your account, report it immediately to
                        <a href="mailto:info@filetica.com?Subject=AccountBreach" target="_top"> info@filetica.com</a>.
                        <br/>
                        If necessary, we may request proof of age to verify your eligibility to use our platform.
                    </Text>

                    <Heading size={'md'}>Prohibited Activities</Heading>
                    <Text>
                        At Filetica, we value a respectful and law-abiding community. As such, users are expected to
                        refrain from the following activities:
                    </Text>
                    <VStack spacing="4" alignItems="flex-start">
                        <UnorderedList>
                            <ListItem><strong>Illegal Activities:</strong> Do not engage in actions that break the law.</ListItem>
                            <ListItem><strong>Infringement:</strong> Avoid activities that infringe on the rights of
                                others or violate any contract or legal duty.</ListItem>
                            <ListItem><strong>Misleading Information:</strong> Do not provide or promote false or
                                deceptive information.</ListItem>
                            <ListItem><strong>Spam:</strong> Unsolicited or repetitive messages are not
                                allowed.</ListItem>
                            <ListItem><strong>Threats & Harassment:</strong> Threatening behavior, harassment, or any
                                form of intimidation is strictly prohibited.</ListItem>
                            <ListItem><strong>Defamation:</strong> Do not post content that harms the reputation of an
                                individual or organization.</ListItem>
                            Security:
                            <ListItem><strong>Security:</strong> Users must not attempt to undermine the security of our
                                platform. This includes, but is not limited to, attempting to gain unauthorized access,
                                conducting denial-of-service attacks, or introducing malicious software.</ListItem>
                            <ListItem><strong>Unauthorized Advertising:</strong> Avoid promoting products, services, or
                                brands without proper authorization.</ListItem>
                            <ListItem><strong>Misuse of Personal Information:</strong> Respect the privacy of other
                                users. Information you receive about other users through Filetica should only be used
                                for platform-related purposes. Do not misuse or exploit this information for other
                                activities.</ListItem>
                        </UnorderedList>
                    </VStack>
                    <Text mt={4}>
                        If you wish to be a part of the Filetica community, it's essential to adhere to these
                        guidelines. Engaging in any of the prohibited activities may result in consequences, including
                        account suspension or termination withour right to refund. Filetica isn’t liable for damages or
                        losses related to your use of our Services. We don’t mediate
                        user disputes or endorse user-submitted content. You assume all risks related to content
                        accessed
                        through our Services. If you encounter any violations of this policy, please report them to us
                        at <a href="mailto:info@filetica.eu?Subject=Violation"
                              target="_top">info@filetica.com</a>
                    </Text>
                    <Heading size={'md'}>Our fees</Heading>
                    <Text>
                        Registering with Filetica comes with associated costs. While we strive to offer valuable
                        services, maintaining and enhancing our platform requires resources. When you successfully
                        utilize our services, both Filetica and our payment partners impose certain fees. The exact fees
                        from our partners might vary based on your geographical location.

                        It's important to note that each payment provider we collaborate with operates as an independent
                        entity. As such, Filetica cannot be held accountable for their individual performance or any
                        discrepancies in their services.

                        Furthermore, users are responsible for any additional charges or taxes that might arise due to
                        their specific location or the nature of their transactions on Filetica. Always ensure you're
                        aware of any extra costs or obligations when using our platform.
                    </Text>

                    <Heading size={'md'}>Other websites and links</Heading>
                    <Text>
                        Filetica may contain links to other websites. (For instance comments that may link to other
                        sites.) When you access third-party websites, you do so at your own risk. We don’t control or
                        endorse those sites. Our partnerships with companies like Stripe and PayPal for
                        payment processing mean you also agree to their terms.
                    </Text>

                    <Heading size={'md'}>Intellectual Property</Heading>
                    <Text>
                        Filetica’s services, content and Marks, are legally protected in a number of ways,
                        including pursuant to copyright, trademark, service marks, patent, trade secrets,
                        and other EU and international intellectual-property laws.
                        You agree to respect all copyright and other legal notices, information, and
                        restrictions contained in any Filetica Content, Services, or Marks accessed through
                        the Site or the Services.
                        You agree not to change, translate, or otherwise create derivative works of the
                        Services.
                        Filetica grants you a limited license (that is temporary, non-exclusive,
                        non-sublicensable, and non-transferrable) to access and use User Content and
                        Filetica’s Content solely for use of the Services in accordance with these Terms.
                        You may not reproduce, redistribute, transmit, assign, sell, broadcast, rent, share,
                        lend, modify, adapt, edit, create derivative works of, license, or otherwise
                        transfer or use any User Content or Filetica Content unless We give you express
                        written permission to do so.
                        We reserve the right to revoke this limited license to access and use User Content
                        and Filetica Content at any time and in our sole discretion. You’re responsible for content you
                        upload and any associated royalties or licensing. Filetica isn’t
                        liable for errors in your content or any resulting issues. Any royalties or licensing on your
                        Content are your responsibility. You will pay all
                        royalties and other amounts owed to any person or entity based on your Content, or
                        on Filetica’s hosting of that Content. You’re responsible for the documents you upload. All
                        information submitted to the Site,
                        whether publicly posted or privately transmitted, is the sole responsibility of the
                        person from whom that content originated.
                        We’re not responsible for mistakes in your content. Filetica will not be liable for
                        any errors or omissions in any content.
                    </Text>

                    <Heading size={'md'}>Account termination</Heading>
                    <Text>
                        You can terminate your account at any time through your account settings or through our email
                        stated bellow. We may
                        retain certain information as required by law or as necessary for our legitimate
                        business purposes. All provisions of this agreement survive termination of an
                        account. You can contact us at <a href="mailto:info@filetica.com?Subject=Privacy"
                                                          target="_top">info@filetica.com</a> for additional information
                        or to request full
                        deletion (this is not available in all circumstances).
                    </Text>

                    <Heading size={'md'}>Indemnity</Heading>
                    <Text>
                        You agree to defend, indemnify and hold harmless Filetica, Our subsidiaries and
                        affiliated companies, and Our officers, directors, employees, partners, contractors,
                        representatives, agents, and third party providers from and against any and all
                        claims, causes of action, damages, obligations, losses, liabilities, costs or debt,
                        and expenses (including reasonable attorneys&apos; fees and costs) and all amounts
                        paid
                        in settlement arising from or relating to, breach of these Terms or violation of any
                        applicable laws. We reserve the right, in Our sole discretion and at Our own
                        expense, to assume the exclusive defense and control of any matter for which you
                        have agreed to indemnify us and you agree to assist and cooperate with us as
                        reasonably required in the defense or settlement of any such matters.
                    </Text>
                    <Heading size={'md'}>Dispute resolution</Heading>
                    <Text>
                        We at Filetica encourage you to contact us if you’re having an issue, before resorting
                        to the courts. In the unfortunate situation where legal action does arise, these
                        Terms (and all other rules, policies, or guidelines incorporated by reference) will
                        be governed by and construed in accordance with the laws of the European union,
                        without giving effect to any principles of conflicts of law, and without application
                        of the Uniform Computer Information Transaction Act or the United Nations Convention
                        of Controls for International Sale of Goods.
                    </Text>
                    <Heading size={'md'}>Copyright</Heading>
                    <Text>
                        The Digital Millennium Copyright Act lays out a system of legal requirements for
                        dealing with allegations of copyright infringement. Filetica complies with the DMCA,
                        and we respond to notices of alleged infringement if they comply with the law and
                        the requirements set forth in our Copyright Policy. We reserve the right to delete
                        or disable content alleged to be infringing, and to terminate accounts for repeat
                        infringers. (We do this when appropriate and at our sole discretion.)
                    </Text>
                    <Heading size={'md'}>Agreement Between You and Us</Heading>
                    <Text>
                        These Terms are the entire agreement between You and Filetica with respect to the
                        Services. They supersede all other communications and proposals (whether oral,
                        written, or electronic) between you and Filetica with respect to the Services and
                        govern our relationship. If any provision of these Terms are deemed invalid by a
                        court of competent jurisdiction, the invalidity of such provision shall not affect
                        the validity of the remaining provisions of this Agreement, which shall remain in
                        full force and effect. Filetica&apos;s failure to assert any right or provision under
                        this
                        Agreement shall not constitute a waiver of such right or provision.
                    </Text>
                    <Heading size={'md'}>Miscellaneous</Heading>
                    <Text>
                        We may modify or discontinue the Services at any time, in our sole discretion. You
                        agree that, except as otherwise expressly provided in these Terms, there shall be no
                        third-party beneficiaries to these Terms. No waiver of any provision of these Terms
                        shall be deemed a further or continuing waiver of such term or any other term, and
                        Filetica’s failure to assert any right or provision under these Terms shall not
                        constitute a waiver of such right or provision. You agree that regardless of any
                        statute or law to the contrary, any claim arising out of or related to the Services
                        must commence within one (1) year after the cause of action accrues. Otherwise, such
                        cause of action is permanently barred.
                    </Text>

                    <Heading size={'md'}>Terms of Service Changes</Heading>
                    <Text>
                        Although most changes are likely to be minor, Filetica may change its Terms of Service
                        Policy from time to time, and in Filetica&apos;s sole discretion. Filetica
                        encourages visitors to frequently check this page for any changes to its Terms of Service.
                        If you have a filetica.com account, you might also receive an alert
                        informing you of these changes. Your continued use of this site after any change in
                        this Terms of Service will constitute your acceptance of such change.
                    </Text>
                    <Heading size={'md'}>Contact us if you have Terms of service related questions</Heading>
                    <Text>
                        Please feel free to contact us if you have any questions about Filetica’s
                        Terms of service.
                        You may contact us as follows:
                    </Text>
                    <VStack spacing="4" alignItems="flex-start">
                        <UnorderedList>
                            <ListItem>Website: <Link to=''>https://filetica.com</Link></ListItem>
                            <ListItem>Email: <a href="mailto:info@filetica.com?Subject=TermsOfService"
                                                target="_top">info@filetica.com</a></ListItem>
                        </UnorderedList>
                    </VStack>
                    {/*Phone: Phone Number*/}
                </VStack>
            </Box>
        </Container>
    )
}

export default TermsOfService;

