import {
    Box,
    Button,
    HStack,
    Spacer,
} from "@chakra-ui/react";
import React, {createContext, useState} from "react";
import {object, string} from "yup";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import {Role, User} from "../../types";
import {hasAllowedRole} from "../../utils/userRoleHelper";
import {useNavigate} from "react-router-dom";
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import UppyFileUpload from "../Files/UppyFileUpload";
import {useCreateUploadHistoryMutation} from "../../services/uploadHistoryService";


export const FileIdsContext = createContext<{ addFiles: (newFileIds: string[]) => void }>({
    addFiles: () => {
    }
});

export default function FilesUpload({setStep, setUploadHistoryId, user}: {
    setStep: (step: number) => void,
    setUploadHistoryId: (uploadHistory: string | null) => void,
    user: User
}) {
    const {t} = useTranslation()
    const [add, {isLoading: isLoading}] = useCreateUploadHistoryMutation()
    const [fileIds, setFileIds] = useState<string[]>([]);

    const addFiles = (newFileIds: string[]) => {
        setFileIds(prevFileIds => [...prevFileIds, ...newFileIds]);
    };


    const navigate = useNavigate()
    const schema = object({
        //     // todo later add file validation (size...)
        // fileIds: string().required(),
        note: string(),
    });

    return (
        <Box>
            <Formik
                validationSchema={schema}
                validateOnMount={true}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    setSubmitting(true);
                    add({...values, fileIds: fileIds})
                        .unwrap()
                        .then(fulfilled => setUploadHistoryId(fulfilled.id))
                        .catch(rejected => console.error(rejected))
                    setSubmitting(false);
                    if (hasAllowedRole(user, [Role.User])) {
                        setStep(2)
                    } else {
                        navigate('/files')
                    }
                }}
                initialValues={{
                    note: '',
                    fileIds: [],
                }}>
                {({
                      handleSubmit,
                      handleChange,
                      handleReset,
                      handleBlur,
                      values,
                      dirty,
                      touched,
                      isValid,
                      isSubmitting,
                      errors,
                      setFieldValue,
                      setFieldTouched,
                      setValues,
                  }) => (<Form>
                    <Box bg={"#fff"} p={8} borderRadius={10}>
                        <FileIdsContext.Provider value={{addFiles}}>
                            <UppyFileUpload/>
                        </FileIdsContext.Provider>
                    </Box>

                    <Box p={8} bg={"#fff"} mt={8} borderRadius={"lg"}>
                        <HStack>
                            <Spacer/>
                            <Button isLoading={isLoading}
                                    loadingText={t('upload.actions.uploading')}
                                    type={'submit'} variant={"primary"}>
                                {hasAllowedRole(user, [Role.User])
                                    ? t('common.actions.next')
                                    : t('common.actions.upload')
                                }
                            </Button>
                        </HStack>
                    </Box>
                </Form>)}
            </Formik>
        </Box>);
}
