import {Box, Center, Container, Flex, Heading, HStack, Text} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import InvalidSubscription from "../components/Subscription/InvalidSubscription";
import {hasAllowedRole, hasSubscription} from "../utils/userRoleHelper";
import {Role} from "../types";
import {useGetCurrentUserQuery} from "../services/userService";
import {Navigate, useNavigate} from "react-router-dom";
import {
    useCreateCheckoutSessionMutation, useGetSubscriptionQuery
} from "../services/stripeSubscriptionService";
import {QueryStatus} from "@reduxjs/toolkit/query";
import SuccessfulSubscription from "../components/Subscription/SuccessfulSubscription";
import Pricing from "../components/Home/Pricing";
import Choose from "../assets/Choose.jsx";
import {isAgencyClient, isEnterprise} from "../utils/generalHelper";
import PendingSubscription from "../components/Subscription/PendingSubscription";

const Subscription: React.FC = () => {
    const {t} = useTranslation()
    // todo remove undefined when migrated to ts
    let [poolingInterval, setPoolingInterval] = useState<number | undefined>(undefined);
    //todo add max pool to stop
    // let [maxPooling, setMaxPoolingInterval] = useState<number | undefined>(undefined);

    const {data} = useGetCurrentUserQuery(undefined);
    const {data: subscription, refetch, status} = useGetSubscriptionQuery(undefined, {
        pollingInterval: poolingInterval,
    });
    // const [createCheckoutSession, { data: checkoutUrl, error, isLoading, isUninitialized, isError}] = useCreateCheckoutSessionMutation()
    const [createCheckoutSession, {data: checkoutUrl}] = useCreateCheckoutSessionMutation()
    // let [message, setMessage] = useState('');
    let [success, setSuccess] = useState(false);
    // let [sessionId, setSessionId] = useState('');
    const navigate = useNavigate()

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get('success') && !success) {
            setSuccess(true);
            // setPoolingInterval(3000);
            // @ts-ignore
            // setSessionId(query.get('session_id'));
            // navigate({
            //     search: '',
            // }, {replace: true});
        }

        if (query.get('canceled')) {
            setSuccess(false);
        }
    }, []);

    useEffect(() => {
        if (checkoutUrl) {
            window.location.href = checkoutUrl.url;
        }
    }, [checkoutUrl]);

    useEffect(() => {
        if (status === QueryStatus.fulfilled && !(subscription === null || subscription === undefined)) {
            console.log('stopping interval')
            setPoolingInterval(undefined);
        }
    }, [subscription]);

    if (success) {
        return (hasSubscription(subscription)
                ? <SuccessfulSubscription setSuccess={setSuccess}/>
                : <PendingSubscription refetchEvent={refetch}/>
        )
    }

    if (hasSubscription(subscription)) {
        return (
            <Navigate to={'/settings'}/>
        )
    }

    if (isEnterprise(data.company) || isAgencyClient(data.company)) {
        return (
            <InvalidSubscription/>
        )
    }

    if (!hasAllowedRole(data, [Role.Manager])) {
        return (
            <InvalidSubscription/>
        )
    }

    // check status and date
    //there check if he is admin if not show message contact manager
    //there check if he is admin yes and null show subscribe and pricing
    // if not active but exising show management page


    // manager can see subscription but cant control or add
    // owner can change stuff
    return (
        <Flex>
            <Container maxW={"container.xl"} p={8}>
                <HStack mb={2}>
                    <Text color={"gray.600"}>{t('nav.setting', {count: 2})} / </Text>
                    <Heading size={"md"} color={"teal.400"}>
                        {t('subscriptions.addSubscription')}
                    </Heading>
                </HStack>
                <Flex my={8} bg={"#fff"} minH={500} rounded={"2xl"} overflow={"hidden"}>
                    <Center bg={"#E6FFFA"} p={12}>
                        <Choose width="400"/>
                    </Center>
                    <Flex
                        px={12}
                        py={16}
                        flexDirection="column"
                        justifyContent={"center"}
                        gap={1}
                        minW="md"
                    >
                        <Heading as="h1" size="xl">
                            {t('subscriptions.welcome.title')}
                        </Heading>

                        <Heading as='h4' my={6}>{t('subscriptions.welcome.thankYou')}</Heading>

                        <Text my={6}>{t('subscriptions.welcome.subscribePrompt')}</Text>
                        {/*    todo add mention that he is as company */}
                    </Flex>
                </Flex>
                <Box flex={1}>
                    <Pricing accountType={data?.company?.account_type}
                             buttonEvent={createCheckoutSession}
                        //todo change this later when company type is different one
                             isPersonalDisabled={true}
                             isSubscription={true}
                    />
                </Box>
            </Container>
        </Flex>
    );
};

export default Subscription;
