import React, {useState} from "react";
import {
    Button,
    ButtonGroup,
    Center,
    Heading,
    Spacer,
    Box, Collapse, HStack, GridItem, Grid, FormControl, FormErrorMessage, Checkbox, FormLabel, FormHelperText,
} from "@chakra-ui/react";
import {IoMdAddCircleOutline} from "react-icons/io";
import {Form, Formik} from "formik";
import {boolean, object, string} from "yup";
import {useTranslation} from "react-i18next";
import {useCreateFileTypeMutation} from "../../services/fileTypeService";
import AppInput from "../AppInput";
import {AccessLimit} from "../../utils/AccessLimit";
import {AccountType} from "../../types";

export default function FileTypeCreateSingle() {
    const {t} = useTranslation()
    const [add, {isLoading}] = useCreateFileTypeMutation()
    const [isOpen, setIsOpen] = useState(false);

    function handleCollapse() {
        setIsOpen((p) => !p);
    }

    const schema = object({
        name: string().min(2).required(),
        hidden: boolean(),
    });

    return (
        <Box mt={"6"} p={8} bg="white" rounded={"2xl"}>
            <HStack>
                <Heading size={"sm"}>{t('fileType.create.title')}</Heading>
                <Spacer/>
                <Button
                    variant={"outline"}
                    w={"auto"}
                    onClick={handleCollapse}
                    leftIcon={
                        <IoMdAddCircleOutline color="var(--chakra-colors-teal-400)"/>
                    }
                >
                    {t('fileType.create.button')}
                </Button>
            </HStack>

            <Collapse in={isOpen} animateOpacity>
                <Formik
                    validationSchema={schema}
                    validateOnMount={true}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setSubmitting(true);
                        // todo extract!!!!!!
                        add({
                            ...Object.keys(values).reduce(
                                (item, key) => ({
                                    ...item,
                                    [key]: values[key] === '' ? null : values[key],
                                }),
                                {}
                            )
                        })
                        setSubmitting(false);
                        resetForm()
                        handleCollapse()
                    }}
                    // enableReinitialize={true}
                    initialValues={{
                        name: '',
                        userAccess: true,
                        agencyClientAccess: true,
                        externalAccess: false,
                        hidden: false, //use during temp hide/archive, just hide
                    }}>
                    {({
                          handleSubmit,
                          handleChange,
                          handleReset,
                          handleBlur,
                          values,
                          dirty,
                          touched,
                          isValid,
                          isSubmitting,
                          errors,
                          setFieldValue,
                          setFieldTouched,
                      }) => (<Form>
                        <Grid pt={4} templateColumns="repeat(2, 1fr)" columnGap={8} rowGap={6}>
                            <GridItem>
                                <FormControl isInvalid={!!errors.name && touched.name}>
                                    <AppInput name={`name`} handleBlur={handleBlur} label={t('common.name')}
                                              placeholder={t('fileType.fileType', {count: 1})}/>
                                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl as='fieldset'>
                                    <FormLabel as='legend'>
                                        {t('fileType.fileVisibility')}
                                    </FormLabel>
                                    <HStack spacing='24px'>
                                        <Checkbox colorScheme='green' isDisabled defaultChecked>
                                            {t('fileType.visibility.manager')}
                                        </Checkbox>
                                        <Checkbox name={'userAccess'} onChange={handleChange} handleBlur={handleBlur}
                                                  value={values.user} isChecked={values.userAccess}
                                                  colorScheme='green' defaultChecked>
                                            {t('fileType.visibility.user')}
                                        </Checkbox>
                                        <AccessLimit allowedCompanyTypes={[AccountType.agencyClient]}>
                                            <Checkbox name={'agencyClientAccess'} onChange={handleChange}
                                                      handleBlur={handleBlur}
                                                      value={values.user} isChecked={values.agencyClientAccess}
                                                      colorScheme='orange' defaultChecked>
                                                {t('users.roles.ROLE_AGENCY_CLIENT')}
                                            </Checkbox>
                                        </AccessLimit>
                                        <Checkbox name={'externalAccess'} onChange={handleChange}
                                                  handleBlur={handleBlur}
                                                  value={values.user} isChecked={values.externalAccess}
                                                  colorScheme='red' defaultChecked>
                                            {t('fileType.visibility.external')}
                                        </Checkbox>
                                    </HStack>
                                    <FormHelperText>{t('fileType.visibility.externalNote')}</FormHelperText>
                                </FormControl>

                            </GridItem>
                        </Grid>
                        <Center
                            mt={16}
                            sx={{
                                button: {
                                    minW: 160,
                                },
                            }}
                        >
                            <Spacer/>
                            <ButtonGroup gap={3}>
                                <Button
                                    isLoading={isLoading}
                                    loadingText={t('common.actions.saving')} type={'submit'}
                                    variant={"primary"}>{t('common.actions.save')}</Button>
                            </ButtonGroup>
                        </Center>
                    </Form>)
                    }
                </Formik>
            </Collapse>
        </Box>
    )
        ;
}

FileTypeCreateSingle.propTypes = {};
