import {
    AccordionButton,
    AccordionIcon,
    Heading,
    HStack,
    Text,
} from "@chakra-ui/react";
import React from "react";
import {IoHandLeftOutline} from "react-icons/io5";
import {NoAccessFile} from "../../types";
import {useTranslation} from "react-i18next";

export default function NoAccessFileAccordionButton({file}: { file: NoAccessFile }) {
    const {t} = useTranslation()

    return (
        <Heading size={'h2'}>
            <AccordionButton p={3}>
                <HStack flex='1' textAlign='left'>
                    <IoHandLeftOutline color="var(--chakra-colors-red-500)"/>
                    <Text>{t('files.noAccess')}</Text>
                </HStack>
                <AccordionIcon/>
            </AccordionButton>
        </Heading>
    );
}
