import React from "react";
import {Box, Container, Heading, HStack, ListItem, Text, UnorderedList, VStack} from "@chakra-ui/react";
import Seo from "../../components/Seo";

const CookiesPolicy = () => {

    return (
        <Container maxW={"container.xl"} pt={"10"} px={3}>
            <Seo
                title={`Filetica - Cookie policy`}
                description={'Filetica Cookie policy'}
                // ogType={'article'}
            />
            <HStack mb={2}>
                <Text color={"gray.600"}>Filetica / </Text>
                <Heading size={"md"} color={"teal.400"}>
                    Cookie policy
                </Heading>
            </HStack>
            <Box bg={"#fff"} p={8} borderRadius={10}>
                <VStack paddingTop="40px" spacing="4" alignItems="flex-start">


                    Why do we use cookies?

                    We use cookies for several reasons:

                    Essential Cookies: Some cookies are essential for the operation of our website. They allow us to
                    provide services you have asked for, like logging in.
                    Performance and Functionality Cookies: These cookies are used to enhance the performance and
                    functionality of our website but are non-essential to their use. However, without these cookies,
                    certain functionality may become unavailable.
                    Analytics and Customization Cookies: We use analytics cookies, primarily Google Analytics, to gather
                    data that helps us understand how our users use the website and to enhance their experience.
                    Stripe Cookies: We use Stripe for payment processing, and Stripe uses cookies to remember users and
                    enable Filetica to process payments without storing any payment information on its servers.
                    How can you control cookies?

                    You have the right to decide whether to accept or reject cookies. You can set or amend your web
                    browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our
                    website, though some functionality and areas of our website may be restricted.

                    How often will we update this Cookie Policy?

                    We may update this Cookie Policy from time to time to reflect changes to the cookies we use or for
                    other operational, legal, or regulatory reasons. Please revisit this Cookie Policy regularly to stay
                    informed about our use of cookies and related technologies.

                    Where can I get further information?

                    If you have any questions about our use of cookies or other technologies, please email us at
                    info@filetica.com.

                    Remember, while this Cookie Policy is tailored to your specific needs, it's always a good idea to
                    consult with legal counsel to ensure that it meets all regulatory requirements.
                    <Text>
                        This Cookie Policy explains how Filetica ("we", "our", or "us") uses cookies and similar
                        technologies when you visit our website or use our services. This policy should be read
                        alongside our Privacy Policy, which explains how we use personal data.
                    </Text>

                    <Heading size={'md'}>What are cookies?:</Heading>
                    <Text>
                        Cookies are small data files that are placed on your computer or mobile device when you visit a
                        website. Cookies are widely used by website owners to make their websites work or to work more
                        efficiently, as well as to provide reporting information.
                    </Text>

                    <Heading size={'md'}>Why do we use cookies?:</Heading>
                    <Text>
                        Cookies are small data files that are placed on your computer or mobile device when you visit a
                        website. Cookies are widely used by website owners to make their websites work or to work more
                        efficiently, as well as to provide reporting information.
                    </Text>
                    <Text>
                        We use cookies for several reasons:
                    </Text>
                    <VStack spacing="4" alignItems="flex-start">
                        <UnorderedList>
                            <ListItem>Essential Cookies: Some cookies are essential for the operation of our website.
                                They allow us to
                                provide services you have asked for, like logging in.</ListItem>
                            <ListItem>Performance and Functionality Cookies: These cookies are used to enhance the
                                performance and
                                functionality of our website but are non-essential to their use. However, without these
                                cookies,
                                certain functionality may become unavailable.</ListItem>
                            <ListItem>Analytics and Customization Cookies: We use analytics cookies, primarily Google
                                Analytics, to gather
                                data that helps us understand how our users use the website and to enhance their
                                experience.</ListItem>
                            <ListItem>Stripe Cookies: We use Stripe for payment processing, and Stripe uses cookies to
                                remember users and enable Filetica to process payments without storing any payment information on its servers.
                            </ListItem>
                        </UnorderedList>
                    </VStack>

                    <Heading size={'md'}>How can you control cookies?</Heading>
                    <Text>
                        You have the right to decide whether to accept or reject cookies. You can set or amend your web
                        browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our
                        website, though some functionality and areas of our website may be restricted.
                    </Text>
                </VStack>
            </Box>
        </Container>
    )
}

export default CookiesPolicy;

