import {createSlice} from '@reduxjs/toolkit'

const fileViewModalSliceInitialState = {
    showModal: false,
    initialized: false,
    file: null,
};

const fileViewModalSlice = createSlice({
    name: 'fileViewModal',
    initialState: fileViewModalSliceInitialState,
    reducers: {
        showFileViewModal: (state, action) => {
            state.showModal = true
            state.file = action.payload.file
        },
        closeFileViewModal: (state) => {
            return fileViewModalSliceInitialState
        },
    },
    extraReducers: (builder) => {
        // Needed to refresh file to see new task for example in modal
        // builder.addMatcher(fileApi.endpoints.getFilesList.matchFulfilled,
        //     (state, action) => {
        //         if (state.file !== null) {
        //             state.file = action.payload.data.find((file) => file.id === state.file.id)
        //         }
        //     }
        // )
        // builder.addMatcher(fileApi.endpoints.getPreviewFile.matchFulfilled,
        //     (state, action) => {
        //         state.initialized = true
        //     }
        // )
    },
})

export const {showFileViewModal, closeFileViewModal} = fileViewModalSlice.actions

export const isFileViewOpen = (state) => state.fileViewModal.showModal
export const selectFileViewModal = (state) => state.fileViewModal

export default fileViewModalSlice.reducer
