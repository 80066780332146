import {createSlice} from '@reduxjs/toolkit'

const devInitialState = {
    links: []
}

const devSlice = createSlice({
    name: 'dev',
    initialState: devInitialState,
    reducers: {
        addProfilerLink: (state, { payload }) => {
            console.log(payload)
            state.links = [...state.links, payload]
        },
    },
})

export const {addProfilerLink} = devSlice.actions

export const selectLinks = (state) => state.dev.links

export default devSlice.reducer

