import {createSlice} from '@reduxjs/toolkit'
import {UploadHistory} from "../types";
import {RootState} from "../store/store";


type SliceState = { showModal: boolean, uploadHistory: UploadHistory | null }

const uploadHistoryViewModalSliceInitialState: SliceState = {
    showModal: false,
    uploadHistory: null,
};

type ShowUploadHistoryAction = {
    payload: {
        uploadHistory: UploadHistory;
    };
    type: string;
};

const uploadHistoryViewModalSlice = createSlice({
    name: 'uploadHistoryViewModal',
    initialState: uploadHistoryViewModalSliceInitialState,
    reducers: {
        showUploadHistoryModal: (state, action: ShowUploadHistoryAction) => {
            state.showModal = true
            state.uploadHistory = action.payload.uploadHistory
        },
        closeUploadHistoryViewModal: (state) => {
            return uploadHistoryViewModalSliceInitialState
        },
    },
    extraReducers: (builder) => {
    },
})

export const {showUploadHistoryModal, closeUploadHistoryViewModal} = uploadHistoryViewModalSlice.actions

export const isUploadHistoryViewOpen = (state: RootState) => state.uploadHistoryViewModal.showModal
export const selectUploadHistoryViewModal = (state: RootState) => state.uploadHistoryViewModal

export default uploadHistoryViewModalSlice.reducer
