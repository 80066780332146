import {baseApi} from "./apiService";
import {PaginatedUploadHistory, UploadHistory} from "../types";

const defaultUploadHistoryFilter = {
    page: 1,
    year: undefined,
}
export const uploadHistoryService = baseApi.injectEndpoints({
        overrideExisting: false,
        endpoints: (builder) => ({
            getUploadHistoryList: builder.query<PaginatedUploadHistory, {
                page: number,
                year: number | undefined
            } | void>({
                query: (params) => ({
                    url: `upload_history`,
                    params: {
                        page: params?.page ?? defaultUploadHistoryFilter.page,
                        year: params?.year ?? defaultUploadHistoryFilter.year,
                    },
                }),
                providesTags: (result: PaginatedUploadHistory | undefined) =>
                    result
                        ? [
                            ...result.data.flatMap(({id, files = []}) => [
                                {type: 'UploadHistory' as const, id},
                                ...files.map(file => ({type: 'UploadHistoryFile' as const, id: file.id})),
                            ]),
                            {type: 'UploadHistory', id: 'LIST'},
                        ]
                        // todo this might be better to remove and keep only []
                        : [{type: 'UploadHistory', id: 'LIST'}],
            }),
            getUploadHistory: builder.query<UploadHistory, { id: string }>({
                query: ({id}) => ({
                    url: `upload_history/${id}`,
                }),
                providesTags: (result: UploadHistory | undefined) =>
                    result
                        ? [
                            {type: 'UploadHistory', id: result.id},
                            'UploadHistory',
                            ...result.files.map(({id}) => ({type: 'UploadHistoryFile' as const, id}))
                        ]
                        : ['UploadHistory'],
            }),
            createUploadHistory: builder.mutation({
                query(body) {
                    return {
                        url: `upload_history`,
                        method: 'POST',
                        body: body,
                    }
                },
                invalidatesTags: [{type: 'UploadHistory'}, {type: 'Files'}],
            })
        }),
    }
)

export const {
    useCreateUploadHistoryMutation,
    useGetUploadHistoryQuery,
    useGetUploadHistoryListQuery,
} = uploadHistoryService
