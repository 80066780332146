import React from "react";
import {
    Avatar,
    Box,
    Flex,
    Heading,
    Stack,
    Text,
    useColorModeValue
} from "@chakra-ui/react";

interface Props {
    children: React.ReactNode
}

export const Step = (props: Props) => {
    const {children} = props

    return <Box>{children}</Box>
}

export const StepContent = (props: Props) => {
    const {children} = props

    return (
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            boxShadow={'lg'}
            p={8}
            rounded={'xl'}
            align={'center'}
            pos={'relative'}
            _after={{
                content: `""`,
                w: 0,
                h: 0,
                borderLeft: 'solid transparent',
                borderLeftWidth: 16,
                borderRight: 'solid transparent',
                borderRightWidth: 16,
                borderTop: 'solid',
                borderTopWidth: 16,
                borderTopColor: useColorModeValue('white', 'gray.800'),
                pos: 'absolute',
                bottom: '-16px',
                left: '50%',
                transform: 'translateX(-50%)',
            }}>
            {children}
        </Stack>
    )
}

export const StepHeading = (props: Props) => {
    const {children} = props

    return (
        <Heading textAlign={'center'} as={'h3'} fontSize={'xl'}>
            {children}
        </Heading>
    )
}

export const StepText = (props: Props) => {
    const {children} = props

    return (
        <Text
            textAlign={'center'}
            color={useColorModeValue('gray.600', 'gray.400')}
            fontSize={'sm'}>
            {children}
        </Text>
    )
}

export const StepAvatar = ({
                               step,
                           }: {
    step: string
}) => {
    return (
        <Flex align={'center'} mt={8} direction={'column'}>
            <Avatar name={step} mb={2}/>
        </Flex>
    )
}
