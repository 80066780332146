import React from "react";
import {
    Box, Flex, Skeleton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";

export default function TableLoader({isLoading, columns, numRows = 5}: {
    isLoading: boolean;
    columns: (string | null)[] | null[];
    numRows?: number;
}) {
    const {t} = useTranslation()

    return (
        <Box
            bg={"white"}
            rounded={"2xl"}
            border={"1px primary var(--chakra-colors-gray-200)"}
            mt={"6"}
        >
            <TableContainer w={"full"}>
                <Table variant="striped">
                    <Thead>
                        <Tr sx={{th: {color: "black", textAlign: "center"}}}>
                            {columns.map((col, index) => (
                                <Th key={index}>
                                    <Flex justify="center" align={'center'} gap={"2px"}>
                                        {col !== null ? t(col): undefined}
                                    </Flex>
                                </Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody
                        sx={{
                            td: {color: "gray.700", textAlign: "center"},
                            "tr:nth-of-type(odd) td": {bg: "gray.50"},
                        }}
                    >
                        {[...Array(numRows)].map((_, rowIndex) => (
                            <Tr key={rowIndex}>
                                {columns.map((_, colIndex) => (
                                    <Td key={colIndex}>
                                        <Skeleton height="20px"/>
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
};
