import {baseApi} from "../apiService";
import {PaginatedUsers, UserImpersonation} from "../../types";

export const userAdminApi = baseApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAdminUsersList: builder.query<PaginatedUsers, number|void>({
            query: (page = 1) => ({
                url: 'admin/users',
                params: {page: page},
            }),
            providesTags: (result: PaginatedUsers | undefined) =>
                result
                    ? [
                        ...result.data.map(({id}) => ({type: 'AdminUsers' as const, id})),
                        {type: 'AdminUsers', id: 'LIST'},
                    ]
                    // todo this might be better to remove and keep only []
                    : [{type: 'AdminUsers', id: 'LIST'}],
        }),
        getImpersonateToken: builder.mutation<UserImpersonation, string>({
            query: (id) => ({
                url: `admin/users/${id}/impersonate`,
            }),
        }),
    }),
})

export const {
    useGetAdminUsersListQuery,
    useGetImpersonateTokenMutation,
} = userAdminApi
