import React, {useEffect} from "react";
import {
    Box, Button,
    Center,
    Heading, HStack,
    Spacer, Tag, Text,
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {IoCard} from "react-icons/io5";
import {useCreateCustomerSessionMutation, useGetSubscriptionQuery} from "../../services/stripeSubscriptionService";
import {hasSubscription} from "../../utils/userRoleHelper";
import {useNavigate} from "react-router-dom";
import {isAgencyClient, isEnterprise} from "../../utils/generalHelper";

export default function Subscription({user}: { user: any }) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [createCustomerSession, {data: customerUrl}] = useCreateCustomerSessionMutation()
    const {data: subscription} = useGetSubscriptionQuery(undefined);

    useEffect(() => {
        if (customerUrl) {
            window.location.href = customerUrl.url;
        }
    }, [customerUrl]);

    //
    // const getUsageColor = (usage) => {
    //     switch (true) {
    //         case (usage < 60):
    //             return 'teal';
    //         case (usage < 80):
    //             return 'orange';
    //         default:
    //             return 'red';
    //     }
    // }

    return (
        <Box p={8} bg={"#fff"} mt={4} borderRadius={"lg"}>
            <Center mb={2}>
                <HStack spacing={4}>
                    <Heading size={"sm"}>{t('subscriptions.subscription')}</Heading>
                    {/*todo if not valid show msg and red*/}
                    <Tag size={'md'} variant='solid' colorScheme='teal'>
                        {t(`subscriptions.accountTypes.${user?.company?.account_type}`)}
                    </Tag>
                    {subscription?.status === 'trialing'
                        ? <Tag size={'md'} variant='subtle' colorScheme='orange'>
                            {t('subscriptions.trial')}
                        </Tag>
                        : null
                    }
                </HStack>
                <Spacer/>
                {isEnterprise(user?.company) || isAgencyClient(user?.company)
                    ? undefined
                    : hasSubscription(subscription)
                        ? <Button
                            variant={"outline"}
                            w={"auto"}
                            onClick={() => createCustomerSession(undefined)}
                            leftIcon={
                                <IoCard color="var(--chakra-colors-teal-400)"/>
                            }
                        >
                            {t('subscriptions.manage')}
                        </Button>
                        : <Button
                            variant={"outline"}
                            w={"auto"}
                            onClick={() => navigate('/subscription')}
                            leftIcon={
                                <IoCard color="var(--chakra-colors-teal-400)"/>
                            }
                        >
                            {t('subscriptions.addSubscription')}
                        </Button>
                }
            </Center>
            <Text mb="4">
                {isEnterprise(user?.company)
                    ? t('subscriptions.noteEnterprise')
                    : isAgencyClient(user?.company)
                        ? t('subscriptions.noteAgencyClient')
                        : t('subscriptions.note')}
            </Text>
        </Box>
    );
}
