import React from "react";
import {
    Box, Flex,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Text
} from "@chakra-ui/react";
import {flexRender, Table as TanstackTable} from "@tanstack/react-table";
import {IoArrowDown, IoArrowUp, IoSwapVertical} from "react-icons/io5";
import {useTranslation} from "react-i18next";

export default function TableWrapper({table, noDataMessage = null}: { table: TanstackTable<any>, noDataMessage?: string|null }) {
    const {t} = useTranslation();
    // Chakra UI with Search and sort!
    // https://codesandbox.io/s/chakra-ui-react-table-0ojzil?file=/App.tsx

    return (
        <Box
            bg={"white"}
            rounded={"2xl"}
            border={"1px primary var(--chakra-colors-gray-200)"}
            mt={"6"}
        >
            {/*<div className="px-1 border-b border-black">*/}
            {/*    <label>*/}
            {/*        <input*/}
            {/*            {...{*/}
            {/*                type: 'checkbox',*/}
            {/*                checked: table.getIsAllColumnsVisible(),*/}
            {/*                onChange: table.getToggleAllColumnsVisibilityHandler(),*/}
            {/*            }}*/}
            {/*        />{' '}*/}
            {/*        Toggle All*/}
            {/*    </label>*/}
            {/*</div>*/}
            {/*{table.getAllLeafColumns().map(column => {*/}
            {/*    return (*/}
            {/*        <div key={column.id} className="px-1">*/}
            {/*            <label>*/}
            {/*                <input*/}
            {/*                    {...{*/}
            {/*                        type: 'checkbox',*/}
            {/*                        checked: column.getIsVisible(),*/}
            {/*                        onChange: column.getToggleVisibilityHandler(),*/}
            {/*                    }}*/}
            {/*                />{' '}*/}
            {/*                {column.id}*/}
            {/*            </label>*/}
            {/*        </div>*/}
            {/*    )*/}
            {/*})}*/}
            <TableContainer w={"full"}>
                <Table variant="striped">
                    <Thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <Tr key={headerGroup.id} sx={{th: {color: "black", textAlign: "center"}}}>
                                {headerGroup.headers.map(header => (
                                    <Th key={header.id} {...{
                                        cursor: header.column.getCanSort() ? 'pointer' : 'default',
                                        onClick: header.column.getToggleSortingHandler(),
                                    }}>
                                        {header.isPlaceholder
                                            ? null
                                            : <Flex justify="center" align={'center'} gap={"2px"}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {header.column.getCanSort() && header.column.getIsSorted() === false
                                                    ? <IoSwapVertical/>
                                                    : header.column.getIsSorted() !== false ? (
                                                        {
                                                            asc: <IoArrowUp/>,
                                                            desc: <IoArrowDown/>,
                                                        }[header.column.getIsSorted() as string] ?? null
                                                    ) : null}
                                            </Flex>
                                        }
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody
                        sx={{
                            td: {color: "gray.700", textAlign: "center"},
                            "tr:nth-of-type(odd) td": {bg: "gray.50"},
                        }}
                    >
                        {table.getRowModel().rows.map(row => (
                            <Tr key={row.id}>
                                {row.getVisibleCells().map(cell => {
                                        //todo fix correct as any
                                        const meta = (cell.column.columnDef as any).meta;
                                        if (meta?.customTd === true) {
                                            return (flexRender(cell.column.columnDef.cell, cell.getContext()))
                                        }

                                        return (
                                            <Td
                                                key={cell.id}
                                                {...(meta ? meta.customStyles : {})}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </Td>
                                        )
                                    }
                                )}
                            </Tr>
                        ))}
                        {table.getRowModel().rows.length === 0 && noDataMessage !== null
                            ?   <Tr>
                                <Td colSpan={table.getHeaderGroups().map(obj => obj.headers).flat().length}>
                                    <Text textAlign="center" py={5}>{t(noDataMessage)}</Text>
                                </Td>
                            </Tr>
                            : undefined
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
};
