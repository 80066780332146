import {TaskStatus} from "../constants/status";

export const getTaskColour = (status) => {
    switch (status) {
        case (TaskStatus.new):
            return 'blue';
        case (TaskStatus.open):
            return 'orange';
        case (TaskStatus.done):
            return 'green';
        case (TaskStatus.closed):
            return 'red';
        default:
            return null;
    }
}

export const getEscalationColour = (timeLeft, status) => {
    if (status === TaskStatus.done || status === TaskStatus.closed) {
        return 'green'
    }

    switch (true) {
        case (timeLeft < 0):
            return 'red';
        case (timeLeft < 6):
            return 'orange';
        default:
            return 'blue';
    }
}

export const getTasksCountColor = (tasks = []) => {
    const countFinished = tasks?.filter(task => (task.status === TaskStatus.done || task.status === TaskStatus.closed))?.length ?? 0
    const countAll = tasks?.length ?? 0

    if (countAll === 0) {
        return 'gray'
    }

    if (countFinished === countAll) {
        return 'green'
    }

    if (countFinished < countAll) {
        return 'orange'
    }

    return 'gray'
}
