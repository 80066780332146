import {
    Box,
    Button,
    Container,
    Heading,
    Text,
} from "@chakra-ui/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export default function NotFound() {
    const {t} = useTranslation()
    const navigate = useNavigate()

    return (
        <Container maxW={"container.xl"} p={4}>
            <Box textAlign="center" py={10} px={6}>
                <Heading
                    display="inline-block"
                    as="h2"
                    size="2xl"
                    bgGradient="linear(to-r, teal.400, teal.600)"
                    backgroundClip="text">
                    404
                </Heading>
                <Text fontSize="18px" mt={3} mb={2}>
                    {t('notFound.title')}
                </Text>
                <Text color={'gray.500'} mb={6}>
                    {t('notFound.text')}
                </Text>

                <Button
                    onClick={() => navigate('/')}
                    colorScheme="teal"
                    bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                    color="white"
                    variant="solid">
                    {t('notFound.action')}
                </Button>
            </Box>
        </Container>
    );
}
