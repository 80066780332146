import {
    Box,
    Flex,
    Stack,
    Heading,
    Text,
    Container,
    Input,
    Button,
    SimpleGrid,
    Avatar,
    AvatarGroup,
    useBreakpointValue,
    IconProps,
    Icon,
    FormControl,
    FormLabel,
    FormHelperText,
    InputGroup,
    GridItem,
    InputLeftAddon,
    Textarea, Progress, FormErrorMessage, InputLeftElement, InputRightAddon, List, ListItem, ListIcon,
} from '@chakra-ui/react';
import React, {useState} from "react";
import {useRegisterMutation} from "../services/registerService";
import {AccountType} from "../types";
import {useLoginMutation} from "../services/userService";
import {isLoggedIn} from "../slices/authSlice";
import {Navigate, useNavigate} from "react-router-dom";
import {useAuth} from "../hooks/useAuth";
import {useTranslation} from "react-i18next";
import * as yup from 'yup'
import YupPassword from 'yup-password'
import {Field, Form, Formik, FormikErrors, FormikHandlers, FormikTouched, FormikValues} from "formik";
import AppInput from "../components/AppInput";
import {GoKey} from "react-icons/go";
import {AiFillEye, AiFillEyeInvisible} from "react-icons/ai";
import AppSelect from "../components/AppSelect";
import {IoInformationCircle} from "react-icons/io5";

YupPassword(yup)

const avatars = [
    {
        name: 'Ryan Florence',
        url: 'https://bit.ly/ryan-florence',
    },
    {
        name: 'Segun Adebayo',
        url: 'https://bit.ly/sage-adebayo',
    },
    {
        name: 'Kent Dodds',
        url: 'https://bit.ly/kent-c-dodds',
    },
    {
        name: 'Prosper Otemuyiwa',
        url: 'https://bit.ly/prosper-baba',
    },
    {
        name: 'Christian Nwamba',
        url: 'https://bit.ly/code-beast',
    },
];

interface RegisterFormValues {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    company: {
        name: string;
        legal_name: string;
        public_email: string;
        account_type: AccountType,
    }
}


const Form1 = ({
                   errors,
                   handleBlur,
                   touched
               }: {
    errors: FormikErrors<RegisterFormValues>,
    handleBlur: FormikHandlers['handleBlur'],
    touched: FormikTouched<RegisterFormValues>
}) => {
    const [show, setShow] = React.useState(false);
    const {t} = useTranslation()

    const handleClick = () => setShow(!show);
    return (
        <>
            <Heading w="100%" size={'md'} fontWeight="normal" mb="2%">
                {t('register.userDetails')}
            </Heading>
            <Flex>
                <FormControl mr="5%" isInvalid={!!errors.first_name && !!touched.first_name}>
                    <AppInput name={`first_name`} handleBlur={handleBlur}
                              label={t('users.firstName')}
                              placeholder={t('users.firstName')}/>
                    <FormErrorMessage>{errors.first_name}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.last_name && !!touched.last_name}>
                    <AppInput name={`last_name`} handleBlur={handleBlur}
                              label={t('users.lastName')}
                              placeholder={t('users.lastName')}/>
                    <FormErrorMessage>{errors.last_name}</FormErrorMessage>
                </FormControl>
            </Flex>
            <FormControl mt="2%" isInvalid={!!errors.email && !!touched.email}>
                <AppInput name={`email`} handleBlur={handleBlur}
                          label={t('users.email')}
                          placeholder={t('users.email')}/>
                <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.password && touched.password}>
                <FormLabel htmlFor="password" fontSize={14} fontWeight={600} mt="2%">
                    {t('login.password')}
                </FormLabel>
                <InputGroup>
                    <InputLeftElement pointerEvents="none" children={<GoKey/>}/>
                    <Input
                        as={Field}
                        name={'password'}
                        onBlur={handleBlur}
                        type={show ? 'text' : 'password'}
                        placeholder={t('login.password') as string}/>
                    <InputRightAddon
                        cursor={"pointer"}
                        sx={{}}
                        onClick={handleClick}
                        children={show ? <AiFillEyeInvisible/> : <AiFillEye/>}
                    />
                </InputGroup>
                <FormErrorMessage>{errors.password}</FormErrorMessage>
            </FormControl>
        </>
    );
};

const Form2 = ({
                   errors,
                   values,
                   handleBlur,
                   handleChange,
                   touched
               }: {
    errors: FormikErrors<RegisterFormValues>,
    values: FormikValues,
    handleBlur: FormikHandlers['handleBlur'],
    handleChange: FormikHandlers['handleChange']
    touched: FormikTouched<RegisterFormValues>
}) => {
    const {t} = useTranslation()

    return (
        <>
            <Heading w="100%" size={'md'} fontWeight="normal" mb="2%">
                {t('register.companyDetails')}
            </Heading>
            <FormControl as={GridItem} colSpan={[6, 3]}>
                <GridItem>
                    <FormControl isInvalid={!!(errors.company?.account_type && touched.company?.account_type)}>
                        <AppSelect name={`company.account_type`}
                                   onChange={handleChange}
                                   handleBlur={handleBlur}
                                   value={values.company.account_type}
                                   label={'subscriptions.accountType'}
                            // placeholder={t('common.select')}
                        >
                            <option disabled={true}
                                    value={AccountType.personal}>{t(`subscriptions.accountTypes.${AccountType.personal}`)}</option>
                            <option
                                value={AccountType.company}>{t(`subscriptions.accountTypes.${AccountType.company}`)}</option>
                            <option
                                value={AccountType.agency}>{t(`subscriptions.accountTypes.${AccountType.agency}`)}</option>
                        </AppSelect>
                        <FormErrorMessage>{errors.company?.account_type}</FormErrorMessage>
                    </FormControl>
                </GridItem>
            </FormControl>
            <GridItem>
                <List spacing={2}>
                    <ListItem>
                        <ListIcon as={IoInformationCircle} color='teal.500' />
                        <b>{t(`subscriptions.accountTypes.${AccountType.personal}`)}:</b> {t(`register.${AccountType.personal}Note`)}
                    </ListItem>
                    <ListItem>
                        <ListIcon as={IoInformationCircle} color='teal.500' />
                        <b>{t(`subscriptions.accountTypes.${AccountType.company}`)}:</b> {t(`register.${AccountType.company}Note`)}
                    </ListItem>
                    <ListItem>
                        <ListIcon as={IoInformationCircle} color='teal.500' />
                        <b>{t(`subscriptions.accountTypes.${AccountType.agency}`)}:</b> {t(`register.${AccountType.agency}Note`)}
                    </ListItem>
                </List>
            </GridItem>
            <FormControl as={GridItem} colSpan={6} isInvalid={!!(errors.company?.name && touched.company?.name)}>
                <AppInput name={`company.name`} handleBlur={handleBlur}
                          isDisabled={values.company?.account_type === AccountType.personal}
                          label={t('company.name')}
                          placeholder={t('company.name')}
                />
                <FormErrorMessage>{errors.company?.name}</FormErrorMessage>
            </FormControl>

            <FormControl as={GridItem} colSpan={[6, 6, null, 2]}
                         isInvalid={!!(errors.company?.legal_name && touched.company?.legal_name)}>
                <AppInput name={`company.legal_name`} handleBlur={handleBlur}
                          isDisabled={values.company?.account_type === AccountType.personal}
                          label={t('company.legalName')}
                          placeholder={t('company.legalName')}
                />
                <FormErrorMessage>{errors.company?.legal_name}</FormErrorMessage>
            </FormControl>

            <FormControl as={GridItem} colSpan={[6, 3, null, 2]}
                         isInvalid={!!(errors.company?.public_email && touched.company?.public_email)}>
                <AppInput name={`company.public_email`} handleBlur={handleBlur}
                          isDisabled={values.company?.account_type === AccountType.personal}
                          label={t('company.publicEmail')}
                          placeholder={t('company.publicEmail')}
                />
                <FormErrorMessage>{errors.company?.public_email}</FormErrorMessage>
            </FormControl>
        </>
    );
};

const Form3 = () => {
    return (
        <>
            <Heading w="100%" textAlign={'center'} fontWeight="normal">
                Social Handles
            </Heading>
            <SimpleGrid columns={1} spacing={6}>
                <FormControl as={GridItem} colSpan={[3, 2]}>
                    <FormLabel
                        fontSize="sm"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                            color: 'gray.50',
                        }}>
                        Website
                    </FormLabel>
                    <InputGroup size="sm">
                        <InputLeftAddon
                            bg="gray.50"
                            _dark={{
                                bg: 'gray.800',
                            }}
                            color="gray.500"
                            rounded="md">
                            http://
                        </InputLeftAddon>
                        <Input
                            type="tel"
                            placeholder="www.example.com"
                            focusBorderColor="brand.400"
                            rounded="md"
                        />
                    </InputGroup>
                </FormControl>

                <FormControl id="email" mt={1}>
                    <FormLabel
                        fontSize="sm"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                            color: 'gray.50',
                        }}>
                        About
                    </FormLabel>
                    <Textarea
                        placeholder="you@example.com"
                        rows={3}
                        shadow="sm"
                        focusBorderColor="brand.400"
                        fontSize={{
                            sm: 'sm',
                        }}
                    />
                    <FormHelperText>
                        Brief description for your profile. URLs are hyperlinked.
                    </FormHelperText>
                </FormControl>
            </SimpleGrid>
        </>
    );
};


//1 - Email password First Last Name (Personal use, Company use Agency use)
//2 - Company view (not shown if personal use) Create company with dummy data from username and mark it as personal
//3 - Show suitable Subscriptions If personal offer Personal. Login user, redirect with subscription maker on login check.
// - If Company offer Business or Premium plus add selector for yearly and amount
// - Same for agency (Maybe add agency package in stripe not sure tho)

export default function JoinOurTeam() {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const size = useBreakpointValue({base: 'md', md: 'lg'});
    const [step, setStep] = useState(1);
    const [progress, setProgress] = useState(0.0);
    const auth = useAuth()
    const [register, {isLoading}] = useRegisterMutation();
    const [login, {isLoading: isLoggingIn}] = useLoginMutation()


    const schema = yup.object({
        first_name: yup.string().min(2).required(),
        last_name: yup.string().min(2).required(),
        email: yup.string().email().required(),
        password: yup.string().min(8).password()
            .minLowercase(1)
            .minUppercase(1)
            .minSymbols(0)
            .required(),
        company: yup.object({
            name: yup.string().min(2).required(),
            legal_name: yup.string().min(2).required(),
            public_email: yup.string().email().required(),
            account_type: yup.string().oneOf([AccountType.personal, AccountType.company, AccountType.agency]),
        }).required(),
    });

    const breakPoint = useBreakpointValue({base: '44px', md: '60px'});

    if (isLoggedIn(auth)) {
        return <Navigate to="/"/>
    }

    return (
        <Box position={'relative'}>
            <Container
                as={SimpleGrid}
                maxW={'7xl'}
                columns={{base: 1, md: 2}}
                spacing={{base: 10, lg: 32}}
                py={{base: 10, sm: 20, lg: 32}}>
                <Stack spacing={{base: 10, md: 20}}>
                    <Heading
                        lineHeight={1.1}
                        fontSize={{base: '3xl', sm: '4xl', md: '5xl', lg: '6xl'}}>
                        {t('register.leading1')}{' '}
                        <Text
                            as={'span'}
                            bgGradient="linear(to-r, teal.300,teal.400)"
                            bgClip="text">
                            &
                        </Text>{' '}
                        {t('register.leading2')}
                    </Heading>
                    <Stack direction={'row'} spacing={4} align={'center'}>
                        <AvatarGroup>
                            {avatars.map((avatar) => (
                                <Avatar
                                    key={avatar.name}
                                    name={avatar.name}
                                    src={avatar.url}
                                    size={size}
                                    position={'relative'}
                                    zIndex={2}
                                    _before={{
                                        content: '""',
                                        width: 'full',
                                        height: 'full',
                                        rounded: 'full',
                                        transform: 'scale(1.125)',
                                        bgGradient: 'linear(to-bl, teal.300,teal.400)',
                                        position: 'absolute',
                                        zIndex: -1,
                                        top: 0,
                                        left: 0,
                                    }}
                                />
                            ))}
                        </AvatarGroup>
                        <Text fontFamily={'heading'} fontSize={{base: '4xl', md: '6xl'}}>
                            +
                        </Text>
                        <Flex
                            align={'center'}
                            justify={'center'}
                            fontFamily={'heading'}
                            fontSize={{base: 'sm', md: 'lg'}}
                            bg={'gray.800'}
                            color={'white'}
                            rounded={'full'}
                            minWidth={breakPoint}
                            minHeight={breakPoint}
                            position={'relative'}
                            _before={{
                                content: '""',
                                width: 'full',
                                height: 'full',
                                rounded: 'full',
                                transform: 'scale(1.125)',
                                bgGradient: 'linear(to-bl, orange.400,yellow.400)',
                                position: 'absolute',
                                zIndex: -1,
                                top: 0,
                                left: 0,
                            }}>
                            {t('register.you')}
                        </Flex>
                    </Stack>
                </Stack>
                <Stack
                    bg={'gray.50'}
                    rounded={'xl'}
                    p={{base: 4, sm: 6, md: 8}}
                    spacing={{base: 8}}
                    maxW={{lg: 'lg'}}>
                    <Stack spacing={4}>
                        <Heading
                            color={'gray.800'}
                            lineHeight={1.1}
                            fontSize={{base: '2xl', sm: '3xl', md: '4xl'}}>
                            {t('register.join')}
                            <Text
                                as={'span'}
                                bgGradient="linear(to-r, teal.300,teal.400)"
                                bgClip="text">
                                !
                            </Text>
                        </Heading>
                        {/*<Text color={'gray.500'} fontSize={{base: 'sm', sm: 'md'}}>*/}
                        {/*    We’re looking for amazing engineers just like you! Become a part*/}
                        {/*    of our rockstar engineering team and skyrocket your career!*/}
                        {/*</Text>*/}
                    </Stack>
                    <Formik
                        validationSchema={schema}
                        validateOnMount={true}
                        onSubmit={(values, {setSubmitting}) => {
                            setSubmitting(true);
                            setProgress(100.0);

                            register(values).unwrap()
                                .then(() => login({
                                    username: values.email,
                                    password: values.password
                                }).unwrap().then(() => navigate('/subscription')))
                                .catch()
                            // .catch(() => dispatch(invalidLoginToast()))

                            setSubmitting(false);
                        }}
                        enableReinitialize={true}
                        initialValues={{
                            first_name: '',
                            last_name: '',
                            email: ``,
                            password: '',
                            company: {
                                name: '',
                                legal_name: '',
                                public_email: '',
                                logo: null,
                                account_type: AccountType.company,
                            },
                            language: i18n.language,
                        }}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              handleReset,
                              handleBlur,
                              values,
                              dirty,
                              touched,
                              isValid,
                              isSubmitting,
                              errors,
                              setFieldValue,
                              setFieldTouched,
                          }) => (
                            <Box as={Form} mt={10}>
                                <Stack spacing={4}>
                                    {step === 1
                                        ? <Form1 errors={errors} handleBlur={handleBlur} touched={touched}/>
                                        : <Form2 errors={errors}
                                                 values={values}
                                                 handleBlur={handleBlur}
                                                 handleChange={handleChange}
                                                 touched={touched}/>
                                        // : <Form3/>
                                    }

                                    <Progress
                                        hasStripe
                                        value={progress}
                                        mb="5%"
                                        mx="5%"
                                        isAnimated/>
                                    <Flex w="100%" justifyContent="space-between">
                                        <Flex>
                                            <Button
                                                onClick={() => {
                                                    setStep(step - 1);
                                                    setProgress(progress - 50.0);
                                                }}
                                                isDisabled={step === 1}
                                                colorScheme="teal"
                                                variant="solid"
                                                w="7rem"
                                                mr="5%">
                                                Back
                                            </Button>
                                            <Button
                                                w="7rem"
                                                isDisabled={step === 2}
                                                onClick={() => {
                                                    setStep(step + 1);
                                                    setProgress(50.0);
                                                    // if (step === 3) {
                                                    //     setProgress(100);
                                                    // } else {
                                                    //     setProgress(progress + 50.0);
                                                    // }
                                                }}
                                                colorScheme="teal"
                                                variant="outline">
                                                Next
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Stack>
                                {step === 2 ?
                                    <Button
                                        fontFamily={'heading'}
                                        mt={8}
                                        w={'full'}
                                        type={'submit'}
                                        bgGradient="linear(to-r, teal.300,teal.400)"
                                        color={'white'}
                                        isDisabled={!isValid}
                                        isLoading={isLoading || isLoggingIn}
                                        _hover={{
                                            bgGradient: 'linear(to-r, teal.300,teal.500)',
                                            boxShadow: 'xl',
                                        }}>
                                        {t('common.actions.register')}
                                    </Button>
                                    : null}
                            </Box>)
                        }
                    </Formik>
                </Stack>
            </Container>
            {/*<Blur*/}
            {/*    position={'absolute'}*/}
            {/*    top={-10}*/}
            {/*    left={-10}*/}
            {/*    style={{ filter: 'blur(70px)' }}*/}
            {/*/>*/}
        </Box>
    );
}

export const Blur = (props: IconProps) => {
    return (
        <Icon
            width={useBreakpointValue({base: '100%', md: '40vw', lg: '30vw'})}
            zIndex={useBreakpointValue({base: -1, md: -1, lg: 0})}
            height="560px"
            viewBox="0 0 528 560"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="71" cy="61" r="111" fill="#F56565"/>
            <circle cx="244" cy="106" r="139" fill="#ED64A6"/>
            <circle cy="291" r="139" fill="#ED64A6"/>
            <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936"/>
            <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B"/>
            <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78"/>
            <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1"/>
        </Icon>
    );
};
