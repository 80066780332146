import {Box, Container, Heading} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import TasksHeader from "../components/Tasks/TasksHeader";
import TasksTable from "../components/Tasks/TasksTable";
import TasksFilterModal from "../components/Tasks/TasksFilter.Modal";
import {useGetTasksListQuery, useLazyGetTaskQuery} from "../services/taskService";
import TaskView from "../components/Tasks/TaskView";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {isTaskViewOpen, showTaskViewModal} from "../slices/taskViewModalSlice";
import { useTranslation } from "react-i18next";
import {
    Pagination,
    PaginationContainer, PaginationNext, PaginationPage,
    PaginationPageGroup,
    PaginationPrevious,
    PaginationSeparator,
    usePagination
} from "@ajna/pagination";
import {IoChevronBackCircleOutline, IoChevronForwardCircleOutline} from "react-icons/io5";
import {
    isTaskSortOpen,
    selectTaskSortModal,
    clearTaskSort,
    closeTaskSortModal,
    setModalSorting
} from "../slices/taskSortModalSlice";
import {selectTaskAssigneeFilter, selectTaskStatusFilter} from "../slices/taskFilterSlice";
import SortModal from "../components/SortModal";

export default function TasksList() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const {t} = useTranslation()

    let {taskId} = useParams();
    const isModalOpen = useSelector(isTaskViewOpen)
    const [filterOpen, setFilterOpen] = useState(false);
    const [dataTotal, setDataTotal] = useState(0);
    const status = useSelector(selectTaskStatusFilter);
    const assignee = useSelector(selectTaskAssigneeFilter);
    const isSortModalOpen = useSelector(isTaskSortOpen)
    const taskSortModal = useSelector(selectTaskSortModal)

    const {
        pages,
        pagesCount,
        currentPage,
        setCurrentPage,
        isDisabled,
    } = usePagination({
        total: dataTotal,
        limits: {
            outer: 3,
            inner: 3
        },
        initialState: {
            pageSize: 20,
            isDisabled: false,
            currentPage: 1
        }
    });

    const {data = {data: [], total: 0, per_page: 20}, isLoading} = useGetTasksListQuery({
        page: currentPage,
        sortField: taskSortModal.sortField,
        sortDirection: taskSortModal.sortDirection,
        status: status === 'all' ? null : status,
        assignee: assignee === 'all' ? null : assignee,
    })
    const [triggerTaskLoad, {data: task, isLoading: isLoadingTask}] = useLazyGetTaskQuery()

    const isFilled = (value) => value !== null && value !== undefined

    const sortableFields = [
        {value: 'name', label: 'tasks.name'},
        {value: 'timeLeft', label: 'tasks.timeLeft', isDefault: true},
    ];

    const handlePageChange = (nextPage) => {
        setCurrentPage(nextPage);
    };

    function handleClose() {
        setFilterOpen(false);
    }

    useEffect(() => {
        setCurrentPage(1)
    }, [setCurrentPage, status])

    useEffect(() => {
        setDataTotal(data.total)
    }, [data.total, setDataTotal])

    useEffect(() => {
        if (isFilled(taskId) && !isModalOpen) {
            if (!isLoadingTask) {
                triggerTaskLoad(taskId)
            }
            if (isFilled(task)) {
                dispatch(showTaskViewModal({task: task}))
                navigate("/tasks", {replace: true})
            }
        }
    }, [isLoadingTask, taskId, isModalOpen, task]);

    if (isLoading) {
        return (
            <Box flexDirection={"column"}>
                <Container maxW={"container.xl"} pt={"10"} px={3}>
                    <TasksHeader count={data.total}/>
                    <Heading>{t('common.actions.loading')}</Heading>
                </Container>
            </Box>
        )
    }

    return (
        <Box pb={8}>
            <TasksFilterModal isOpen={filterOpen} onClose={handleClose}/>
            <SortModal
                sortableFields={sortableFields}
                isModalOpen={isSortModalOpen}
                sortValues={taskSortModal}
                closeAction={closeTaskSortModal}
                clearAction={clearTaskSort}
                submitAction={setModalSorting}
            />
            {/*<ErrorAlert />*/}
            <Container maxW={"container.xl"} pt={"10"} px={3}>
                <TasksHeader count={data.total}/>
                <TasksTable tasks={data.data}/>

                {/*todo extract*/}
                <Pagination
                    pagesCount={pagesCount}
                    currentPage={currentPage}
                    isDisabled={isDisabled}
                    onPageChange={handlePageChange}
                >
                    <PaginationContainer
                        align="center"
                        justify="space-between"
                        p={4}
                        w="full"
                    >
                        <PaginationPrevious
                            _hover={{
                                bg: "teal.500"
                            }}
                            bg="teal.400"
                        >
                            <IoChevronBackCircleOutline/>
                        </PaginationPrevious>
                        <PaginationPageGroup
                            isInline
                            align="center"
                            separator={
                                <PaginationSeparator
                                    bg="teal.300"
                                    fontSize="sm"
                                    w={7}
                                    jumpSize={11}
                                />
                            }
                        >
                            {pages.map((page) => (
                                <PaginationPage
                                    w={7}
                                    bg="teal.400"
                                    key={`pagination_page_${page}`}
                                    page={page}
                                    fontSize="sm"
                                    _hover={{
                                        bg: "teal.500"
                                    }}
                                    _current={{
                                        bg: "teal.500",
                                        fontSize: "sm",
                                        color: "white",
                                        w: 7
                                    }}
                                />
                            ))}
                        </PaginationPageGroup>
                        <PaginationNext
                            _hover={{
                                bg: "teal.500"
                            }}
                            bg="teal.400"
                        >
                            <IoChevronForwardCircleOutline/>
                        </PaginationNext>
                    </PaginationContainer>
                </Pagination>
            </Container>
            {/*<TaskEditingView />*/}
            <TaskView/>
        </Box>
    );
}
