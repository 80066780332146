import {Box, Container} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useGetCurrentUserQuery} from "../services/userService";
import {Role} from "../types";
import {useGetUploadHistoryListQuery} from "../services/uploadHistoryService";
import {
    Pagination,
    PaginationContainer, PaginationNext, PaginationPage,
    PaginationPageGroup,
    PaginationPrevious, PaginationSeparator,
    usePagination
} from "@ajna/pagination";
import {useParams} from "react-router-dom";
import TableLoader from "./Table/TableLoader";
import {hasAllowedRole} from "../utils/userRoleHelper";
import {IoChevronBackCircleOutline, IoChevronForwardCircleOutline} from "react-icons/io5";
import UploadHistoryTable from "../components/UploadHistory/UploadHistoryTable";
import UploadHistoryView from "../components/UploadHistory/UploadHistoryView";

export default function UploadHistory() {
    const {t} = useTranslation()
    const [dataTotal, setDataTotal] = useState(0);
    let {uploadHistoryId} = useParams();
    const isModalOpen = false
    // const isModalOpen = useSelector(isFileViewOpen)
    const {data: user} = useGetCurrentUserQuery(undefined)


    const {
        pages,
        pagesCount,
        currentPage,
        setCurrentPage,
        isDisabled,
    } = usePagination({
        total: dataTotal,
        limits: {
            outer: 3,
            inner: 3
        },
        initialState: {
            pageSize: 20,
            isDisabled: false,
            currentPage: 1
        }
    });

    const {data = {data: [], total: 0, per_page: 20}, isLoading, isFetching} = useGetUploadHistoryListQuery({
        page: currentPage,
        year: undefined,
        // year: parseInt(fileFilterModal.year),
    })

    // so far none!!
    // const sortableFields = [
    //     {value: 'ids', label: 'common.ID'},
    //     {value: 'name', label: 'files.fileName'},
    //     {value: 'externalId', label: 'files.view.externalID'},
    //     {value: 'internalId', label: 'files.view.internalID'},
    //     {value: 'createdAt', label: 'files.uploadedAt', isDefault: true},
    //     {value: 'fileCreatedAt', label: 'common.createdAt'},
    // ];

    const handlePageChange = (nextPage: number) => {
        setCurrentPage(nextPage);
    };

    useEffect(() => {
        setCurrentPage(1)
    }, [setCurrentPage])

    useEffect(() => {
        setDataTotal(data.total)
    }, [data.total, setDataTotal])

    // useEffect(() => {
    //     if (isFilled(taskId) && !isModalOpen) {
    //         if (!isLoadingTask) {
    //             triggerTaskLoad(taskId)
    //         }
    //         if (isFilled(task)) {
    //             dispatch(showTaskViewModal({task: task}))
    //             navigate("/tasks", {replace: true})
    //         }
    //     }
    // }, [isLoadingTask, taskId, isModalOpen, task]);

    console.log(data)


    return (
        <Box flexDirection={"column"}>
            <Container maxW={"container.xl"} pt={"10"} px={3}>
                {/*<FilesHeader count={data.total} search={search} setSearch={setSearch}/>*/}

                {isLoading || isFetching
                    ? <TableLoader
                        isLoading={true}
                        columns={hasAllowedRole(user, [Role.User])
                            ? [
                                'common.ID',
                                'files.fileName',
                                'common.createdBy',
                                'common.createdAt',
                                'files.uploadedAt',
                                'tasks.task',
                                null]
                            : [
                                'common.ID',
                                'files.fileName',
                                'common.createdBy',
                                'common.createdAt',
                                'files.uploadedAt',
                                null]
                        }
                    />
                    : <UploadHistoryTable
                        uploadHistory={data.data}
                        hiddenFields={hasAllowedRole(user, [Role.User]) ? [] : ['tasks']}
                    />
                }

                {/*todo extract*/}
                <Pagination
                    pagesCount={pagesCount}
                    currentPage={currentPage}
                    isDisabled={isDisabled}
                    onPageChange={handlePageChange}
                >
                    <PaginationContainer
                        align="center"
                        justify="space-between"
                        p={4}
                        w="full"
                    >
                        <PaginationPrevious
                            _hover={{
                                bg: "teal.500"
                            }}
                            bg="teal.400"
                        >
                            <IoChevronBackCircleOutline/>
                        </PaginationPrevious>
                        <PaginationPageGroup
                            isInline
                            align="center"
                            separator={
                                <PaginationSeparator
                                    bg="teal.300"
                                    fontSize="sm"
                                    w={7}
                                    jumpSize={11}
                                />
                            }
                        >
                            {pages.map((page) => (
                                <PaginationPage
                                    w={7}
                                    bg="teal.400"
                                    key={`pagination_page_${page}`}
                                    page={page}
                                    fontSize="sm"
                                    _hover={{
                                        bg: "teal.500"
                                    }}
                                    _current={{
                                        bg: "teal.500",
                                        fontSize: "sm",
                                        color: "white",
                                        w: 7
                                    }}
                                />
                            ))}
                        </PaginationPageGroup>
                        <PaginationNext
                            _hover={{
                                bg: "teal.500"
                            }}
                            bg="teal.400"
                        >
                            <IoChevronForwardCircleOutline/>
                        </PaginationNext>
                    </PaginationContainer>
                </Pagination>
            </Container>
            <UploadHistoryView/>
        </Box>
    );
}
