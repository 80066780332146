import {
    Box, Center, Heading, HStack, Spacer,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {useGetAgencyCompaniesListQuery} from "../../services/agencyService";


export default function ClientsTable() {
    const {t} = useTranslation()
    const {data, isLoading} = useGetAgencyCompaniesListQuery()
    // const [switchCompany, {isLoading: isLoadingCompanySwitch}] = useSwitchCompanyMutation()

    if (isLoading) {
        return (
            <Box p={8} bg={"#fff"} mt={4} borderRadius={"lg"}>
                <Center mb={2}>
                    <HStack spacing={4}>
                        <Heading size={"sm"}>{t('agency.client', {count: 2})}</Heading>
                    </HStack>
                    <Spacer/>
                </Center>
                {/*todo late pagination and sort*/}
                <TableContainer w={"full"}>
                    <Table variant="striped">
                        <Thead>
                            <Tr sx={{th: {color: "black"}}}>
                                <Th>{t('company.name')} </Th>
                                <Th>{t('company.publicEmail')} </Th>
                            </Tr>
                        </Thead>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    return (
        <Box py={8} bg={"#fff"} mt={4} borderRadius={"lg"}>
            <Center px={8} mb={2}>
                <HStack spacing={4}>
                    <Heading size={"sm"}>{t('agency.client', {count: 2})}</Heading>
                </HStack>
                <Spacer/>
            </Center>
            {/*todo late pagination and sort*/}
            <TableContainer w={"full"}>
                <Table variant="striped">
                    <Thead>
                        <Tr sx={{th: {color: "black", textAlign: "center"}}}>
                            <Th>{t('company.name')} </Th>
                            <Th>{t('company.publicEmail')} </Th>
                        </Tr>
                    </Thead>
                    <Tbody
                        sx={{
                            td: {color: "gray.700", textAlign: "center"},
                            "tr:nth-of-type(odd) td": {bg: "gray.50"},
                        }}
                    >
                        {data?.map((company, i: number) => {
                            return (
                                <Tr key={company.id + i}>
                                    <Td>{company.name}</Td>
                                    <Td>{company.public_email}</Td>
                                    {/*<Td><Button*/}
                                    {/*    onClick={() => switchCompany(company.id)*/}
                                    {/*        .unwrap()*/}
                                    {/*        .then(() => window.location.reload())*/}
                                    {/*        .catch()}>{t('admin.users.impersonate')}</Button>*/}
                                    {/*</Td>*/}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
}
