import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React from "react";
import {useLazyGetDownloadFileQuery} from "../../services/fileService";
import {isFilled, isNoAccessFile} from "../../utils/generalHelper";
import {Button, Center, Flex, Td,} from "@chakra-ui/react";
import {
    createColumnHelper,
    getCoreRowModel,
    OnChangeFn,
    SortingState,
    useReactTable,
    VisibilityState
} from "@tanstack/react-table";
import {BsFileEarmarkCheckFill, BsFillCloudDownloadFill} from "react-icons/bs";
import {getTasksCountColor} from "../../utils/taskHelper";
import {CellTypes, File, NoAccessFile, TaskStatus} from "../../types";
import {closeFileViewModal, showFileViewModal} from "../../slices/fileViewModalSlice";
import {AiFillEye} from "react-icons/ai";
import TableWrapper from "../../pages/Table/TableWrapper";
import TableCell from "../../pages/Table/TableCell";
import {selectFileRawSorting, setSorting} from "../../slices/fileSortModalSlice";

export default function FilesTable(
    {
        files = [],
        hiddenFields = [],
        inModal = false,
        customAction = undefined,
        customActionArgs = []
    }: {
        files: (File | NoAccessFile)[],
        hiddenFields?: string[],
        inModal?: boolean,
        customAction?: (info: any, ...args: any[]) => void,
        // customActionArgs?: any[]
        customActionArgs?: any
    }) {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [trigger, {isLoading: isLoadingTrigger}] = useLazyGetDownloadFileQuery()
    const sorting = useSelector(selectFileRawSorting)

    //todo extract
    const getTranslatedText = (key: string, count: number = 1): string => {
        return t(key, {count: count}) ?? key;
    };

    const columnHelper = createColumnHelper<File | NoAccessFile>()
    const columns = [
            //todo implement custom filter function to handle both of them!!
            columnHelper.accessor(row => `${row.internal_id} ${row.external_id}`, {
                id: 'ids',
                header: getTranslatedText('common.ID'),
                cell: info => <TableCell
                    content={info.row.original.internal_id}
                    subContent={info.row.original.external_id}
                    placeholder={info.row.original.id}
                    isContentFilled={isFilled(info.row.original.internal_id) || isFilled(info.row.original.external_id)}
                    type={CellTypes.CombinedIds}
                    maxLength={11}
                />,
                enableSorting: true,
            }),
            columnHelper.accessor(row => row.name ?? row.original_name, {
                id: 'name',
                header: getTranslatedText('files.fileName'),
                cell: info => isNoAccessFile(info.row.original)
                    ? <TableCell
                        content={t('files.noAccess')}
                        shorten={true}
                        type={CellTypes.NoAccess}
                    />
                    : <TableCell
                        content={info.getValue() as string}
                        shorten={true}
                        isContentFilled={isFilled(info.row.original.name)}
                    />,
                enableSorting: true,
            }),
            columnHelper.accessor(row => `${row.user.first_name} ${row.user.last_name}`, {
                id: 'createdBy',
                header: getTranslatedText('common.createdBy'),
                cell: info => <TableCell
                    content={info.row.original.user.first_name}
                    subContent={info.row.original.user.last_name}
                    isContentFilled={isFilled(info.row.original.user.first_name)}
                    shorten={true}
                    type={CellTypes.Name}
                />,
                enableSorting: false,
            }),
            columnHelper.accessor('file_created_at', {
                id: 'fileCreatedAt',
                header: getTranslatedText('common.createdAt'),
                cell: info => <TableCell
                    content={info.getValue()}
                    isContentFilled={isFilled(info.getValue())}
                    type={CellTypes.Date}
                />,
                enableSorting: true,
            }),
            columnHelper.accessor('created_at', {
                id: 'createdAt',
                header: getTranslatedText('files.uploadedAt'),
                cell: info => <TableCell
                    content={info.getValue()}
                    isContentFilled={isFilled(info.getValue())}
                    type={CellTypes.Date}
                />,
                enableSorting: true,
            }),
            columnHelper.accessor(row => row.tasks?.filter(task => (task.status === TaskStatus.done || task.status === TaskStatus.closed))?.length ?? 0, {
                id: 'tasks',
                header: getTranslatedText('tasks.task', 2),
                cell: info => <Center gap={2}>
                    <BsFileEarmarkCheckFill
                        fontSize={20}
                        color={`var(--chakra-colors-${getTasksCountColor(info.row.original.tasks)}-300)`}
                    />
                    {`${info.row.original.tasks?.filter(task => (task.status === TaskStatus.done || task.status === TaskStatus.closed))?.length ?? 0} / ${info.row.original.tasks?.length ?? 0}`}
                </Center>,
                enableSorting: false,
            }),
            columnHelper.display({
                id: 'actions',
                meta: {
                    customTd: true,
                },
                cell: info => customAction
                    ? customAction(info, ...customActionArgs)
                    : isNoAccessFile(info.row.original)
                        ? undefined
                        : <Td px={0}
                              bg={info.row.index % 2 === 0 ? "blackAlpha.100 !important" : "whiteAlpha.100 !important"}>
                            <Flex justifyContent={"space-around"}>
                                <Button
                                    height={'25px'}
                                    pe={0}
                                    me={4}
                                    colorScheme={'white'}
                                    isLoading={isLoadingTrigger}
                                    // loadingText='Downloading'
                                    onClick={() => trigger({
                                        id: info.row.original.id,
                                        name: info.row.original.document_name
                                    })}
                                >
                                    <BsFillCloudDownloadFill
                                        fontSize={25}
                                        color="var(--chakra-colors-teal-400)"
                                    />
                                </Button>
                                <Button height={'25px'} ps={0} colorScheme={'white'}
                                        onClick={() => {
                                            if (inModal) {
                                                dispatch(closeFileViewModal())
                                                navigate(`/files/${info.row.original.id}`)
                                            } else {
                                                dispatch(showFileViewModal({file: info.row.original}))
                                            }
                                        }}>
                                    <AiFillEye
                                        fontSize={25}
                                        color="var(--chakra-colors-teal-400)"
                                    />
                                </Button>
                            </Flex>
                        </Td>,
                enableSorting: false,
            }),
        ]
    ;

    const defaultVisibility = {created_at: !inModal, tasks: !inModal};

    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(() => {
        return {...defaultVisibility, ...hiddenFields.reduce((acc, field) => ({...acc, [field]: false}), {})};
    });

    const handleSortingChange: OnChangeFn<SortingState> = (newSorting) => {
        dispatch(setSorting(newSorting));
    };

    const table = useReactTable({
        data: files,
        columns,
        state: {
            columnVisibility,
            sorting,
        },
        onColumnVisibilityChange: setColumnVisibility,
        onSortingChange: handleSortingChange,
        manualSorting: true,
        enableSorting: !inModal,
        getCoreRowModel: getCoreRowModel(),
    })

    return (
        <TableWrapper table={table} noDataMessage={'files.noFiles'}/>
    );
};
